import React, { useState } from "react";
import Breadcrumb from "../../component/breadcrumb/Breadcrumb";
import Textarea from "../../component/inputs/Textarea";
import InputFile from "../../component/inputs/FileInput";
import { useParams } from "react-router-dom";
import { getFileType } from "../../data/data";
import { postDocumentData } from "../../API/postDocumentData";
import postData from "../../API/postData";
import { IoMdCloseCircleOutline } from "react-icons/io";

const SendReply = () => {
  const { id } = useParams();
  const [reply, setReply] = useState({});

  const [selectedImages, setSelectedImages] = useState([]);
  const [shouldSubmit, setShouldSubmit] = useState(false);
  const handleProductImages = (event) => {
    const files = event.target.files;
    setSelectedImages([...selectedImages, ...Array.from(files)]);
  };

  const handleProductImageRemove = (index) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedImages) {
      try {
        const productImagesId = [];
        for (const image of selectedImages) {
          const formDATA = new FormData();
          formDATA.append("document", image);
          const DOCtype = getFileType(image.name);
          formDATA.append("doc_type", DOCtype);
          console.log("uploading product images:", image.name);
          const productImagesUpload = await postDocumentData(
            "auth/documents/upload/",
            formDATA
          );

          const imagesID = productImagesUpload.data.id;
          console.log("uploaded images id:", imagesID);
          productImagesId.push(imagesID);
          setReply((prev) => ({
            ...prev,
            attachment: productImagesId,
            ticket: id,
          }));
          setShouldSubmit(true);
        }
      } catch (error) {
        console.log("error uploading images", error);
      }
    }
  };
  if (shouldSubmit) {
    postData("support/admin/reply/", reply).then((res) => {
      console.log("ticket reply response", res);
      setShouldSubmit(false);
    });
  }
  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb directory1="Support" directory2="Reply" />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search mb-3">
            <h5>Reply Ticket</h5>
          </div>
          <div>
            <form action="" onSubmit={handleSubmit}>
              <Textarea
                placeholder="Note"
                onChange={(value) =>
                  setReply((prev) => ({
                    ...prev,
                    note: value,
                  }))
                }
              />
              <div className="d-flex flex-wrap">
                <div className="me-3 add-images-input">
                  <InputFile
                    id="ticket-attachment"
                    imageChange={handleProductImages}
                    multiple={true}
                  />
                </div>
                <div className="d-flex">
                  {selectedImages.map((image, index) => (
                    <div className="add-images-wrapper" key={index}>
                      <img
                        className="img-fluid"
                        src={URL.createObjectURL(image)}
                        alt={`ticket-${index}`}
                      />
                      <button
                        className="border-0 bg-transparent multi-img-close-btn"
                        onClick={handleProductImageRemove}
                      >
                        <IoMdCloseCircleOutline className="fs-4 text-secondary" />
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              <div className="my-3">
                <button className="btn btn-dark w-100" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendReply;
