import React, { useEffect, useState } from "react";
import Breadcrumb from "../component/breadcrumb/Breadcrumb";
import Input from "../component/inputs/Input";
import DropSelect from "../component/dropdowns/DropSelect";
import { getFileType, stockStatusData } from "./data";
import InputFile from "../component/inputs/FileInput";
import ProductVariants from "./Productvariant";
import AdditionalProp from "./Additionalprop";
import { postDocumentData } from "../API/postDocumentData";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { useParams } from "react-router-dom";
import { GETData } from "../API/GETDataV2";

const Test = () => {
  const { id } = useParams();
  const [dataSet, setData] = useState({});
  const [additionalProps, setAdditionalProps] = useState({});
  const [productVariants, setProductVariants] = useState([]);
  const [fileInput, setFileInput] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [shouldSubmit, setShouldSubmit] = useState(false);

  const [selectedImages, setSelectedImages] = useState([]);

  useEffect(() => {
    GETData(`inventory/admin/product/${id}`).then((res) => {
      setData(res.data.data);

      setSelectedImage(res.data.data.thumbnail_url || null);
      setSelectedImages(res.data.data.images || []);
      setAdditionalProps(res.data.data.product_specification || {});
      setProductVariants(res.data.data.product_variants || []);
    });
  }, [id]);

  //   const navigate = useNavigate();

  const handleInputChange = (name, value) => {
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // thumbnail image
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setFileInput(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // variant options
  const handleVariantDataChange = (newVariantData) => {
    // console.log("Updated Variant Data:", newVariantData);
    setData((prev) => ({
      ...prev,
      product_variants: newVariantData,
    }));
  };

  const handleVariantOption = (value) => {
    setData((prev) => ({
      ...prev,
      has_variant: value,
    }));
  };

  // additional prop

  const handleAdditionalPropsChange = (newProps) => {
    setAdditionalProps(newProps);
  };
  console.log("additionalPropsEdit", additionalProps);
  // upload images
  const handleProductImages = (event) => {
    const files = event.target.files;
    setSelectedImages([...selectedImages, ...Array.from(files)]);
  };

  const handleProductImageRemove = (index) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const additionProp = Object.keys(additionalProps).length === 0;
    if (!additionProp) {
      setData((prev) => ({
        ...prev,
        product_specification: additionalProps,
      }));
    }
    if (fileInput) {
      const formData = new FormData();
      formData.append("document", fileInput);
      const docType = getFileType(fileInput.name);
      formData.append("doc_type", docType);

      try {
        const docUpload = await postDocumentData(
          "auth/documents/upload/",
          formData
        );
        setData((prev) => ({
          ...prev,
          thumbnail: docUpload.data.id,
        }));

        if (selectedImages) {
          try {
            const productImagesId = [];
            for (const image of selectedImages) {
              const formDATA = new FormData();
              formDATA.append("document", image);
              const DOCtype = getFileType(image.name);
              formDATA.append("doc_type", DOCtype);
              console.log("uploading product images:", image.name);
              const productImagesUpload = await postDocumentData(
                "auth/documents/upload/",
                formDATA
              );

              const imagesID = productImagesUpload.data.id;
              console.log("uploaded images id:", imagesID);
              productImagesId.push(imagesID);
            }
            setData((prev) => ({
              ...prev,
              images: productImagesId,
            }));
            setShouldSubmit(true);
          } catch (error) {
            console.log("error uploading images", error);
          }
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setShouldSubmit(true);
    }
  };

  if (shouldSubmit) {
    console.log(dataSet);
    setShouldSubmit(false);
  }
  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb
          directory1="Inventory"
          directory2="Products"
          directory3="Add Product"
          path="/inventory/products"
        />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search">
            <h5>Add product</h5>
          </div>

          <form action="" onSubmit={handleFormSubmit}>
            <div className="row">
              <div className="col-md-6">
                <Input
                  type="text"
                  placeholder="Name"
                  value={dataSet.name || ""}
                  onChange={(value) => handleInputChange("name", value)}
                />

                <div>
                  <label className="form-label fw-bold">Thumbnail Image</label>
                  <div className="profile-img-wrapper">
                    {selectedImage ? (
                      <img
                        className="mb-2 img-fluid"
                        src={selectedImage || ""}
                        alt="brand"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <InputFile
                    id="product-image1"
                    imageChange={handleImageChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <DropSelect
                  label="Stock Status"
                  options={stockStatusData}
                  value={dataSet.stock_status}
                  onChange={(value) => handleInputChange("stock_status", value)}
                />

                <Input
                  type="text"
                  placeholder="Unit Value"
                  value={dataSet.unit_value}
                  onChange={(value) => handleInputChange("unit_value", value)}
                />
              </div>
            </div>
            <div className="row mt-4 py-4 border-top border-2 border-secondary">
              <DropSelect
                label="Has Variant ?"
                options={[
                  { title: "Select an option", value: "", disabled: true },
                  { title: "Yes", value: true },
                  { title: "No", value: false },
                ]}
                value={dataSet.has_variant}
                onChange={handleVariantOption}
              />
              {dataSet.has_variant === true ? (
                <ProductVariants
                  productVariants={productVariants}
                  onVariantDataChange={handleVariantDataChange}
                />
              ) : (
                ""
              )}
            </div>
            <AdditionalProp
              additionProps={additionalProps}
              onAdditionPropsChange={handleAdditionalPropsChange}
            />
            <div className="row mt-4 pb-3 pt-5 border-top border-2 border-secondary">
              <div className="d-flex flex-wrap">
                <div className="me-3 add-images-input">
                  <InputFile
                    id="add-producr-images"
                    imageChange={handleProductImages}
                    multiple={true}
                  />
                </div>
                <div className="d-flex">
                  {selectedImages.map((image, index) => {
                    return (
                      <div className="add-images-wrapper" key={index}>
                        <img
                          className="img-fluid"
                          src={image.document_url}
                          alt={`product-${index}`}
                        />
                        <button
                          className="border-0 bg-transparent multi-img-close-btn"
                          onClick={handleProductImageRemove}
                        >
                          <IoMdCloseCircleOutline className="fs-4 text-secondary" />
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <button className="btn btn-dark my-3 py-2 w-100" type="submit">
              {shouldSubmit ? "Adding Product..." : "Add Product"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Test;
