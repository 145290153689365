import React, { useEffect, useState } from "react";
import FloatingInput from "../../../component/inputs/FloatingInput";
import DropSelect from "../../../component/dropdowns/DropSelect";
import axiosInstance from "../../../API/AxiosInstance";
import Logo from "../../../assets/Logo.png";
import { genderData, roleData } from "../../../data/data";
import { useNavigate } from "react-router-dom";

const Signup = ({ setPhoneNumber }) => {
  const [country, setCountry] = useState([]);

  const fetchCountries = async () => {
    const response = await axiosInstance.get("auth/country/");
    setCountry(response.data.data.results);
  };

  const [signUpData, setSignUpData] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const handleInputChange = (name, value) => {
    if (name === "country" || name === "gender" || name === "role") {
      value = value ? parseInt(value, 10) : 0;
    }
    setSignUpData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axiosInstance.post("auth/signup/", signUpData);
      setPhoneNumber(signUpData.mobile);
      console.log("sign-up response", response);
      navigate("/account-verification");
    } catch (error) {
      setErrorMsg(
        error.response?.data?.errors?.email?.[0] ||
          error.response?.data?.errors?.mobile?.[0] ||
          error.response?.data?.errors?.country?.[0] ||
          error.response?.data?.errors?.gender?.[0] ||
          error.response?.data?.errors?.role?.[0] ||
          error.response?.data?.errors?.password?.[0] ||
          error.response?.data?.errors?.confirm_password?.[0] ||
          error.response?.data?.message
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  return (
    <div className="vh-100 w-100 d-flex align-items-center justify-content-center p-3 p-sm-2 p-md-0 p-lg-0 p-xl-0 p-xxl-0">
      <div className="col-xl-6 col-lg-8 col-md-10 col-12">
        <div className="shadow p-4 rounded-12 border">
          <div className="d-flex justify-content-center">
            <img className="img-fluid mt-3 mb-5" src={Logo} alt="Techtsy" />
          </div>
          {loading ? <p className="text-center">Loading...</p> : ""}
          {errorMsg ? (
            <p className="fade-bg px-1 py-2 text-center">{errorMsg}</p>
          ) : (
            ""
          )}
          <form onSubmit={handleSubmit}>
            <div className="row register-form">
              <div className="col-md-6">
                <FloatingInput
                  type="text"
                  placeholder="First Name"
                  onChange={(value) => handleInputChange("first_name", value)}
                  required={true}
                />
                <FloatingInput
                  type="text"
                  placeholder="Last Name"
                  onChange={(value) => handleInputChange("last_name", value)}
                  required={true}
                />
                <FloatingInput
                  type="date"
                  placeholder="Date of Birth"
                  onChange={(value) => handleInputChange("dob", value)}
                  required={true}
                />
                <FloatingInput
                  type="email"
                  placeholder="Email"
                  onChange={(value) => handleInputChange("email", value)}
                  required={true}
                />
                <FloatingInput
                  type="text"
                  placeholder="Mobile"
                  onChange={(value) => handleInputChange("mobile", value)}
                  required={true}
                />
              </div>
              <div className="col-md-6">
                <FloatingInput
                  type="password"
                  placeholder="Password"
                  onChange={(value) => handleInputChange("password", value)}
                  required={true}
                />
                <FloatingInput
                  type="password"
                  placeholder="Confirm Password"
                  onChange={(value) =>
                    handleInputChange("confirm_password", value)
                  }
                  required={true}
                />
                <DropSelect
                  options={[
                    {
                      value: "",
                      title: "Select a Country",
                      disabled: true,
                    },
                    ...country.map((item) => ({
                      value: item.id,
                      title: item.name,
                    })),
                  ]}
                  onChange={(value) => handleInputChange("country", value)}
                />
                <DropSelect
                  options={genderData}
                  onChange={(value) => handleInputChange("gender", value)}
                />
                <DropSelect
                  options={roleData}
                  onChange={(value) => handleInputChange("role", value)}
                />
              </div>
            </div>
            <button type="submit" className="btn btn-dark w-100">
              Sign Up
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Signup;
