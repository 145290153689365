import React, { useEffect, useState } from "react";
import Breadcrumb from "../../component/breadcrumb/Breadcrumb";
import { getData } from "../../API/getData";
import { IoSendOutline, IoImage } from "react-icons/io5";
import { IoMdCloseCircle } from "react-icons/io";

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [text, setText] = useState("");
  // const [receivers, setReceivers] = useState(null);
  const fetchMessages = () => {
    getData("chat/admin/message/").then((res) => {
      setMessages(res);
      setSelectedMessage(res[0]);
    });
  };
  useEffect(() => {
    fetchMessages();
  }, []);

  const handleMessageClick = (message) => {
    setSelectedMessage(message);
  };

  const handleProductImages = (event) => {
    const files = event.target.files;
    setSelectedImages([...selectedImages, ...Array.from(files)]);
  };
  const handleProductImageRemove = (index) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);
  };
  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb directory1="Chats" />
      </div>
      <div className="p-3">
        <div className="px-2">
          <div className="row mb-1">
            <div className="col-3">
              <h5 className="mb-0 primary-bg text-white p-3">Users</h5>
            </div>
            <div className="col-9 ">
              <h5 className="mb-0 primary-bg text-white p-3">Messages</h5>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              {messages && messages.length > 0
                ? messages.map((item) => (
                    <div
                      key={item.id}
                      className="message-user-list bg-white p-3 mb-1 d-flex align-items-center c-pointer"
                      onClick={() => handleMessageClick(item)}
                    >
                      <img
                        className="profile-img-sm rounded-circle"
                        src={
                          item.profile_image ||
                          "https://cdn3.vectorstock.com/i/1000x1000/30/97/flat-business-man-user-profile-avatar-icon-vector-4333097.jpg"
                        }
                        alt={item.receiver_name}
                      />
                      <div className="ms-1">
                        <p className="fw-bold mb-1 fs-5">
                          {item.receiver_name || "name"}
                        </p>
                        <p className="mb-0">{item.content}</p>
                      </div>
                      <span className="ms-auto">
                        {/* <TimeAgo timestamp={item.timestamp} /> */}
                      </span>
                    </div>
                  ))
                : "No Messages"}
            </div>
            <div className="col-9">
              <div className="bg-white p-3 messages-content-part">
                {selectedMessage && (
                  <>
                    <div className="header-message-profile border-bottom py-1">
                      <div className="d-flex align-items-center">
                        <img
                          className="profile-img-sm rounded-circle"
                          src={
                            selectedMessage.profile_image ||
                            "https://cdn3.vectorstock.com/i/1000x1000/30/97/flat-business-man-user-profile-avatar-icon-vector-4333097.jpg"
                          }
                          alt={selectedMessage.receiver_name}
                        />
                        <div className="ms-1">
                          <p className="fw-bold mb-1 fs-5">
                            {selectedMessage.receiver_name || "Name"}
                          </p>
                          <span>Role</span>
                        </div>
                      </div>
                    </div>
                    <div className="message-contents py-3">
                      <div className="fade-bg rounded-3 p-2 message-paragraph">
                        <p className="mb-0">{selectedMessage.content}</p>
                      </div>
                    </div>
                    <div className="mt-3 p-3 border-top position-relative">
                      <input
                        type="text"
                        placeholder="Write your messages"
                        value={text || ""}
                        className="w-100 border-0 message-sent-input"
                        onChange={(e) => setText(e.target.value)}
                      />
                      <div className="position-absolute message-doc-icon">
                        <input
                          type="file"
                          onChange={handleProductImages}
                          multiple
                          className="d-none"
                          id="message-doc"
                        />
                        <label htmlFor="message-doc" className="msg-doc">
                          <IoImage className="c-pointer fs-5" />
                        </label>
                      </div>
                      <button
                        className="border-0 bg-transparent position-absolute end-0"
                        // onClick={() => sentMessage(selectedMessage.receiver)}
                      >
                        <IoSendOutline className="fs-5" />
                      </button>
                    </div>
                    <div className="ms-3 d-flex gap-3 flex-wrap ">
                      {selectedImages.map((image, index) => (
                        <div key={index} className="position-relative">
                          <img
                            className="review-img img-thumbnail"
                            src={URL.createObjectURL(image)}
                            alt={`message-${index}`}
                          />
                          <IoMdCloseCircle
                            className="fs-4 review-img-close c-pointer"
                            onClick={handleProductImageRemove}
                          />
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
