import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import { GETData } from "../../../API/GETDataV2";
import InputFile from "../../../component/inputs/FileInput";
import Input from "../../../component/inputs/Input";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { getFileType } from "../../../data/data";
import { postDocumentData } from "../../../API/postDocumentData";
import { useNavigate } from "react-router-dom";
import postData from "../../../API/postData";

const EditGlobalSetting = () => {
  const [globalSetting, setGlobalSetting] = useState({});
  const [fileInput, setFileInput] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [shouldSubmit, setShouldSubmit] = useState(false);
  const [clickedPosition, setClickedPosition] = useState(null);

  useEffect(() => {
    if (clickedPosition) {
      setGlobalSetting((prev) => ({
        ...prev,
        latitude: clickedPosition[0],
        longitude: clickedPosition[1],
      }));
    }
  }, [clickedPosition]);

  useEffect(() => {
    GETData("utility/admin/global-settings/").then((res) => {
      const data = res.data.data;
      if (Object.keys(data).length !== 0) {
        setGlobalSetting(data);
        setSelectedImage(data.logo_url);
      }
    });
  }, []);

  const handleInputChange = (name, value) => {
    setGlobalSetting((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setFileInput(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (fileInput) {
      const formData = new FormData();
      formData.append("document", fileInput);
      const docType = getFileType(fileInput.name);
      formData.append("doc_type", docType);

      try {
        const docUpload = await postDocumentData(
          "auth/documents/upload/",
          formData
        );

        setGlobalSetting((prev) => ({
          ...prev,
          logo: docUpload.data.id,
        }));
        setShouldSubmit(true);
      } catch (error) {
        console.log(error);
      }
    } else {
      setShouldSubmit(true);
    }
  };

  const navigate = useNavigate();
  if (shouldSubmit) {
    postData("utility/admin/global-settings/", globalSetting)
      .then((res) => {
        console.log("add global settings response", res);
        navigate("/utility/global-setting");
        setShouldSubmit(false);
      })
      .catch((err) => {
        console.log("error adding currency", err);
      });
  }
  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb
          directory1="Utility"
          directory2="Global Setting"
          directory3="Edit Global Setting"
          path="/utility/global-setting"
        />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search mb-3">
            <h5>Edit Global Setting</h5>
          </div>
          <div>
            <div className="mb-4">
              <MapContainer
                center={[23.685, 90.3563]}
                zoom={13}
                style={{ height: "300px", width: "100%" }}
              >
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />

                {clickedPosition ? (
                  <Marker position={clickedPosition}>
                    <Popup>
                      Clicked at: <br />
                      Latitude: {clickedPosition[0]} <br />
                      Longitude: {clickedPosition[1]}
                    </Popup>
                  </Marker>
                ) : (
                  ""
                )}

                <DefaultClick setClickedPosition={setClickedPosition} />
              </MapContainer>
            </div>
            <form action="" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <Input
                    type="text"
                    placeholder="Latitude"
                    value={globalSetting.latitude || ""}
                    onChange={(value) => handleInputChange("latitude", value)}
                    disabled={true}
                    required={true}
                  />
                  <Input
                    type="text"
                    placeholder="Site Name"
                    value={globalSetting.site_name || ""}
                    onChange={(value) => handleInputChange("site_name", value)}
                  />
                  <Input
                    type="text"
                    placeholder="Website Url"
                    value={globalSetting.website_url || ""}
                    onChange={(value) =>
                      handleInputChange("website_url", value)
                    }
                  />
                  <Input
                    type="text"
                    placeholder="Email"
                    value={globalSetting.email || ""}
                    onChange={(value) => handleInputChange("email", value)}
                  />
                  <Input
                    type="text"
                    placeholder="Phone"
                    value={globalSetting.phone || ""}
                    onChange={(value) => handleInputChange("phone", value)}
                  />
                  <Input
                    type="text"
                    placeholder="Address"
                    value={globalSetting.address || ""}
                    onChange={(value) => handleInputChange("address", value)}
                  />
                  <Input
                    type="text"
                    placeholder="Short Description"
                    value={globalSetting.short_desc || ""}
                    onChange={(value) => handleInputChange("short_desc", value)}
                  />
                  <Input
                    type="text"
                    placeholder="Shipping Fee"
                    value={globalSetting.shipping_fee || ""}
                    onChange={(value) =>
                      handleInputChange("shipping_fee", value)
                    }
                  />
                  <Input
                    type="text"
                    placeholder="Vat Percentage"
                    value={globalSetting.vat_percentage || ""}
                    onChange={(value) =>
                      handleInputChange("vat_percentage", value)
                    }
                  />
                  <Input
                    type="text"
                    placeholder="Email Host"
                    value={globalSetting.email_host || ""}
                    onChange={(value) => handleInputChange("email_host", value)}
                  />
                  <Input
                    type="text"
                    placeholder="User Name"
                    value={globalSetting.user_name || ""}
                    onChange={(value) => handleInputChange("user_name", value)}
                  />
                  <Input
                    type="password"
                    placeholder="Password"
                    value={globalSetting.password || ""}
                    onChange={(value) => handleInputChange("password", value)}
                  />
                  <Input
                    type="text"
                    placeholder="Port"
                    value={globalSetting.port || ""}
                    onChange={(value) => handleInputChange("port", value)}
                  />
                  <Input
                    type="text"
                    placeholder="Sms Server"
                    value={globalSetting.sms_server || ""}
                    onChange={(value) => handleInputChange("sms_server", value)}
                  />
                  <Input
                    type="text"
                    placeholder="Sms Api Key"
                    value={globalSetting.sms_api_key || ""}
                    onChange={(value) =>
                      handleInputChange("sms_api_key", value)
                    }
                  />
                  <div>
                    <div className="profile-img-wrapper">
                      {selectedImage ? (
                        <img
                          className="mb-2 img-fluid"
                          src={selectedImage || ""}
                          alt="currency"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <InputFile
                      id="setting-logo-g"
                      imageChange={handleImageChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <Input
                    type="text"
                    placeholder="Longitude"
                    value={globalSetting.longitude || ""}
                    onChange={(value) => handleInputChange("longitude", value)}
                    disabled={true}
                    required={true}
                  />
                  <Input
                    type="text"
                    placeholder="Facebook"
                    value={globalSetting.facebook || ""}
                    onChange={(value) => handleInputChange("facebook", value)}
                  />
                  <Input
                    type="text"
                    placeholder="Linkedin"
                    value={globalSetting.linkedin || ""}
                    onChange={(value) => handleInputChange("linkedin", value)}
                  />
                  <Input
                    type="text"
                    placeholder="Instagram"
                    value={globalSetting.instagram || ""}
                    onChange={(value) => handleInputChange("instagram", value)}
                  />
                  <Input
                    type="text"
                    placeholder="Youtube"
                    value={globalSetting.youtube || ""}
                    onChange={(value) => handleInputChange("youtube", value)}
                  />
                  <Input
                    type="text"
                    placeholder="Twitter"
                    value={globalSetting.twitter || ""}
                    onChange={(value) => handleInputChange("twitter", value)}
                  />
                  <Input
                    type="text"
                    placeholder="Minimum Withdrawal Amount"
                    value={globalSetting.min_withdraw_amount || ""}
                    onChange={(value) =>
                      handleInputChange("min_withdraw_amount", value)
                    }
                  />
                  <Input
                    type="text"
                    placeholder="Maximum Withdrawal Amount"
                    value={globalSetting.max_withdraw_account || ""}
                    onChange={(value) =>
                      handleInputChange("max_withdraw_account", value)
                    }
                  />
                  <Input
                    type="text"
                    placeholder="Paypal Client Id"
                    value={globalSetting.paypal_client_id || ""}
                    onChange={(value) =>
                      handleInputChange("paypal_client_id", value)
                    }
                  />
                  <Input
                    type="text"
                    placeholder="Paypal Client Secret"
                    value={globalSetting.paypal_client_secret || ""}
                    onChange={(value) =>
                      handleInputChange("paypal_client_secret", value)
                    }
                  />

                  <Input
                    type="text"
                    placeholder="Sms User Name"
                    value={globalSetting.sms_username || ""}
                    onChange={(value) =>
                      handleInputChange("sms_username", value)
                    }
                  />
                  <Input
                    type="text"
                    placeholder="Sms Phone Number"
                    value={globalSetting.sms_phone_number || ""}
                    onChange={(value) =>
                      handleInputChange("sms_phone_number", value)
                    }
                  />

                  <Input
                    type="text"
                    placeholder="User Fcm Key"
                    value={globalSetting.user_fcm_key || ""}
                    onChange={(value) =>
                      handleInputChange("user_fcm_key", value)
                    }
                  />
                  <Input
                    type="text"
                    placeholder="Rider Fcm Server Key"
                    value={globalSetting.rider_fcm_server_key || ""}
                    onChange={(value) =>
                      handleInputChange("rider_fcm_server_key", value)
                    }
                  />
                  <Input
                    type="text"
                    placeholder="Sender Email"
                    value={globalSetting.sender_email || ""}
                    onChange={(value) =>
                      handleInputChange("sender_email", value)
                    }
                  />
                </div>
              </div>
              <div className="my-3">
                <button className="btn btn-dark w-100" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const DefaultClick = ({ setClickedPosition }) => {
  useMapEvents({
    click: (e) => {
      setClickedPosition([e.latlng.lat.toFixed(4), e.latlng.lng.toFixed(4)]);
    },
  });

  return null;
};

export default EditGlobalSetting;
