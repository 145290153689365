import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import Table from "../../../component/table/Table";
import { brandColumns } from "../../../data/tableColumns";
import { getData } from "../../../API/getData";
import AddNew from "../../../component/buttons/AddNew";
import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit2 } from "react-icons/fi";
import useDeleteModal from "../../../utilities/DeleteModal";
import { useNavigate } from "react-router-dom";

const Brand = () => {
  const [tableData, setTableData] = useState([]);

  const fetchData = async () => {
    try {
      const response = await getData("inventory/admin/brand");
      setTableData(response);
    } catch (error) {
      console.log(error);
    }
  };

  const { showDeleteModal, DeleteModal } = useDeleteModal(
    "inventory/admin/brand"
  );

  const navigate = useNavigate();

  const actionColumn = {
    title: "Actions",
    key: "actions",
    render: (data) => {
      return (
        <div className="d-flex align-items-center">
          <button
            className="primary-bg p-1 border-0 me-2"
            onClick={() => navigate(`/inventory/brands/edit-brand/${data.id}`)}
          >
            <FiEdit2 className="text-white" />
          </button>

          <button
            className="bg-danger p-1 border-0 ms-2"
            onClick={() => showDeleteModal(data, fetchData)}
          >
            <AiOutlineDelete className="text-white" />
          </button>
        </div>
      );
    },
  };

  const finalColumn = [...brandColumns, actionColumn];

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb directory1="Inventory" directory2="Brands" />
        <AddNew path="/inventory/brands/add-brand" />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search mb-3">
            <h5>All Brands</h5>
          </div>
          <div className="table-area">
            <Table columns={finalColumn} dataSource={tableData} />
          </div>
        </div>
      </div>
      {/* delete item */}
      <DeleteModal />
    </div>
  );
};

export default Brand;
