export const genderData = [
  { title: "Select Gender", value: "", disabled: true },
  { title: "Male", value: 0 },
  { title: "Female", value: 1 },
  { title: "Other", value: 2 },
];

export const roleData = [
  { title: "Select Role", value: "", disabled: true },
  { title: "Amin", value: 0 },
  { title: "User", value: 1 },
];

export const isFeatured = [
  { title: "Select an option", value: "", disabled: true },
  { title: "Featured", value: true },
  { title: "Not Featured", value: false },
];

export const isActive = [
  { title: "Select an option", value: "", disabled: true },
  { title: "Active", value: true },
  { title: "Inactive", value: false },
];

export const getFileType = (fileName) => {
  const extension = fileName.split(".").pop().toLowerCase();
  if (["jpg", "jpeg", "png", "webp", "svg"].includes(extension)) {
    return 0;
  } else if (["mp4", "mkv"].includes(extension)) {
    return 1;
  } else if (extension === "pdf") {
    return 2;
  } else {
    return 3;
  }
};

export const notificationTypeChoice = [
  { title: "Select an option", value: "", disabled: true },
  { title: "Information", value: 1 },
  { title: "Warning", value: 2 },
  { title: "Error", value: 3 },
];
export const isRead = [
  { title: "Select an option", value: "", disabled: true },
  { title: "True", value: true },
  { title: "False", value: false },
];

export const stockStatusData = [
  { title: "Select an option", value: "", disabled: true },
  { title: "In Stock", value: 0 },
  { title: "Out of Stock", value: 1 },
  { title: "Coming Soon", value: 2 },
];

export const couponType = [
  { title: "Select an option", value: "", disabled: true },
  { title: "Free Delivery", value: 0 },
  { title: "First Order", value: 1 },
  { title: "Discount On Purchase", value: 2 },
];

export const discountType = [
  { title: "Select an option", value: "", disabled: true },
  { title: "Amount", value: 0 },
  { title: "Percentage", value: 1 },
];

export const orderStatusData = [
  { title: "Select an option", value: "", disabled: true },
  { title: "Pending", value: 0 },
  { title: "Processing", value: 1 },
  { title: "Shipped", value: 2 },
  { title: "Out for delivery", value: 3 },
  { title: "Delivered", value: 4 },
  { title: "Cancelled", value: 5 },
  { title: "Refunded", value: 6 },
];

export const paymentMethodData = [
  { title: "Select an option", value: "", disabled: true },
  { title: "Paypal", value: 0 },
  { title: "Billplz", value: 1 },
  { title: "Cash on delivery", value: 2 },
];

export const reasonTypeData = [
  { title: "Select an option", value: "", disabled: true },
  { title: "Change of Mind", value: 0 },
  { title: "Item Out of Stock", value: 1 },
  { title: "Customer Request", value: 2 },
  { title: "By Admin", value: 3 },
];

export const ticketStatusChoice = [
  { title: "Select an option", value: "", disabled: true },
  { title: "Open", value: 1 },
  { title: "Closed", value: 2 },
  { title: "In Progress", value: 3 },
];

export const priorityChoices = [
  { title: "Select an option", value: "", disabled: true },
  { title: "Low", value: 1 },
  { title: "Medium", value: 2 },
  { title: "High", value: 3 },
];

export const membershipAndPackageType = [
  { title: "Select an option", value: "", disabled: true },
  { title: "Basic", value: 0 },
  { title: "Premium", value: 1 },
  { title: "VIP", value: 2 },
];

export const faqTypeData = [
  { title: "Select an option", value: "", disabled: true },
  { title: "Admin", value: 0 },
  { title: "Rider", value: 1 },
  { title: "Customer", value: 2 },
];

export const pageTypeData = [
  { title: "Select an option", value: "", disabled: true },
  { title: "Privacy & policy", value: 0 },
  { title: "Terms & condition", value: 1 },
  { title: "About us", value: 2 },
  { title: "General", value: 3 },
];
