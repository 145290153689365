import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import Input from "../../../component/inputs/Input";
import DropSelect from "../../../component/dropdowns/DropSelect";
import { getFileType, isActive } from "../../../data/data";
import { getData } from "../../../API/getData";
import InputFile from "../../../component/inputs/FileInput";
import { postDocumentData } from "../../../API/postDocumentData";
import postData from "../../../API/postData";
import { useNavigate } from "react-router-dom";

const AddCategory = () => {
  const [categoryData, setCategoryData] = useState([]);
  const [fileInput, setFileInput] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [shouldSubmit, setShouldSubmit] = useState(false);

  const [dataSet, setData] = useState({});

  const handleInputChange = (name, value) => {
    if (name === "parent" || name === "image") {
      value = value ? parseInt(value, 10) : 0;
    }
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setFileInput(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const fetchCategory = () => {
    getData("inventory/admin/category/").then((response) => {
      setCategoryData(response);
    });
  };

  const navigate = useNavigate();
  useEffect(() => {
    fetchCategory();

    if (shouldSubmit) {
      postData("inventory/admin/category/", dataSet).then((response) => {
        console.log(response);
        setShouldSubmit(false);
        navigate("/inventory/categories");
      });
    }
  }, [shouldSubmit, dataSet, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (fileInput) {
      const formData = new FormData();
      formData.append("document", fileInput);
      const docType = getFileType(fileInput.name);
      formData.append("doc_type", docType);

      try {
        const docUpload = await postDocumentData(
          "auth/documents/upload/",
          formData
        );
        setData((prev) => ({
          ...prev,
          image: docUpload.data.id,
        }));
        setShouldSubmit(true);
      } catch (error) {
        console.log(error);
      }
    } else {
      setShouldSubmit(true);
    }
  };
  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb
          directory1="Inventory"
          directory2="Category"
          directory3="Add New"
          path="/inventory/categories"
        />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search mb-3">
            <h5>Add Category</h5>
          </div>
          <div>
            <form action="" onSubmit={handleSubmit}>
              <Input
                type="text"
                placeholder="Name"
                onChange={(value) => handleInputChange("name", value)}
              />
              <DropSelect
                label="Is Active"
                options={isActive}
                onChange={(value) => handleInputChange("is_active", value)}
              />
              <DropSelect
                label="Parent Category"
                options={[
                  {
                    value: "",
                    title: "Select a Parent Category",
                    disabled: true,
                  },
                  ...categoryData.map((item) => ({
                    value: item.id,
                    title: item.name,
                  })),
                ]}
                onChange={(value) => handleInputChange("parent", value)}
              />
              <div>
                <div className="profile-img-wrapper">
                  {selectedImage ? (
                    <img
                      className="mb-2 img-fluid"
                      src={selectedImage || ""}
                      alt="brand"
                    />
                  ) : (
                    ""
                  )}
                </div>
                <InputFile
                  id="category-image"
                  imageChange={handleImageChange}
                />
              </div>
              <button className="btn btn-dark w-100 my-3" type="submit">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCategory;
