import React, { useEffect, useState } from "react";
import Breadcrumb from "../../component/breadcrumb/Breadcrumb";
import Input from "../../component/inputs/Input";
import { IoCameraOutline } from "react-icons/io5";
import { postDocumentData } from "../../API/postDocumentData";
import axiosInstance from "../../API/AxiosInstance";
import postData from "../../API/postData";

const Profile = () => {
  const [profileData, setProfileData] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [fileInput, setFileInput] = useState();
  const [shouldSubmit, setShouldSubmit] = useState(false);
  const token = `Token ${localStorage.getItem("token")}`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get("auth/profile/", {
          headers: { Authorization: token },
        });
        setProfileData(response.data.data);
        localStorage.setItem("profileImage", response.data.data.image_url);
        localStorage.setItem("userName", response.data.data.first_name);
      } catch (error) {
        console.error("Error fetching profile data", error);
      }
    };

    fetchData();
  }, [token]);

  if (shouldSubmit) {
    postData("auth/profile/", {
      first_name: profileData.first_name,
      last_name: profileData.last_name,
      image: profileData.image,
    }).then((res) => {
      console.log(res);
      localStorage.setItem("profileImage", res.data.image_url);
      localStorage.setItem("userName", res.data.first_name);
      setShouldSubmit(false);
    });
  }

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setFileInput(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (name, value) => {
    if (name === "image") {
      value = value ? parseInt(value, 10) : 0;
    }
    setProfileData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (fileInput) {
      const formData = new FormData();
      formData.append("document", fileInput);
      formData.append("doc_type", 0);

      try {
        const docUpload = await postDocumentData(
          "auth/documents/upload/",
          formData
        );
        setProfileData((prev) => ({
          ...prev,
          first_name: profileData.first_name,
          last_name: profileData.last_name,
          image: docUpload.data.id,
        }));
        setShouldSubmit(true);
      } catch (error) {
        console.log(error);
      }
    } else {
      setShouldSubmit(true);
    }
  };

  return (
    <div>
      <div className="bg-white p-3">
        <Breadcrumb directory1="Profile" />
      </div>
      <div className="p-3">
        <form className="bg-light p-3" onSubmit={handleSubmit}>
          <h5 className="mb-3">Profile Information</h5>
          <Input
            type="text"
            placeholder="First Name"
            value={profileData.first_name || ""}
            onChange={(value) => handleInputChange("first_name", value)}
          />
          <Input
            type="text"
            placeholder="Last Name"
            value={profileData.last_name || ""}
            onChange={(value) => handleInputChange("last_name", value)}
          />
          <Input
            type="email"
            placeholder="Email Address"
            value={profileData.email || ""}
            disabled={true}
            // onChange={(value)=> handleInputChange("email", value)}
          />
          <div className="mb-3">
            <div className="profile-img-wrapper">
              <img
                className="img-fluid"
                src={selectedImage || profileData.image_url || ""}
                alt="profile"
              />
            </div>
            <br />
            <label className="p-1 px-3 border fade-bg" htmlFor="profile-img">
              <IoCameraOutline className="me-2 mb-1" />
              Upload
            </label>
            <input
              className="d-none"
              id="profile-img"
              type="file"
              onChange={handleImageChange}
            />
          </div>
          <div>
            <button type="submit" className="btn btn-dark">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Profile;
