import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import Input from "../../../component/inputs/Input";
import DropSelect from "../../../component/dropdowns/DropSelect";
import { membershipAndPackageType } from "../../../data/data";
import { GETData } from "../../../API/GETDataV2";
import postData from "../../../API/postData";
import { useNavigate } from "react-router-dom";

const AddSubscription = () => {
  const [userData, setUserData] = useState([]);
  const [packages, setPackages] = useState([]);

  const [subscription, setSubscription] = useState({});

  const fetchUserData = () => {
    GETData("auth/user/").then((res) => {
      setUserData(res.data.data);
    });
  };

  useEffect(() => {
    fetchUserData();
    GETData("package/admin/package/").then((res) => {
      setPackages(res.data.data.results);
    });
  }, []);

  const handleInputChange = (name, value) => {
    if (
      name === "membership_type" ||
      name === "customer" ||
      name === "package"
    ) {
      value = value ? parseInt(value, 10) : 0;
    }
    setSubscription((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(subscription);
    postData("package/admin/subscription/", subscription)
      .then((res) => {
        console.log("add subscription response", res);
        navigate("/packages/subscriptions");
      })
      .catch((err) => {
        console.log("error adding subscription", err);
      });
  };

  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb
          directory1="Packages"
          directory2="Subscriptions"
          directory3="Add New"
          path="/packages/subscriptions"
        />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search mb-3">
            <h5>Add Subscription</h5>
          </div>
          <div>
            <form onSubmit={handleSubmit}>
              <Input
                type="text"
                placeholder="Amount"
                onChange={(value) => handleInputChange("amount", value)}
              />
              <DropSelect
                label="Membership Type"
                options={membershipAndPackageType}
                onChange={(value) =>
                  handleInputChange("membership_type", value)
                }
              />
              <Input
                type="date"
                placeholder="Expiry Date"
                onChange={(value) => handleInputChange("expiry_date", value)}
              />
              <DropSelect
                label="Customer"
                options={[
                  {
                    value: "",
                    title: "Select an User",
                    disabled: true,
                  },
                  ...userData
                    .filter((item) => item.role === 2)
                    .map((item) => ({
                      value: item.id,
                      title: item.user_name,
                    })),
                ]}
                onChange={(value) => handleInputChange("customer", value)}
              />
              <DropSelect
                label="Package"
                options={[
                  {
                    value: "",
                    title: "Select package",
                    disabled: true,
                  },
                  ...packages.map((item) => ({
                    value: item.id,
                    title: item.package_name,
                  })),
                ]}
                onChange={(value) => handleInputChange("package", value)}
              />
              <button className="btn btn-dark my-3 w-100" type="submit">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSubscription;
