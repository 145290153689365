import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import Input from "../../../component/inputs/Input";
import DropSelect from "../../../component/dropdowns/DropSelect";
import { isActive } from "../../../data/data";
import { useNavigate, useParams } from "react-router-dom";
import { GETData } from "../../../API/GETDataV2";
import putData from "../../../API/putData";

const EditDeliveryCharge = () => {
  const { id } = useParams();
  const [deliveryCharge, setDeliveryCharge] = useState({});

  useEffect(() => {
    GETData(`delivery/admin/delivery_charge/${id}/`).then((res) => {
      setDeliveryCharge(res.data.data);
    });
  }, [id]);

  const handleInputChange = (name, value) => {
    if (name === "km_distance") {
      value = value ? parseInt(value, 10) : 0;
    }
    setDeliveryCharge((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    putData(`delivery/admin/delivery_charge/${id}/`, {
      km_distance: deliveryCharge.km_distance,
      amount: deliveryCharge.amount,
      is_active: deliveryCharge.is_active,
    }).then((res) => {
      console.log("Edit delivery charge response", res);
      navigate("/delivery/delivery-charge");
    });
  };
  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb
          directory1="Delivery"
          directory2="Delivery Charge"
          directory3="Edit Delivery Charge"
          path="/delivery/delivery-charge"
        />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search mb-3">
            <h5>Edit Delivery Charge</h5>
          </div>
          <div>
            <form action="" onSubmit={handleSubmit}>
              <Input
                type="text"
                placeholder="Distance (KM)"
                value={deliveryCharge.km_distance || ""}
                onChange={(value) => handleInputChange("km_distance", value)}
              />
              <Input
                type="text"
                placeholder="Amount"
                value={deliveryCharge.amount || ""}
                onChange={(value) => handleInputChange("amount", value)}
              />
              <DropSelect
                label="Status"
                options={isActive}
                value={deliveryCharge.is_active}
                onChange={(value) => handleInputChange("is_active", value)}
              />
              <button className="my-3 btn btn-dark w-100" type="submit">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditDeliveryCharge;
