import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoutes as NotificationProtection } from "../ProtectedRoutes";
import Notification from "../../view/notification/Notification";
import SystemAlert from "../../view/notification/system-alert/SystemAlert";
import AddNotification from "../../view/notification/AddNotification";
import EditNotification from "../../view/notification/EditNotification";
import AddNewAlert from "../../view/notification/system-alert/AddNewAlert";
import EditSystemAlert from "../../view/notification/system-alert/EditSystemAlert";

const NotificationRoutes = () => {
  return (
    <Routes>
      <Route
        path="/personal-notifications"
        element={<NotificationProtection Component={Notification} />}
      />

      <Route
        path="/add-notification"
        element={<NotificationProtection Component={AddNotification} />}
      />
      <Route
        path="/edit-notification/:id"
        element={<NotificationProtection Component={EditNotification} />}
      />
      <Route
        path="/system-alert"
        element={<NotificationProtection Component={SystemAlert} />}
      />
      <Route
        path="/system-alert/add-new"
        element={<NotificationProtection Component={AddNewAlert} />}
      />
      <Route
        path="/system-alert/edit-alert/:id"
        element={<NotificationProtection Component={EditSystemAlert} />}
      />
    </Routes>
  );
};

export default NotificationRoutes;
