import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import Input from "../../../component/inputs/Input";
import { GETData } from "../../../API/GETDataV2";
import DropSelect from "../../../component/dropdowns/DropSelect";

const AddRider = () => {
  const [riders, setRiders] = useState([]);
  const fetchUsers = () => {
    GETData("auth/user/").then((res) => {
      const user = res.data.data;
      const rider = user && user.filter((item) => item.role === 3);
      setRiders(rider);
    });
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  //   console.log(riders);
  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb
          directory1="Delivery"
          directory2="Rider"
          directory3="Add Rider"
          path="/delivery/rider"
        />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search mb-3">
            <h5>Add Rider</h5>
          </div>
          <div>
            <form action="">
              <Input type="text" placeholder="Account Balance" />
              <Input type="text" placeholder="COD Balance" />
              <Input type="text" placeholder="Vehicle Type" />
              <DropSelect
                label="Rider"
                options={[
                  {
                    value: "",
                    title: "Select Rider",
                    disabled: true,
                  },
                  ...riders.map((item) => ({
                    value: item.id,
                    title: item.user_name,
                  })),
                ]}
              />
              <p className="text-danger">Addresses not available for admin</p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddRider;
