import React, { useState } from "react";
import AuthContainer from "../AuthContainer";
import logo from "../../../assets/Logo.png";
import FloatingInput from "../../../component/inputs/FloatingInput";
import axiosInstance from "../../../API/AxiosInstance";
import { useNavigate } from "react-router-dom";

const ForgotPass = ({ setForgotPhone }) => {
  const [forgotPassData, setForgotPassData] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axiosInstance.post("auth/forget/password/", {
        mobile: `${forgotPassData}`,
      });
      console.log(response);
      setForgotPhone(forgotPassData);
      navigate("/forgot-password-verification");
    } catch (error) {
      setErrorMsg(
        error.response?.data?.errors?.mobile?.[0] ||
          error.response?.data?.message
      );
    } finally {
      setLoading(false);
    }
  };
  console.log(forgotPassData);
  return (
    <AuthContainer
      titleLG="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Autem
                ex perferendis"
      titleSM="Lorem ipsum dolor sit amet consectetur adipisicing elit. Est,
                possimus?"
    >
      <div className="p-4">
        <div className="w-100 d-flex justify-content-center">
          <img className="img-fluid mt-3" src={logo} alt="techtsy" />
        </div>
        {loading ? <p className="text-center">Loading...</p> : ""}
        {errorMsg ? (
          <p className="fade-bg px-1 py-2 text-center">{errorMsg}</p>
        ) : (
          ""
        )}
        <h4 className="my-4">Forgot Password</h4>
        <form onSubmit={handleSubmit}>
          <div className="my-4">
            <FloatingInput
              type="text"
              placeholder="Phone Number"
              onChange={(value) => setForgotPassData(value)}
              required={true}
            />
          </div>
          <div className="my-3">
            <button type="submit" className="btn btn-dark">
              Send
            </button>
          </div>
        </form>
      </div>
    </AuthContainer>
  );
};

export default ForgotPass;
