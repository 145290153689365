import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoutes } from "../ProtectedRoutes";
import Coupon from "../../view/sales/coupon/Coupon";
import AddCoupon from "../../view/sales/coupon/AddCoupon";
import EditCoupon from "../../view/sales/coupon/EditCoupon";
import Orders from "../../view/sales/order/Orders";
import OrderDetail from "../../view/sales/order/OrderDetail";
import Reason from "../../view/sales/cancel-reason/Reason";
import AddReason from "../../view/sales/cancel-reason/AddReason";
import EditReason from "../../view/sales/cancel-reason/EditReason";

const SalesRoutes = () => {
  return (
    <Routes>
      {/* coupons */}
      <Route path="/coupon" element={<ProtectedRoutes Component={Coupon} />} />
      <Route
        path="/coupon/add-new"
        element={<ProtectedRoutes Component={AddCoupon} />}
      />
      <Route
        path="/coupon/edit-coupon/:id"
        element={<ProtectedRoutes Component={EditCoupon} />}
      />
      {/* orders */}
      <Route path="/orders" element={<ProtectedRoutes Component={Orders} />} />
      <Route
        path="/orders/order-detail/:id"
        element={<ProtectedRoutes Component={OrderDetail} />}
      />

      {/* reasons */}
      <Route
        path="/cancel-reasons"
        element={<ProtectedRoutes Component={Reason} />}
      />
      <Route
        path="/cancel-reasons/add-reason"
        element={<ProtectedRoutes Component={AddReason} />}
      />
      <Route
        path="/cancel-reasons/edit-reason/:id"
        element={<ProtectedRoutes Component={EditReason} />}
      />
    </Routes>
  );
};

export default SalesRoutes;
