import React, { useEffect, useState } from "react";
import Breadcrumb from "../../component/breadcrumb/Breadcrumb";
import Input from "../../component/inputs/Input";
import Textarea from "../../component/inputs/Textarea";
import InputFile from "../../component/inputs/FileInput";
import { getFileType } from "../../data/data";
import { postDocumentData } from "../../API/postDocumentData";
import { useNavigate, useParams } from "react-router-dom";
import { GETData } from "../../API/GETDataV2";
import putData from "../../API/putData";

const EditBlog = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState({});

  useEffect(() => {
    GETData(`blog/admin/post/${id}/`).then((res) => {
      setBlog(res.data.data);
      setSelectedImage(res.data.data.image_url);
    });
  }, [id]);

  const [fileInput, setFileInput] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [shouldSubmit, setShouldSubmit] = useState(false);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setFileInput(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (name, value) => {
    setBlog((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (fileInput) {
      const formData = new FormData();
      formData.append("document", fileInput);
      const docType = getFileType(fileInput.name);
      formData.append("doc_type", docType);

      try {
        const docUpload = await postDocumentData(
          "auth/documents/upload/",
          formData
        );

        setBlog((prev) => ({
          ...prev,
          image: docUpload.data.id,
        }));
        setShouldSubmit(true);
      } catch (error) {
        console.log(error);
      }
    } else {
      setShouldSubmit(true);
    }
  };

  const navigate = useNavigate();
  if (shouldSubmit) {
    putData(`blog/admin/post/${id}/`, {
      seo_title: blog.seo_title,
      seo_description: blog.seo_description,
      seo_keyword: blog.seo_keyword,
      title: blog.title,
      content: blog.content,
      image: blog.image,
    })
      .then((res) => {
        console.log("edit blog response", res);
        navigate("/blogs/blog-list");
        setShouldSubmit(false);
      })
      .catch((err) => {
        console.log("error editing blog", err);
      });
  }
  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb
          directory1="Blogs"
          directory2="Blog List"
          directory3="Edit Blog"
          path="/blogs/blog-list"
        />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search mb-3">
            <h5>Add Blog</h5>
          </div>
          <div>
            <form action="" onSubmit={handleSubmit}>
              <Input
                type="text"
                placeholder="SEO Title"
                required={true}
                value={blog.seo_title || ""}
                onChange={(value) => handleInputChange("seo_title", value)}
              />
              <Input
                type="text"
                placeholder="SEO Description"
                required={true}
                value={blog.seo_description || ""}
                onChange={(value) =>
                  handleInputChange("seo_description", value)
                }
              />
              <Input
                type="text"
                placeholder="SEO Keyword"
                required={true}
                value={blog.seo_keyword || ""}
                onChange={(value) => handleInputChange("seo_keyword", value)}
              />
              <Input
                type="text"
                placeholder="Title"
                required={true}
                value={blog.title || ""}
                onChange={(value) => handleInputChange("title", value)}
              />
              <Textarea
                placeholder="Content"
                required={true}
                value={blog.content || ""}
                onChange={(value) => handleInputChange("content", value)}
              />
              <div>
                <div className="profile-img-wrapper">
                  {selectedImage ? (
                    <img
                      className="mb-2 img-fluid"
                      src={selectedImage || ""}
                      alt="currency"
                    />
                  ) : (
                    ""
                  )}
                </div>
                <InputFile id="blog-img" imageChange={handleImageChange} />
              </div>
              <button className="btn btn-dark w-100 my-3" type="submit">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditBlog;
