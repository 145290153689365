import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { GETData } from "../../../API/GETDataV2";
import putData from "../../../API/putData";
import { getFileType, isActive } from "../../../data/data";
import { postDocumentData } from "../../../API/postDocumentData";
import Input from "../../../component/inputs/Input";
import InputFile from "../../../component/inputs/FileInput";
import DropSelect from "../../../component/dropdowns/DropSelect";

const EditBanner = () => {
  const { id } = useParams();
  const [banners, setBanners] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    GETData(`utility/admin/banner/${id}/`).then((res) => {
      setBanners(res.data.data);
      setSelectedImage(res.data.data.image_url);
    });
  }, [id]);

  const [fileInput, setFileInput] = useState();
  const [shouldSubmit, setShouldSubmit] = useState(false);
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setFileInput(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (name, value) => {
    if (name === "image" || name === "position") {
      value = value ? parseInt(value, 10) : 0;
    }
    setBanners((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (fileInput) {
      const formData = new FormData();
      formData.append("document", fileInput);
      const docType = getFileType(fileInput.name);
      formData.append("doc_type", docType);

      try {
        const docUpload = await postDocumentData(
          "auth/documents/upload/",
          formData
        );
        setBanners((prev) => ({
          ...prev,
          image: docUpload.data.id,
        }));
        setShouldSubmit(true);
      } catch (error) {
        console.log(error);
      }
    } else {
      setShouldSubmit(true);
    }
  };

  const navigate = useNavigate();
  if (shouldSubmit) {
    putData(`utility/admin/banner/${id}/`, {
      name: banners.name,
      expiry_date: banners.expiry_date,
      position: banners.position,
      is_active: banners.is_active,
      image: banners.image,
    })
      .then((res) => {
        console.log("edit banner response", res);
        navigate("/utility/banner");
      })
      .catch((err) => {
        console.log("error editing banner", err);
      });
  }
  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb
          directory1="Utility"
          directory2="Banner"
          directory3="Edit Banner"
          path="/utility/banner"
        />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search mb-3">
            <h5>Edit Banner</h5>
          </div>
          <div>
            <form action="" onSubmit={handleSubmit}>
              <Input
                type="text"
                placeholder="Name"
                value={banners.name || ""}
                onChange={(value) => handleInputChange("name", value)}
              />
              <Input
                type="date"
                placeholder="Expiry Date"
                value={banners.expiry_date || ""}
                onChange={(value) => handleInputChange("expiry_date", value)}
              />
              <Input
                type="text"
                placeholder="Position"
                value={banners.position || ""}
                onChange={(value) => handleInputChange("position", value)}
              />
              <DropSelect
                label="Is Active"
                options={isActive}
                value={banners.is_active}
                onChange={(value) => handleInputChange("is_active", value)}
              />
              <div>
                <div className="profile-img-wrapper">
                  {selectedImage ? (
                    <img
                      className="mb-2 img-fluid"
                      src={selectedImage || ""}
                      alt="brand"
                    />
                  ) : (
                    ""
                  )}
                </div>
                <InputFile id="banner-img" imageChange={handleImageChange} />
              </div>
              <button className="btn btn-dark w-100 my-3" type="submit">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditBanner;
