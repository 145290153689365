import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import Input from "../../../component/inputs/Input";
import DropSelect from "../../../component/dropdowns/DropSelect";
import InputFile from "../../../component/inputs/FileInput";
import {
  getFileType,
  isActive,
  isFeatured,
  stockStatusData,
} from "../../../data/data";
import { getData } from "../../../API/getData";
import { useNavigate, useParams } from "react-router-dom";
import { GETData } from "../../../API/GETDataV2";
import ProductVariantsEdit from "./ProductVariantsEdit";
import { postDocumentData } from "../../../API/postDocumentData";
import AdditionalPropEdit from "./AdditionalPropEdit";
import { IoMdCloseCircleOutline } from "react-icons/io";
import putData from "../../../API/putData";

const EditProduct = () => {
  const { id } = useParams();
  const [categoryData, setCategoryData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [dataSet, setData] = useState({});
  const [shouldSubmit, setShouldSubmit] = useState(false);
  const [additionalProps, setAdditionalProps] = useState({});
  const [productVariants, setProductVariants] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [fileInput, setFileInput] = useState();
  const [fileInputs, setFileInputs] = useState();
  const [selectedImage, setSelectedImage] = useState(null);

  const fetchData = () => {
    getData("inventory/admin/category/").then((res) => {
      setCategoryData(res);
    });
    getData("inventory/admin/brand/").then((res) => {
      setBrandData(res);
    });
  };

  useEffect(() => {
    fetchData();
    GETData(`inventory/admin/product/${id}/`).then((res) => {
      setData(res.data.data);
      setSelectedImage(res.data.data.thumbnail_url);
      setSelectedImage(res.data.data.thumbnail_url || null);
      setSelectedImages(res.data.data.images || []);
      setAdditionalProps(res.data.data.product_specification || {});
      setProductVariants(res.data.data.product_variants || []);
    });
  }, [id]);

  const handleInputChange = (name, value) => {
    if (
      name === "category" ||
      name === "brand" ||
      name === "quantity" ||
      name === "reward_points" ||
      name === "stock_status" ||
      name === "brand"
    ) {
      value = value ? parseInt(value, 10) : 0;
    }
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // thumbnail image
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setFileInput(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // variant options
  const handleVariantDataChange = (newVariantData) => {
    setData((prev) => ({
      ...prev,
      product_variants: newVariantData,
    }));
  };

  const handleVariantOption = (value) => {
    setData((prev) => ({
      ...prev,
      has_variant: value,
    }));
  };

  // additional prop

  const handleAdditionalPropsChange = (newProps) => {
    setAdditionalProps(newProps);
  };

  // upload images
  const handleProductImages = (event) => {
    const files = event.target.files;
    setFileInputs(files);
    setSelectedImages([...selectedImages, ...Array.from(files)]);
  };

  const handleProductImageRemove = (index) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const additionProp = Object.keys(additionalProps).length === 0;
    if (!additionProp) {
      setData((prev) => ({
        ...prev,
        product_specification: additionalProps,
      }));
    }

    if (fileInput !== undefined) {
      const formData = new FormData();
      formData.append("document", fileInput);
      const docType = getFileType(fileInput.name);
      formData.append("doc_type", docType);

      try {
        const docUpload = await postDocumentData(
          "auth/documents/upload/",
          formData
        );
        setData((prev) => ({
          ...prev,
          thumbnail: docUpload.data.id,
        }));
        setShouldSubmit(true);
      } catch (error) {
        console.log(error);
      }
    } else if (fileInputs !== undefined) {
      try {
        const productImagesId = [];
        for (const image of selectedImages) {
          const formDATA = new FormData();
          formDATA.append("document", image);
          const DOCtype = getFileType(image.name);
          formDATA.append("doc_type", DOCtype);
          console.log("uploading product images:", image.name);
          const productImagesUpload = await postDocumentData(
            "auth/documents/upload/",
            formDATA
          );

          const imagesID = productImagesUpload.data.id;
          console.log("uploaded images id:", imagesID);
          productImagesId.push(imagesID);
        }
        setData((prev) => ({
          ...prev,
          images: productImagesId,
        }));
        setShouldSubmit(true);
      } catch (error) {
        console.log("error uploading images", error);
      }
    } else {
      const imageIds = dataSet.images.map((image) => image.id);
      setData((prev) => ({
        ...prev,
        images: imageIds,
      }));
      setShouldSubmit(true);
    }
  };

  const navigate = useNavigate();

  if (shouldSubmit) {
    console.log(dataSet);
    putData(`inventory/admin/product/${id}/`, dataSet)
      .then((res) => {
        console.log("edit product response", res);
        setShouldSubmit(false);
        navigate("/inventory/products");
      })
      .catch((err) => {
        console.log("error editing product");
      });
  }

  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb
          directory1="Inventory"
          directory2="Products"
          directory3="Edit Product"
          path="/inventory/products"
        />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search">
            <h5>Edit Product</h5>
          </div>
          <form action="" onSubmit={handleFormSubmit}>
            <div className="row">
              <div className="col-md-6">
                <Input
                  type="text"
                  placeholder="Name"
                  value={dataSet.name || ""}
                  onChange={(value) => handleInputChange("name", value)}
                />
                <DropSelect
                  label="Category"
                  options={[
                    {
                      value: "",
                      title: "Select Category",
                      disabled: true,
                    },
                    ...categoryData.map((item) => ({
                      value: item.id,
                      title: item.name,
                    })),
                  ]}
                  value={dataSet.category}
                  onChange={(value) => handleInputChange("category", value)}
                />
                <DropSelect
                  label="Brand"
                  options={[
                    {
                      value: "",
                      title: "Select Brand",
                      disabled: true,
                    },
                    ...brandData.map((item) => ({
                      value: item.id,
                      title: item.name,
                    })),
                  ]}
                  value={dataSet.brand}
                  onChange={(value) => handleInputChange("brand", value)}
                />
                <Input
                  type="text"
                  placeholder="SKU"
                  value={dataSet.sku || ""}
                  onChange={(value) => handleInputChange("sku", value)}
                />
                <Input
                  type="text"
                  placeholder="Description"
                  value={dataSet.description || ""}
                  onChange={(value) => handleInputChange("description", value)}
                />
                <Input
                  type="text"
                  placeholder="Cost"
                  value={dataSet.cost || ""}
                  onChange={(value) => handleInputChange("cost", value)}
                />
                <Input
                  type="text"
                  placeholder="Price"
                  value={dataSet.price || ""}
                  onChange={(value) => handleInputChange("price", value)}
                />
                <Input
                  type="text"
                  placeholder="Quantity"
                  value={dataSet.quantity || ""}
                  onChange={(value) => handleInputChange("quantity", value)}
                />

                <Input
                  type="text"
                  placeholder="Vat"
                  value={dataSet.vat || ""}
                  onChange={(value) => handleInputChange("vat", value)}
                />
                <div>
                  <label className="form-label fw-bold">Thumbnail Image</label>
                  <div className="profile-img-wrapper">
                    {selectedImage ? (
                      <img
                        className="mb-2 img-fluid"
                        src={selectedImage || ""}
                        alt="brand"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <InputFile
                    id="product-image"
                    imageChange={handleImageChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <DropSelect
                  label="Stock Status"
                  options={stockStatusData}
                  value={dataSet.stock_status}
                  onChange={(value) => handleInputChange("stock_status", value)}
                />
                <DropSelect
                  label="Is Active"
                  options={isActive}
                  value={dataSet.is_active}
                  onChange={(value) => handleInputChange("is_active", value)}
                />
                <DropSelect
                  label="Is Featured"
                  options={isFeatured}
                  value={dataSet.is_featured}
                  onChange={(value) => handleInputChange("is_featured", value)}
                />
                <Input
                  type="text"
                  placeholder="Reward Points"
                  value={dataSet.reward_points}
                  onChange={(value) =>
                    handleInputChange("reward_points", value)
                  }
                />
                <DropSelect
                  label="Has Promotion"
                  value={dataSet.has_promotion}
                  options={[
                    { title: "Select an option", value: "", disabled: true },
                    { title: "true", value: true },
                    { title: "False", value: false },
                  ]}
                  onChange={(value) =>
                    handleInputChange("has_promotion", value)
                  }
                />
                <Input
                  type="text"
                  placeholder="Promotional Price"
                  value={dataSet.promotional_price || ""}
                  onChange={(value) =>
                    handleInputChange("promotional_price", value)
                  }
                />
                <Input
                  type="date"
                  placeholder="Promotion Start Date"
                  value={dataSet.promotions_start_date || ""}
                  onChange={(value) =>
                    handleInputChange("promotions_start_date", value)
                  }
                />
                <Input
                  type="date"
                  placeholder="Promotion End Date"
                  value={dataSet.promotions_expiry_date || ""}
                  onChange={(value) =>
                    handleInputChange("promotions_expiry_date", value)
                  }
                />

                <Input
                  type="text"
                  placeholder="Unit Name"
                  value={dataSet.unit_name || ""}
                  onChange={(value) => handleInputChange("unit_name", value)}
                />
                <Input
                  type="text"
                  placeholder="Unit Value"
                  value={dataSet.unit_value || ""}
                  onChange={(value) => handleInputChange("unit_value", value)}
                />
              </div>
            </div>
            <div className="row mt-4 py-4 border-top border-2 border-secondary">
              <DropSelect
                label="Has Variant ?"
                options={[
                  { title: "Select an option", value: "", disabled: true },
                  { title: "Yes", value: true },
                  { title: "No", value: false },
                ]}
                value={dataSet.has_variant}
                onChange={handleVariantOption}
              />
              {dataSet.has_variant === true ? (
                <ProductVariantsEdit
                  productVariants={productVariants}
                  onVariantDataChange={handleVariantDataChange}
                />
              ) : (
                ""
              )}
            </div>
            <AdditionalPropEdit
              additionProps={additionalProps}
              onAdditionPropsChange={handleAdditionalPropsChange}
            />
            <div className="row mt-4 pb-3 pt-5 border-top border-2 border-secondary">
              <div className="d-flex flex-wrap">
                <div className="me-3 add-images-input">
                  <InputFile
                    id="add-producr-images"
                    imageChange={handleProductImages}
                    multiple={true}
                  />
                </div>
                <div className="d-flex">
                  {selectedImages.map((image, index) => {
                    return (
                      <div className="add-images-wrapper" key={index}>
                        <img
                          className="img-fluid"
                          src={image.document_url}
                          alt={`product-${index}`}
                        />
                        <button
                          className="border-0 bg-transparent multi-img-close-btn"
                          onClick={handleProductImageRemove}
                          type="button"
                        >
                          <IoMdCloseCircleOutline className="fs-4 text-secondary" />
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <button className="btn btn-dark my-3 py-2 w-100" type="submit">
              {shouldSubmit ? "Adding Product..." : "Add Product"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditProduct;
