import React from "react";
import { Route, Routes } from "react-router-dom";
import Rider from "../../view/delivery/rider/Rider";
import EditRider from "../../view/delivery/rider/EditRider";
import AddRider from "../../view/delivery/rider/AddRider";
import { ProtectedRoutes } from "../ProtectedRoutes";
import DeliveryCharge from "../../view/delivery/delivery-charge/DeliveryCharge";
import EditDeliveryCharge from "../../view/delivery/delivery-charge/EditDeliveryCharge";
import AddDeliveryCharge from "../../view/delivery/delivery-charge/AddDeliveryCharge";

const DeliveryRoots = () => {
  return (
    <Routes>
      <Route
        path="/delivery-charge"
        element={<ProtectedRoutes Component={DeliveryCharge} />}
      />
      <Route
        path="/delivery-charge/add-delivery-charge"
        element={<ProtectedRoutes Component={AddDeliveryCharge} />}
      />
      <Route
        path="/delivery-charge/edit-delivery-charge/:id"
        element={<ProtectedRoutes Component={EditDeliveryCharge} />}
      />
      <Route path="/rider" element={<ProtectedRoutes Component={Rider} />} />
      <Route
        path="/rider/add-rider"
        element={<ProtectedRoutes Component={AddRider} />}
      />
      <Route
        path="/rider/edit-rider/:id"
        element={<ProtectedRoutes Component={EditRider} />}
      />
    </Routes>
  );
};

export default DeliveryRoots;
