import React, { useState } from "react";
import AuthContainer from "../AuthContainer";
import logo from "../../../assets/Logo.png";
import FloatingInput from "../../../component/inputs/FloatingInput";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../../../API/AxiosInstance";

const Login = () => {
  const [loginData, setLoginData] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const handleInputChange = (name, value) => {
    setLoginData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axiosInstance.post("auth/login/", loginData);
      console.log(response);
      const token = response.data.data.token;
      localStorage.setItem("token", token);
      localStorage.setItem("login", true);
      let login = localStorage.getItem("login");
      if (login) {
        navigate("/");
      }
    } catch (error) {
      setErrorMsg(
        error.response?.data?.errors?.mobile?.[0] ||
          error.response?.data?.message
      );
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <AuthContainer
        titleLG="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Autem
                ex perferendis"
        titleSM="Lorem ipsum dolor sit amet consectetur adipisicing elit. Est,
                possimus?"
      >
        <div className="p-4">
          <div className="w-100 d-flex justify-content-center">
            <img className="img-fluid mt-3" src={logo} alt="techtsy" />
          </div>
          {loading ? <p className="text-center">Loading...</p> : ""}
          {errorMsg ? (
            <p className="fade-bg px-1 py-2 text-center">{errorMsg}</p>
          ) : (
            ""
          )}
          <h4 className="my-4">Sign In</h4>
          <form onSubmit={handleSubmit}>
            <FloatingInput
              type="text"
              placeholder="Phone Number"
              onChange={(value) => handleInputChange("mobile", value)}
              required={true}
            />
            <FloatingInput
              type="password"
              placeholder="Password"
              onChange={(value) => handleInputChange("password", value)}
              required={true}
            />
            <div className="d-flex align-items-center justify-content-between">
              <div className="form-check">
                <input className="form-check-input p-0" type="checkbox" />
                <label className="form-check-label">Remember me</label>
              </div>
              <Link to="/forgot-password" className="text-default text-sm">
                Forgot Password?
              </Link>
            </div>
            <div className="my-3">
              <button type="submit" className="btn btn-dark">
                Login
              </button>
            </div>
            <div className="my-2">
              <span>
                Not Registered ?{" "}
                <Link
                  to="/sign-up"
                  className="text-default text-decoration-underline"
                >
                  Register
                </Link>{" "}
                from here.
              </span>
            </div>
          </form>
        </div>
      </AuthContainer>
    </>
  );
};

export default Login;
