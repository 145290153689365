import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import { getData } from "../../../API/getData";
import useDeleteModal from "../../../utilities/DeleteModal";
import { AiOutlineDelete } from "react-icons/ai";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { productReviewColumns } from "../../../data/tableColumns";
import Table from "../../../component/table/Table";
import Modal from "../../../component/modal/Modal";

const ProductReview = () => {
  const [reviewData, setReviewData] = useState([]);

  const fetchReviewData = () => {
    getData("inventory/admin/productreview/")
      .then((reviews) => {
        setReviewData(reviews);
      })
      .catch((err) => {
        console.log("error getting reviews", err);
      });
  };
  useEffect(() => {
    fetchReviewData();
  }, []);
  // console.log(reviewData);

  const { showDeleteModal, DeleteModal } = useDeleteModal(
    "inventory/admin/productreview"
  );

  const [reviewModal, setReviewModal] = useState(false);
  const [reviewinfo, setReviewInfo] = useState({});
  const popUpReview = (data) => {
    setReviewInfo(data);
    setReviewModal(true);
  };

  const actionColumn = {
    title: "Actions",
    key: "actions",
    render: (data) => {
      return (
        <div className="d-flex align-items-center">
          <button
            className="bg-danger p-1 border-0 me-2"
            onClick={() => showDeleteModal(data, fetchReviewData)}
          >
            <AiOutlineDelete className="text-white" />
          </button>
          <button
            className="bg-light border p-1 ms-2"
            onClick={() => popUpReview(data)}
          >
            <MdOutlineRemoveRedEye className="text-dark" />
          </button>
        </div>
      );
    },
  };

  const finalColumn = [...productReviewColumns, actionColumn];
  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb directory1="Inventory" directory2="Product Review" />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search mb-3">
            <h5>All Product Reviews</h5>
          </div>
          <div className="table-area">
            <Table columns={finalColumn} dataSource={reviewData} />
          </div>
        </div>
      </div>
      {/* delete item */}
      <DeleteModal />
      {/* review popup */}
      <div className="review-popup">
        <Modal
          title="Preview Details"
          visible={reviewModal}
          onCancel={() => {
            setReviewInfo({});
            setReviewModal(false);
          }}
          onOk={() => {
            setReviewInfo({});
            setReviewModal(false);
          }}
          content={
            <div>
              <div className="row">
                <div className="col-4">
                  <p>Id</p>
                  <p>Created at</p>
                  <p>User</p>
                  <p>Product</p>
                  <p>Rating</p>
                  <p>Comment</p>
                </div>
                <div className="col-8">
                  <p>{reviewinfo.id}</p>
                  <p>{reviewinfo.created_at || "Not found"}</p>
                  <p>{reviewinfo.user_name || "Not found"}</p>
                  <p>{reviewinfo.product_name}</p>
                  <p>{reviewinfo.rating}</p>
                  <p>{reviewinfo.comment}</p>
                </div>
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default ProductReview;
