import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import Input from "../../../component/inputs/Input";
import DropSelect from "../../../component/dropdowns/DropSelect";
import { getFileType, isActive, pageTypeData } from "../../../data/data";
import InputFile from "../../../component/inputs/FileInput";
import { postDocumentData } from "../../../API/postDocumentData";
import { useNavigate, useParams } from "react-router-dom";
import { GETData } from "../../../API/GETDataV2";
import putData from "../../../API/putData";

const EditPage = () => {
  const { id } = useParams();
  const [page, setPage] = useState({});
  const [fileInput, setFileInput] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [fileInput2, setFileInput2] = useState();
  const [selectedImage2, setSelectedImage2] = useState(null);
  const [shouldSubmit, setShouldSubmit] = useState(false);

  useEffect(() => {
    GETData(`utility/admin/page/${id}/`).then((res) => {
      setPage(res.data.data);
      setSelectedImage(res.data.data.thumbnail_url);
      setSelectedImage2(res.data.data.attachment_url || "");
    });
  }, [id]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setFileInput(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleImageChange2 = (event) => {
    const file = event.target.files[0];
    setFileInput2(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage2(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (name, value) => {
    setPage((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (fileInput) {
      const formData = new FormData();
      formData.append("document", fileInput);
      const docType = getFileType(fileInput.name);
      formData.append("doc_type", docType);

      try {
        const docUpload = await postDocumentData(
          "auth/documents/upload/",
          formData
        );

        setPage((prev) => ({
          ...prev,
          thumbnail: docUpload.data.id,
        }));

        setShouldSubmit(true);
      } catch (error) {
        console.log(error);
      }
    } else if (fileInput2) {
      const formData2 = new FormData();
      formData2.append("document", fileInput2);
      const docType2 = getFileType(fileInput2.name);
      formData2.append("doc_type", docType2);

      try {
        const docUpload2 = await postDocumentData(
          "auth/documents/upload/",
          formData2
        );

        setPage((prev) => ({
          ...prev,
          attachment: docUpload2.data.id,
        }));
        setShouldSubmit(true);
      } catch (error) {
        console.log(error);
      }
    } else {
      setShouldSubmit(true);
    }
  };

  const navigate = useNavigate();
  if (shouldSubmit) {
    putData(`utility/admin/page/${id}/`, {
      title: page.title,
      desc: page.desc,
      video_url: page.video_url,
      page_type: page.page_type,
      is_active: page.is_active,
      thumbnail: page.thumbnail,
      attachment: page.attachment,
    })
      .then((res) => {
        console.log("edit page response", res);
        navigate("/utility/page");
        setShouldSubmit(false);
      })
      .catch((err) => {
        console.log("error adding page data", err);
      });
  }
  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb
          directory1="Utility"
          directory2="Page"
          directory3="Edit Page"
          path="/utility/page"
        />
      </div>
      <div className="p-3">
        <div className="p-3 bg-white">
          <div className="title-and-search mb-3">
            <h5>Add Page</h5>
          </div>
          <div>
            <form action="" onSubmit={handleSubmit}>
              <Input
                type="text"
                placeholder="Title"
                value={page.title || ""}
                onChange={(value) => handleInputChange("title", value)}
              />
              <Input
                type="text"
                placeholder="Description"
                value={page.desc || ""}
                onChange={(value) => handleInputChange("desc", value)}
              />
              <Input
                type="text"
                placeholder="Video URL"
                value={page.video_url || ""}
                onChange={(value) => handleInputChange("video_url", value)}
              />
              <DropSelect
                label="Page Type"
                options={pageTypeData}
                value={page.page_type}
                onChange={(value) => handleInputChange("page_type", value)}
              />
              <DropSelect
                label="Status"
                options={isActive}
                value={page.is_active || ""}
                onChange={(value) => handleInputChange("is_active", value)}
              />
              <div>
                <label htmlFor="" className="fw-bold mb-2">
                  Page Thumbnail
                </label>
                <div className="profile-img-wrapper">
                  {selectedImage ? (
                    <img
                      className="mb-2 img-fluid"
                      src={selectedImage || ""}
                      alt="page-thumb"
                    />
                  ) : (
                    ""
                  )}
                </div>
                <InputFile
                  id="page-thumbnail"
                  imageChange={handleImageChange}
                />
              </div>
              <div>
                <label htmlFor="" className="fw-bold mb-2">
                  Attachment
                </label>
                <div className="profile-img-wrapper">
                  {selectedImage ? (
                    <img
                      className="mb-2 img-fluid"
                      src={selectedImage2 || ""}
                      alt="page-attach"
                    />
                  ) : (
                    ""
                  )}
                </div>
                <InputFile
                  id="page-attachment"
                  imageChange={handleImageChange2}
                />
              </div>
              <button className="my-3 btn btn-dark w-100" type="submit">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPage;
