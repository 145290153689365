import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import AddNew from "../../../component/buttons/AddNew";
import Table from "../../../component/table/Table";
import { GETData } from "../../../API/GETDataV2";
import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit2 } from "react-icons/fi";
import { systemAlertData } from "../../../data/tableColumns";
import useDeleteModal from "../../../utilities/DeleteModal";
import { useNavigate } from "react-router-dom";

const SystemAlert = () => {
  const [tableData, setTableData] = useState([]);
  const fetchAlertData = () => {
    GETData("notification/admin/system-alert/").then((res) => {
      setTableData(res.data.data.results);
    });
  };

  useEffect(() => {
    fetchAlertData();
  }, []);

  const { showDeleteModal, DeleteModal } = useDeleteModal(
    "notification/admin/system-alert"
  );

  const navigate = useNavigate();
  const actionColumn = {
    title: "Actions",
    key: "actions",
    render: (data) => {
      // console.log(data);
      return (
        <div className="d-flex align-items-center">
          <button
            className="primary-bg p-1 border-0 me-2"
            onClick={() =>
              navigate(`/notifications/system-alert/edit-alert/${data.id}`)
            }
          >
            <FiEdit2 className="text-white" />
          </button>

          <button
            className="bg-danger p-1 border-0 ms-2"
            onClick={() => showDeleteModal(data, fetchAlertData)}
          >
            <AiOutlineDelete className="text-white" />
          </button>
        </div>
      );
    },
  };

  const finalColumn = [...systemAlertData, actionColumn];
  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb directory1="Notifications" directory2="System Alert" />
        <AddNew path="/notifications/system-alert/add-new" />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search mb-3">
            <h5>System Alerts</h5>
          </div>
          <div className="table-area">
            <Table columns={finalColumn} dataSource={tableData} />
          </div>
        </div>
      </div>
      <DeleteModal />
    </div>
  );
};

export default SystemAlert;
