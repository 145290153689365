import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import Input from "../../../component/inputs/Input";
import DropSelect from "../../../component/dropdowns/DropSelect";
import { couponType, discountType, isActive } from "../../../data/data";
import { GETData } from "../../../API/GETDataV2";
import { IoMdCloseCircleOutline } from "react-icons/io";
import postData from "../../../API/postData";
import { useNavigate } from "react-router-dom";

const AddCoupon = () => {
  const [couponData, setCouponData] = useState({});
  const [userData, setUserData] = useState([]);
  const fetchUserData = () => {
    GETData("auth/user/").then((res) => {
      setUserData(res.data.data);
    });
  };

  const [selectedOptions, setSelectedOptions] = useState([]);
  const handleOptionSelect = (selectedOption) => {
    if (!selectedOptions.includes(selectedOption)) {
      setSelectedOptions([...selectedOptions, parseInt(selectedOption, 10)]);
    }
  };

  useEffect(() => {
    fetchUserData();
    setCouponData((prev) => ({
      ...prev,
      customers: selectedOptions,
    }));
  }, [selectedOptions]);

  const handleRemoveOption = (removedOption) => {
    const updatedOptions = selectedOptions.filter(
      (option) => option !== removedOption
    );
    setSelectedOptions(updatedOptions);
  };

  const handleInputChange = (name, value) => {
    if (
      name === "coupon_type" ||
      name === "discount_type" ||
      name === "maximum_discount" ||
      name === "max_usage" ||
      name === "usage_count"
    ) {
      value = value ? parseInt(value, 10) : 0;
    }
    setCouponData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    postData("sales/admin/coupon/", couponData)
      .then((res) => {
        console.log("add coupon response", res);
        navigate("/sales/coupon");
      })
      .catch((err) => {
        console.log("error adding coupon", err);
      });
  };

  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb
          directory1="Sales"
          directory2="Coupon"
          directory3="Add Coupon"
          path="/sales/coupon"
        />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search mb-3">
            <h5>Add Coupon</h5>
          </div>
          <form onSubmit={handleSubmit}>
            <Input
              type="text"
              placeholder="Coupon title"
              onChange={(value) => handleInputChange("coupon_title", value)}
            />
            <DropSelect
              label="Coupon Type"
              options={couponType}
              onChange={(value) => handleInputChange("coupon_type", value)}
            />
            <DropSelect
              label="Discount type"
              options={discountType}
              onChange={(value) => handleInputChange("discount_type", value)}
            />
            <Input
              type="date"
              placeholder="Start Date"
              onChange={(value) => handleInputChange("start_date", value)}
            />
            <Input
              type="date"
              placeholder="Expiry Date"
              onChange={(value) => handleInputChange("expire_date", value)}
            />
            <Input
              type="text"
              placeholder="Discount Amount"
              onChange={(value) => handleInputChange("discount_amount", value)}
            />
            <Input
              type="text"
              placeholder="Minimum Purchase"
              onChange={(value) => handleInputChange("minimum_purchase", value)}
            />
            <Input
              type="text"
              placeholder="Maximum Discount"
              onChange={(value) => handleInputChange("maximum_discount", value)}
            />
            <Input
              type="text"
              placeholder="Maximum Usage"
              onChange={(value) => handleInputChange("max_usage", value)}
            />
            <Input
              type="text"
              placeholder="Usage Count"
              onChange={(value) => handleInputChange("usage_count", value)}
            />
            <DropSelect
              label="Status"
              options={isActive}
              onChange={(value) => handleInputChange("is_active", value)}
            />
            <DropSelect
              label="Customers"
              options={[
                {
                  value: "",
                  title: "Select customer",
                  disabled: true,
                },
                ...userData.map((item) => ({
                  value: item.id,
                  title: item.user_name,
                })),
              ]}
              onChange={handleOptionSelect}
            />
            <div className="mt-4 d-flex flex-wrap">
              {selectedOptions.map((selectedOption) => {
                const selectedUser = userData.find(
                  (user) => user.id === selectedOption
                );

                return (
                  <div
                    key={selectedOption}
                    className="fade-bg p-2 position-relative me-3"
                  >
                    <p className="mb-0">
                      {selectedUser ? selectedUser.user_name : "Unknown"}
                    </p>
                    <button
                      className="position-absolute bg-transparent border-0 req-doc-close"
                      onClick={() => handleRemoveOption(selectedOption)}
                    >
                      <IoMdCloseCircleOutline className="fs-5" />
                    </button>
                  </div>
                );
              })}
            </div>
            <button className="btn btn-dark w-100 my-3" type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddCoupon;
