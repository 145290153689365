import React from "react";
import { Route, Routes } from "react-router-dom";
import Brand from "../../view/inventory/brand/Brand";
import { ProtectedRoutes as InventoryProtectedRoutes } from "../ProtectedRoutes";
import AddBrand from "../../view/inventory/brand/AddBrand";
import EditBrand from "../../view/inventory/brand/EditBrand";
import Category from "../../view/inventory/category/Category";
import Products from "../../view/inventory/product/Products";
import AddProduct from "../../view/inventory/product/AddProduct";
import AddCategory from "../../view/inventory/category/AddCategory";
import EditCategory from "../../view/inventory/category/EditCategory";
import EditProduct from "../../view/inventory/product/EditProduct";
import ProductReview from "../../view/inventory/product-review/ProductReview";
import Promotion from "../../view/inventory/promotion/Promotion";
import VariantGroup from "../../view/inventory/variant-group/VariantGroup";
import AddVariantGroup from "../../view/inventory/variant-group/AddVariantGroup";
import EditVariantGroup from "../../view/inventory/variant-group/EditVariantGroup";
import VariantOption from "../../view/inventory/variant-option/VariantOption";
import AddVariantOption from "../../view/inventory/variant-option/AddVariantOption";
import EditVariantOption from "../../view/inventory/variant-option/EditVariantOption";

const InventoryRoutes = () => {
  return (
    <Routes>
      {/* brands */}
      <Route
        path="/brands"
        element={<InventoryProtectedRoutes Component={Brand} />}
      />
      <Route
        path="/brands/add-brand"
        element={<InventoryProtectedRoutes Component={AddBrand} />}
      />
      <Route
        path="/brands/edit-brand/:id"
        element={<InventoryProtectedRoutes Component={EditBrand} />}
      />
      {/* categories */}
      <Route
        path="/categories"
        element={<InventoryProtectedRoutes Component={Category} />}
      />
      <Route
        path="/categories/add-new"
        element={<InventoryProtectedRoutes Component={AddCategory} />}
      />
      <Route
        path="/categories/edit-category/:id"
        element={<InventoryProtectedRoutes Component={EditCategory} />}
      />
      <Route
        path="/products"
        element={<InventoryProtectedRoutes Component={Products} />}
      />
      <Route
        path="/products/add-new"
        element={<InventoryProtectedRoutes Component={AddProduct} />}
      />
      <Route
        path="/products/edit-product/:id"
        element={<InventoryProtectedRoutes Component={EditProduct} />}
      />

      {/* product-review */}
      <Route
        path="/product-review"
        element={<InventoryProtectedRoutes Component={ProductReview} />}
      />
      {/* promotion */}
      <Route
        path="/promotions"
        element={<InventoryProtectedRoutes Component={Promotion} />}
      />
      {/* variant group */}
      <Route
        path="/variant-group"
        element={<InventoryProtectedRoutes Component={VariantGroup} />}
      />
      <Route
        path="/variant-group/add-variant"
        element={<InventoryProtectedRoutes Component={AddVariantGroup} />}
      />
      <Route
        path="/variant-group/edit-variant/:id"
        element={<InventoryProtectedRoutes Component={EditVariantGroup} />}
      />
      {/* variant options */}
      <Route
        path="/variant-option"
        element={<InventoryProtectedRoutes Component={VariantOption} />}
      />
      <Route
        path="/variant-option/add-option"
        element={<InventoryProtectedRoutes Component={AddVariantOption} />}
      />
      <Route
        path="/variant-option/edit-option/:id"
        element={<InventoryProtectedRoutes Component={EditVariantOption} />}
      />
    </Routes>
  );
};

export default InventoryRoutes;
