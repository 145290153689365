import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import { getData } from "../../../API/getData";
import Table from "../../../component/table/Table";
import { bannerColumns } from "../../../data/tableColumns";
import { useNavigate } from "react-router-dom";
import useDeleteModal from "../../../utilities/DeleteModal";
import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit2 } from "react-icons/fi";
import AddNew from "../../../component/buttons/AddNew";

const Banner = () => {
  const [banners, setBanners] = useState([]);
  const fetchBanners = () => {
    getData("utility/admin/banner/")
      .then((res) => {
        setBanners(res);
      })
      .catch((err) => {
        console.log("error getting banners");
      });
  };
  useEffect(() => {
    fetchBanners();
  }, []);

  const navigate = useNavigate();
  const { showDeleteModal, DeleteModal } = useDeleteModal(
    "utility/admin/banner"
  );

  const actionColumn = {
    title: "Actions",
    key: "actions",
    render: (data) => {
      return (
        <div className="d-flex align-items-center">
          <button
            className="primary-bg p-1 border-0 me-2"
            onClick={() => navigate(`/utility/banner/edit-banner/${data.id}`)}
          >
            <FiEdit2 className="text-white" />
          </button>

          <button
            className="bg-danger p-1 border-0 ms-2"
            onClick={() => showDeleteModal(data, fetchBanners)}
          >
            <AiOutlineDelete className="text-white" />
          </button>
        </div>
      );
    },
  };

  const finalColumn = [...bannerColumns, actionColumn];
  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb directory1="Utility" directory2="Banner" />
        <AddNew path="/utility/banner/add-new" />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search mb-3">
            <h5>Banners</h5>
          </div>
          <div className="table-area">
            <Table columns={finalColumn} dataSource={banners} />
          </div>
        </div>
      </div>
      {/* delete item */}
      <DeleteModal />
    </div>
  );
};

export default Banner;
