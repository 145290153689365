import React from "react";
import { IoCameraOutline } from "react-icons/io5";

const InputFile = ({ id, imageChange, multiple }) => {
  return (
    <div className="mb-3">
      <label className="p-1 px-3 border fade-bg" htmlFor={id}>
        <IoCameraOutline className="me-2 mb-1" />
        Image Upload
      </label>
      <input
        className="d-none"
        id={id}
        type="file"
        onChange={imageChange}
        {...(multiple ? { multiple: true } : {})}
      />
    </div>
  );
};

export default InputFile;
