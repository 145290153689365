import React, { useEffect, useState } from "react";
import SwitchColumn from "../utilities/SwitchColumn";
import { GETData } from "../API/GETDataV2";
import FormattedDate from "../utilities/FormattedDate";
import {
  faqTypeData,
  membershipAndPackageType,
  notificationTypeChoice,
  orderStatusData,
  pageTypeData,
  priorityChoices,
  reasonTypeData,
  ticketStatusChoice,
} from "./data";

const FindUsernameById = ({ userId }) => {
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    GETData("auth/user/").then((res) => {
      setUserData(res.data.data);
    });
  }, []);

  const foundUser = userData ? userData.find((user) => user.id === userId) : "";

  return <span>{foundUser ? foundUser.user_name : "Unknown"}</span>;
};

export const brandColumns = [
  { title: "ID", key: "id" },
  { title: "Brand Name", key: "name" },
  { title: "Logo", key: "logo_url" },
  SwitchColumn({ apiEndpoint: "inventory/admin/brand" }),
];

export const notificationColumns = [
  { title: "ID", key: "id" },
  { title: "Title", key: "title" },
  { title: "Message", key: "message" },
  {
    title: "Time Stamp",
    key: "timestamp",
    render: (data) => {
      return <FormattedDate timestamp={data.timestamp} />;
    },
  },
  {
    title: "Notification Type",
    key: "notification_type",
    render: (data) => {
      const notificationTitle = notificationTypeChoice.find(
        (type) => type.value === data.notification_type
      );
      return notificationTitle ? notificationTitle.title : "Unknown";
    },
  },
  {
    title: "Is Read",
    key: "is_read",
    render: (data) => {
      return data.is_read === true ? (
        <span className="fade-bg p-1 rounded-2">True</span>
      ) : (
        <span className="bg-danger text-white p-1 rounded-2">False</span>
      );
    },
  },
  {
    title: "User",
    key: "user",
    render: (data) => <FindUsernameById userId={data.user} />,
  },
];

export const systemAlertData = [
  { title: "ID", key: "id" },
  { title: "Title", key: "title" },
  { title: "Message", key: "message" },

  {
    title: "Users",
    key: "users",
    render: (data) => {
      if (data.users && data.users.length > 0) {
        return (
          <span>
            {data.users.map((userId, index) => (
              <React.Fragment key={userId}>
                <FindUsernameById userId={userId} />
                {index < data.users.length - 1 && ", "}
              </React.Fragment>
            ))}
          </span>
        );
      } else {
        return <span>No Users</span>;
      }
    },
  },
];

export const categoryDataColumns = [
  { title: "ID", key: "id" },
  { title: "Name", key: "name" },
  { title: "Image", key: "image_url" },
  { title: "Parent", key: "parent_name" },
  { title: "Product Count", key: "product_count" },
  { title: "Slug", key: "slug" },
  SwitchColumn({ apiEndpoint: "inventory/admin/category" }),
];

export const productDataColumns = [
  { title: "ID", key: "id" },
  { title: "Name", key: "name" },
  { title: "Image", key: "thumbnail_url" },
  { title: "SKU", key: "sku" },
  { title: "Brand", key: "brand_name" },
  { title: "Category", key: "category_name" },
  { title: "Parent Category", key: "parent_category_name" },
  { title: "Code", key: "product_code" },
  { title: "Stock Status", key: "stock_status_display" },
  { title: "Product Count", key: "product_count" },
  SwitchColumn({ apiEndpoint: "inventory/admin/product" }),
];

export const productReviewColumns = [
  { title: "ID", key: "id" },
  { title: "Image", key: "product_image" },
  { title: "Product", key: "product_name" },
  { title: "Rating", key: "rating" },
];

export const promotionsColumn = [
  { title: "ID", key: "id" },
  { title: "Name", key: "name" },
  { title: "Promotional Price", key: "promotional_price" },
  { title: "Status", key: "promotion_status" },
];

export const variantGroupsColumn = [
  { title: "ID", key: "id" },
  { title: "Group Name", key: "group_name" },
  SwitchColumn({ apiEndpoint: "inventory/admin/variant-group" }),
];

export const variantOptionsColumn = [
  { title: "ID", key: "id" },
  { title: "Name", key: "name" },
  { title: "Group", key: "group" },
  SwitchColumn({ apiEndpoint: "inventory/admin/variant-option" }),
];

export const couponColumns = [
  { title: "ID", key: "id" },
  { title: "Code", key: "coupon_code" },
  { title: "Title", key: "coupon_title" },
  { title: "Coupon Type", key: "coupon_type" },
  { title: "Discount Type", key: "discount_type" },
  {
    title: "Start date",
    key: "start_date",
    render: (data) => {
      return <FormattedDate timestamp={data.start_date} />;
    },
  },
  {
    title: "Expiry date",
    key: "expire_date",
    render: (data) => {
      return <FormattedDate timestamp={data.expire_date} />;
    },
  },
  { title: "Discount amount", key: "discount_amount" },
  { title: "Minimum purchase", key: "minimum_purchase" },
  { title: "Maximum discount", key: "maximum_discount" },
  { title: "Max usage", key: "max_usage" },
  { title: "Usage Count", key: "usage_count" },
  SwitchColumn({ apiEndpoint: "sales/admin/coupon" }),
  {
    title: "Customers",
    key: "customers",
    render: (data) => {
      return (
        <span>
          {data.customers.map((userId, index) => (
            <React.Fragment key={userId}>
              <FindUsernameById userId={userId} />
              {index < data.customers.length - 1 && ", "}
            </React.Fragment>
          ))}
        </span>
      );
    },
  },
];

export const orderColumns = [
  { title: "ID", key: "id" },
  {
    title: "Order at",
    key: "created_at",
    render: (data) => {
      return <FormattedDate timestamp={data.created_at} />;
    },
  },
  { title: "Customer Name", key: "customer_name" },
  { title: "Mobile", key: "customer_mobile" },
  { title: "Invoice No", key: "invoice_no" },
  { title: "Item Count", key: "item_count" },
  {
    title: "Order Status",
    key: "order_status",
    render: (data) => {
      const orderStatus = orderStatusData.find(
        (item) => item.value === data.order_status
      );
      return orderStatus ? orderStatus.title : "Unknown";
    },
  },
  { title: "Total", key: "total" },
];

export const cancelReasonColumns = [
  { title: "ID", key: "id" },
  {
    title: "Reason Type",
    key: "reason_type",
    render: (data) => {
      const reasonType = reasonTypeData.find(
        (item) => item.value === data.reason_type
      );
      return reasonType ? reasonType.title : "Unknown";
    },
  },
  { title: "Reason Name", key: "reason_name" },
  SwitchColumn({ apiEndpoint: "sales/admin/reason" }),
];

export const ticketColumns = [
  { title: "ID", key: "id" },
  { title: "Description", key: "description" },
  { title: "Subject", key: "subject" },
  {
    title: "Priority",
    key: "priority",
    render: (data) => {
      const priority = priorityChoices.find(
        (item) => item.value === data.priority
      );
      return priority ? priority.title : "Unknown";
    },
  },
  {
    title: "Status",
    key: "status",
    render: (data) => {
      const status = ticketStatusChoice.find(
        (item) => item.value === data.status
      );
      return status ? status.title : "Unknown";
    },
  },
];

export const packagesColumns = [
  { title: "ID", key: "id" },
  { title: "Name", key: "package_name" },
  { title: "Price", key: "price" },
  {
    title: "Type",
    key: "package_type",
    render: (data) => {
      const type = membershipAndPackageType.find(
        (item) => item.value === data.package_type
      );
      return type ? type.title : "Unknown";
    },
  },
  {
    title: "Created at",
    key: "created_at",
    render: (data) => {
      return <FormattedDate timestamp={data.created_at} />;
    },
  },
  { title: "Duration (days)", key: "duration" },
  { title: "Details", key: "details" },
  SwitchColumn({ apiEndpoint: "package/admin/package" }),
];

export const subscriptionColumns = [
  { title: "ID", key: "id" },
  // { title: "Customer Name", key: "customer_name" },
  // { title: "Amount", key: "amount" },
  {
    title: "Package",
    key: "package",
    render: (data) => {
      const type = membershipAndPackageType.find(
        (item) => item.value === data.package
      );
      return type ? type.title : "Unknown";
    },
  },
  // { title: "Start Date", key: "start_date" },
  { title: "Expiry Date", key: "expiry_date" },
  {
    title: "Expiry Status",
    key: "is_expired",
    render: (data) => {
      const status = data.is_expired;
      return status ? (
        <span className="bg-danger p-1 rounded-3">Expired</span>
      ) : (
        <span className="bg-success p-1 rounded-3 text-white">Active</span>
      );
    },
  },
  {
    title: "Customer",
    key: "customer",
    render: (data) => <FindUsernameById userId={data.customer} />,
  },
];

export const bannerColumns = [
  { title: "ID", key: "id" },
  { title: "Image", key: "image_url" },
  { title: "Name", key: "name" },
  { title: "Expiry Date", key: "expiry_date" },
  { title: "Position", key: "position" },
  SwitchColumn({ apiEndpoint: "utility/admin/banner" }),
];

export const currencyColumns = [
  { title: "ID", key: "id" },
  { title: "Name", key: "currency_name" },
  {
    title: "Image",
    key: "currency_sign_url",
    render: (data) => {
      return (
        <img
          src={data.currency_sign_url}
          alt="currencies"
          style={{ height: "40px", width: "40px" }}
        />
      );
    },
  },
  { title: "Currency Rate", key: "currency_rate" },
  SwitchColumn({ apiEndpoint: "utility/admin/currency" }),
];

export const faqColumns = [
  { title: "ID", key: "id" },
  {
    title: "Type",
    key: "faq_type",
    render: (data) => {
      const type = faqTypeData.find((item) => item.value === data.faq_type);
      return type ? type.title : "Unknown";
    },
  },
  { title: "Question", key: "question" },
  { title: "Answer", key: "answer" },
  { title: "Position", key: "position" },
  SwitchColumn({ apiEndpoint: "utility/admin/faq" }),
];

export const globalSettingColumns = [
  { title: "ID", key: "id" },
  { title: "Logo", key: "logo_url" },
  { title: "Site Name", key: "site_name" },
  { title: "Website Url", key: "website_url" },
  { title: "Email", key: "email" },
  { title: "Phone", key: "phone" },
  { title: "Address", key: "address" },
  { title: "Short Description", key: "short_desc" },
  { title: "Facebook", key: "facebook" },
  { title: "Twitter", key: "twitter" },
  { title: "Linkedin", key: "linkedin" },
  { title: "Instagram", key: "instagram" },
  { title: "Youtube", key: "youtube" },
  { title: "Shipping Fee", key: "shipping_fee" },
  { title: "VAT Percentage", key: "vat_percentage" },
  { title: "Delivery Commission", key: "delivery_commission" },
  { title: "Min Withdraw Amount", key: "min_withdraw_amount" },
  { title: "Max Withdraw Amount", key: "max_withdraw_amount" },
  { title: "Paypal Client ID", key: "paypal_client_id" },
  { title: "Paypal Client Secret", key: "paypal_client_secret" },
  { title: "SMS Server", key: "sms_server" },
  { title: "SMS Username", key: "sms_username" },
  { title: "SMS Phone Number", key: "sms_phone_number" },
  { title: "SMS API Key", key: "sms_api_key" },
  { title: "User FCM Key", key: "user_fcm_key" },
  { title: "Rider FCM Server Key", key: "rider_fcm_server_key" },
  { title: "Sender Email", key: "sender_email" },
  { title: "Email Host", key: "email_host" },
  { title: "Username", key: "username" },
  { title: "Password", key: "password" },
  { title: "Port", key: "port" },
];

export const pageColumns = [
  { title: "ID", key: "id" },
  { title: "Thumbnail", key: "thumbnail_url" },
  { title: "TItle", key: "title" },
  { title: "Description", key: "description" },
  { title: "Slug", key: "slug" },
  {
    title: "Video Url",
    key: "video_url",
    render: (data) => {
      return (
        <a href={data.video_url} target="_blank" rel="noreferrer">
          {data.video_url}
        </a>
      );
    },
  },
  {
    title: "Page Type",
    key: "page_type",
    render: (data) => {
      const pageType = pageTypeData.find(
        (item) => item.value === data.page_type
      );
      return pageType ? pageType.title : "Unknown";
    },
  },
  SwitchColumn({ apiEndpoint: "utility/admin/page" }),
  { title: "Slug", key: "slug" },
];

export const blogColumns = [
  { title: "ID", key: "id" },
  { title: "Title", key: "title" },
  { title: "Author", key: "author_name" },
  {
    title: "Content",
    key: "content",
    render: (data) => {
      const words = data.content.split(" ");

      const truncatedContent = words.slice(0, 10).join(" ");

      const contentToShow =
        words.length > 20 ? `${truncatedContent}...` : truncatedContent;

      return <span>{contentToShow}</span>;
    },
  },
  {
    title: "Created at",
    key: "created_at",
    render: (data) => {
      return <FormattedDate timestamp={data.created_at} />;
    },
  },
  { title: "Image", key: "image_url" },
  { title: "Likes", key: "like_count" },
  { title: "Comments", key: "comment_count" },
];

export const likeColumns = [
  { title: "ID", key: "id" },
  { title: "Liked User", key: "liked_user" },
  {
    title: "Created at",
    key: "created_at",
    render: (data) => {
      return <FormattedDate timestamp={data.created_at} />;
    },
  },
  { title: "Post", key: "post" },
  { title: "User", key: "user" },
];

export const commentColumns = [
  { title: "ID", key: "id" },
  { title: "Comment Author", key: "comment_author" },
  {
    title: "Created at",
    key: "created_at",
    render: (data) => {
      return <FormattedDate timestamp={data.created_at} />;
    },
  },
  { title: "Content", key: "content" },
  SwitchColumn({ apiEndpoint: "blog/admin/comment" }),
  { title: "Post", key: "post" },
  { title: "User", key: "user" },
];

export const riderColumns = [
  { title: "ID", key: "id" },
  { title: "Delivery Man", key: "delivery_man" },
  { title: "COD amount", key: "cod_amount" },
  { title: "Veicle Type", key: "vehicle_type" },
  { title: "Address", key: "address" },
];

export const deliveryChargesColumns = [
  { title: "ID", key: "id" },
  { title: "Distance(KM)", key: "km_distance" },
  { title: "Amount", key: "amount" },
  SwitchColumn({ apiEndpoint: "delivery/admin/delivery_charge" }),
];
