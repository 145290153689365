import React, { useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import Input from "../../../component/inputs/Input";
import DropSelect from "../../../component/dropdowns/DropSelect";
import { isActive, reasonTypeData } from "../../../data/data";
import postData from "../../../API/postData";
import { useNavigate } from "react-router-dom";

const AddReason = () => {
  const [reason, setReason] = useState({});

  const handleInputChange = (name, value) => {
    if (name === "reason_type") {
      value = value ? parseInt(value, 10) : 0;
    }
    setReason((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    postData("sales/admin/reason/", reason)
      .then((res) => {
        console.log("reason submit response", res);
        navigate("/sales/cancel-reasons");
      })
      .catch((err) => {
        console.log("error adding reason", err);
      });
  };
  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb
          directory1="Sales"
          directory2="Cancel Reasons"
          directory3="Add New"
          path="/sales/cancel-reasons"
        />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search mb-3">
            <h5>Add Reason</h5>
          </div>
          <form onSubmit={handleSubmit}>
            <Input
              type="text"
              placeholder="Reason Name"
              onChange={(value) => handleInputChange("reason_name", value)}
            />
            <DropSelect
              label="Reason Type"
              options={reasonTypeData}
              onChange={(value) => handleInputChange("reason_type", value)}
            />
            <DropSelect
              label="Is Active"
              options={isActive}
              onChange={(value) => handleInputChange("is_active", value)}
            />

            <button className="btn btn-dark w-100 my-3" type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddReason;
