import React from "react";
import { Link } from "react-router-dom";

const Breadcrumb = ({ directory1, directory2, directory3, path, path1 }) => {
  return (
    <nav>
      <h4 className="">
        {directory3 ? directory3 : directory2 ? directory2 : directory1}
      </h4>

      <ol className="breadcrumb mb-1">
        <li className="breadcrumb-item">
          <Link
            className="text-decoration-none text-muted"
            to="/"
            onClick={() => {
              localStorage.setItem("activeMenuId", 1);
            }}
          >
            Dashboard
          </Link>
        </li>
        <li className={`breadcrumb-item ${directory2 ? "active" : ""}`}>
          <Link to={path1} className="text-decoration-none">
            {directory1}
          </Link>
        </li>
        {directory2 ? (
          <li className={`breadcrumb-item ${directory3 ? "active" : ""}`}>
            <Link
              to={directory3 ? `${path}` : "#"}
              className="text-decoration-none"
            >
              {directory2}
            </Link>
          </li>
        ) : (
          ""
        )}
        {directory3 ? <li className="breadcrumb-item">{directory3}</li> : null}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
