import React, { useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import Input from "../../../component/inputs/Input";
import DropSelect from "../../../component/dropdowns/DropSelect";
import { isActive } from "../../../data/data";
import postData from "../../../API/postData";
import { useNavigate } from "react-router-dom";

const AddVariantGroup = () => {
  const [variantData, setVariantData] = useState({});
  const handleInputChange = (name, value) => {
    setVariantData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    postData("inventory/admin/variant-group/", variantData)
      .then((res) => {
        console.log("variant group add response", res);
        navigate("/inventory/variant-group");
      })
      .catch((err) => {
        console.log("error adding variant group", err);
      });
  };
  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb
          directory1="Inventory"
          directory2="Variant Group"
          directory3="Add Variant group"
          path="/inventory/variant-group"
        />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search mb-3">
            <h5>Add Variant Group</h5>
          </div>
          <form onSubmit={handleSubmit}>
            <Input
              type="text"
              placeholder="Group Name"
              onChange={(value) => handleInputChange("group_name", value)}
              required={true}
            />
            <DropSelect
              label="Status"
              options={isActive}
              onChange={(value) => handleInputChange("is_active", value)}
            />
            <div className="mt-4">
              <button type="submit" className="btn btn-dark w-100">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddVariantGroup;
