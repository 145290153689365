import React from "react";
import Switch from "../component/switch/Switch";
import patchData from "../API/patchData";

const SwitchColumn = ({ apiEndpoint }) => {
  const handleSwitchChange = (newStatus, itemId) => {
    console.log(`Switch changed to: ${newStatus} for item ID: ${itemId}`);
    patchData(`${apiEndpoint}/${itemId}/`, {
      is_active: newStatus,
    }).then((res) => console.log(res));
  };

  return {
    title: "Status",
    key: "is_active",
    render: (data) => {
      // console.log(data.is_active);
      return (
        <Switch
          initialValue={data.is_active}
          onSwitchChange={(newStatus) => handleSwitchChange(newStatus, data.id)}
        />
      );
    },
  };
};

export default SwitchColumn;
