import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import Input from "../../../component/inputs/Input";
import Textarea from "../../../component/inputs/Textarea";
import DropSelect from "../../../component/dropdowns/DropSelect";
// import { isRead } from "../../../data/data";
import { GETData } from "../../../API/GETDataV2";
import { IoMdCloseCircleOutline } from "react-icons/io";
import putData from "../../../API/putData";

const EditSystemAlert = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [userData, setUserData] = useState([]);
  const [alertData, setAlertData] = useState({
    title: "",
    message: "",
    is_read: "",
    users: [],
  });
  const [selectedOptions, setSelectedOptions] = useState([]);

  const fetchUserData = () => {
    GETData("auth/user/").then((res) => {
      setUserData(res.data.data);
    });
  };

  useEffect(() => {
    fetchUserData();
    GETData(`notification/admin/system-alert/${id}`).then((res) => {
      setAlertData(res.data.data);
      setSelectedOptions(res.data.data.users);
    });
  }, [id]);

  const handleInputChange = (name, value) => {
    setAlertData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleOptionSelect = (selectedOption) => {
    if (!selectedOptions.includes(selectedOption)) {
      setSelectedOptions([...selectedOptions, parseInt(selectedOption, 10)]);
    }
  };

  const handleRemoveOption = (removedOption) => {
    const updatedOptions = selectedOptions.filter(
      (option) => option !== removedOption
    );
    setSelectedOptions(updatedOptions);
  };

  console.log("users", selectedOptions);
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await putData(`notification/admin/system-alert/${id}/`, {
        title: alertData.title,
        message: alertData.message,
        // is_read: alertData.is_read,
        users: selectedOptions,
      });
      navigate("/notifications/system-alert");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb
          directory1="Notifications"
          directory2="System Alert"
          directory3="Edit Alert"
          path="/notifications/system-alert"
        />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search mb-3">
            <h5>Edit Alert</h5>
          </div>
          <div>
            <form onSubmit={handleSubmit}>
              <Input
                type="text"
                placeholder="Title"
                value={alertData.title}
                onChange={(value) => handleInputChange("title", value)}
              />
              <Textarea
                placeholder="Message"
                value={alertData.message}
                onChange={(value) => handleInputChange("message", value)}
              />
              {/* <DropSelect
                label="Is Read"
                options={isRead}
                value={alertData.is_read}
                onChange={(value) => handleInputChange("is_read", value)}
              /> */}
              <DropSelect
                label="User"
                options={[
                  {
                    value: "",
                    title: "Select User",
                    disabled: true,
                  },
                  ...userData.map((item) => ({
                    value: item.id,
                    title: item.user_name,
                  })),
                ]}
                value={selectedOptions}
                onChange={handleOptionSelect}
              />
              <div className="mt-4 d-flex flex-wrap">
                {selectedOptions.map((selectedOption) => {
                  const selectedUser = userData.find(
                    (user) => user.id === selectedOption
                  );

                  return (
                    <div
                      key={selectedOption}
                      className="fade-bg p-2 position-relative me-3"
                    >
                      <p className="mb-0">
                        {selectedUser ? selectedUser.user_name : "Unknown"}
                      </p>
                      <button
                        className="position-absolute bg-transparent border-0 req-doc-close"
                        onClick={() => handleRemoveOption(selectedOption)}
                      >
                        <IoMdCloseCircleOutline className="fs-5" />
                      </button>
                    </div>
                  );
                })}
              </div>
              <button className="btn btn-dark w-100 my-2" type="submit">
                Update
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSystemAlert;
