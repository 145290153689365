import React, { useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import DropSelect from "../../../component/dropdowns/DropSelect";
import { faqTypeData, isActive } from "../../../data/data";
import Input from "../../../component/inputs/Input";
import postData from "../../../API/postData";
import { useNavigate } from "react-router-dom";

const AddFaq = () => {
  const [faqs, setFaqs] = useState({});

  const handleInputChange = (name, value) => {
    if (name === "position") {
      value = value ? parseInt(value, 10) : 0;
    }
    setFaqs((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    postData("utility/admin/faq/", faqs)
      .then((res) => {
        console.log("add faqs response", res);
        navigate("/utility/faq");
      })
      .catch((err) => {
        console.log("error adding faq", err);
      });
  };
  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb
          directory1="Utility"
          directory2="Faq"
          directory3="Add Faq"
          path="/utility/faq"
        />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search mb-3">
            <h5>Add Faq</h5>
          </div>
          <div>
            <form action="" onSubmit={handleSubmit}>
              <DropSelect
                label="Faq Type"
                options={faqTypeData}
                onChange={(value) => handleInputChange("faq_type", value)}
              />
              <Input
                type="text"
                placeholder="Question"
                onChange={(value) => handleInputChange("question", value)}
                required={true}
              />
              <Input
                type="text"
                placeholder="Answer"
                onChange={(value) => handleInputChange("answer", value)}
                required={true}
              />
              <Input
                type="text"
                placeholder="Position"
                onChange={(value) => handleInputChange("position", value)}
                required={true}
              />
              <DropSelect
                label="Status"
                options={isActive}
                onChange={(value) => handleInputChange("is_active", value)}
              />
              <button className="my-3 btn btn-dark w-100" type="submit">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddFaq;
