import React, { useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import Input from "../../../component/inputs/Input";
import DropSelect from "../../../component/dropdowns/DropSelect";
import { isActive, membershipAndPackageType } from "../../../data/data";
import postData from "../../../API/postData";
import { useNavigate } from "react-router-dom";

const AddPackage = () => {
  const [packages, setPackage] = useState({});

  const handleInputChange = (name, value) => {
    if (name === "package_type" || name === "duration") {
      value = value ? parseInt(value, 10) : 0;
    }
    setPackage((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    postData("package/admin/package/", packages).then((res) => {
      console.log("add package response", res);
      navigate("/packages/package-list");
    });
  };
  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb
          directory1="Packages"
          directory2="Package List"
          directory3="Add New"
          path="/packages/package-list"
        />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search mb-3">
            <h5>Add New Package</h5>
          </div>
          <div>
            <form action="" onSubmit={handleSubmit}>
              <Input
                type="text"
                placeholder="Package Name"
                required={true}
                onChange={(value) => handleInputChange("package_name", value)}
              />
              <Input
                type="text"
                placeholder="Price"
                required={true}
                onChange={(value) => handleInputChange("price", value)}
              />
              <DropSelect
                label="Package Type"
                options={membershipAndPackageType}
                onChange={(value) => handleInputChange("package_type", value)}
              />
              <Input
                type="text"
                placeholder="Duration (in days)"
                required={true}
                onChange={(value) => handleInputChange("duration", value)}
              />
              <Input
                type="text"
                placeholder="Details"
                required={true}
                onChange={(value) => handleInputChange("details", value)}
              />
              <DropSelect
                label="Status"
                options={isActive}
                onChange={(value) => handleInputChange("is_active", value)}
              />
              <button className="my-3 btn btn-dark w-100" type="submit">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPackage;
