import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import AddNew from "../../../component/buttons/AddNew";
import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit2 } from "react-icons/fi";
import Table from "../../../component/table/Table";
import { getData } from "../../../API/getData";
import { variantGroupsColumn } from "../../../data/tableColumns";
import useDeleteModal from "../../../utilities/DeleteModal";
import { useNavigate } from "react-router-dom";

const VariantGroup = () => {
  const [variantGroups, setVariantGroups] = useState([]);
  const fetchVariantGroups = () => {
    getData("inventory/admin/variant-group/")
      .then((res) => {
        setVariantGroups(res);
      })
      .catch((err) => {
        console.log("error getting variant groups", err);
      });
  };
  useEffect(() => {
    fetchVariantGroups();
  }, []);

  const { showDeleteModal, DeleteModal } = useDeleteModal(
    "inventory/admin/variant-group"
  );
  const navigate = useNavigate();

  const actionColumn = {
    title: "Actions",
    key: "actions",
    render: (data) => {
      return (
        <div className="d-flex align-items-center">
          <button
            className="primary-bg p-1 border-0 me-2"
            onClick={() =>
              navigate(`/inventory/variant-group/edit-variant/${data.id}`)
            }
          >
            <FiEdit2 className="text-white" />
          </button>

          <button
            className="bg-danger p-1 border-0 ms-2"
            onClick={() => showDeleteModal(data, fetchVariantGroups)}
          >
            <AiOutlineDelete className="text-white" />
          </button>
        </div>
      );
    },
  };
  const finalColumn = [...variantGroupsColumn, actionColumn];

  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb directory1="Inventory" directory2="Variant Group" />
        <AddNew path="/inventory/variant-group/add-variant" />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search mb-3">
            <h5>All Brands</h5>
          </div>
          <div className="table-area">
            <Table columns={finalColumn} dataSource={variantGroups} />
          </div>
        </div>
      </div>
      {/* Delete item */}
      <DeleteModal />
    </div>
  );
};

export default VariantGroup;
