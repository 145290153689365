import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import AddNew from "../../../component/buttons/AddNew";
import Table from "../../../component/table/Table";
import { getData } from "../../../API/getData";
import { categoryDataColumns } from "../../../data/tableColumns";
import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit2 } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import useDeleteModal from "../../../utilities/DeleteModal";

const Category = () => {
  const [tableData, setTableData] = useState([]);

  const fetchCategory = () => {
    getData("inventory/admin/category/").then((response) => {
      setTableData(response);
    });
  };

  const { showDeleteModal, DeleteModal } = useDeleteModal(
    "inventory/admin/category"
  );

  const navigate = useNavigate();
  const actionColumn = {
    title: "Actions",
    key: "actions",
    render: (data) => {
      return (
        <div className="d-flex align-items-center">
          <button
            className="primary-bg p-1 border-0 me-2"
            onClick={() =>
              navigate(`/inventory/categories/edit-category/${data.id}`)
            }
          >
            <FiEdit2 className="text-white" />
          </button>

          <button
            className="bg-danger p-1 border-0 ms-2"
            onClick={() => showDeleteModal(data, fetchCategory)}
          >
            <AiOutlineDelete className="text-white" />
          </button>
        </div>
      );
    },
  };

  const finalColumn = [...categoryDataColumns, actionColumn];

  useEffect(() => {
    fetchCategory();
  }, []);

  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb directory1="Inventory" directory2="Category" />
        <AddNew path="/inventory/categories/add-new" />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search mb-3">
            <h5>All Categories</h5>
          </div>
          <div className="table-area">
            <Table columns={finalColumn} dataSource={tableData} />
          </div>
        </div>
      </div>
      {/* delete modal */}
      <DeleteModal />
    </div>
  );
};

export default Category;
