import DashCardSection from "./dashboard-components/DashCardSection";

const Dashboard = () => {
  return (
    <div className="p-3">
      <DashCardSection />
    </div>
  );
};

export default Dashboard;
