import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import { getData } from "../../../API/getData";
import AddNew from "../../../component/buttons/AddNew";
import Table from "../../../component/table/Table";
import { useNavigate } from "react-router-dom";
import useDeleteModal from "../../../utilities/DeleteModal";
import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit2 } from "react-icons/fi";
import { riderColumns } from "../../../data/tableColumns";

const Rider = () => {
  const [riders, setRiders] = useState([]);
  const fetchRiders = () => {
    getData("delivery/admin/rider/").then((res) => {
      setRiders(res);
    });
  };
  useEffect(() => {
    fetchRiders();
  }, []);

  const navigate = useNavigate();
  const { showDeleteModal, DeleteModal } = useDeleteModal(
    "delivery/admin/rider"
  );

  const actionColumn = {
    title: "Actions",
    key: "actions",
    render: (data) => {
      return (
        <div className="d-flex align-items-center">
          <button
            className="primary-bg p-1 border-0 me-2"
            onClick={() => navigate(`/delivery/admin/rider/${data.id}`)}
          >
            <FiEdit2 className="text-white" />
          </button>

          <button
            className="bg-danger p-1 border-0 ms-2"
            onClick={() => showDeleteModal(data, fetchRiders)}
          >
            <AiOutlineDelete className="text-white" />
          </button>
        </div>
      );
    },
  };

  const finalColumn = [...riderColumns, actionColumn];

  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb directory1="Delivery" directory2="Rider" />
        <AddNew path="/delivery/rider/add-rider" />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search mb-3">
            <h5>All Riders</h5>
          </div>
          <div className="table-area">
            <Table columns={finalColumn} dataSource={riders} />
          </div>
        </div>
      </div>
      {/*  */}
      <DeleteModal />
    </div>
  );
};

export default Rider;
