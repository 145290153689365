import React, { useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import Input from "../../../component/inputs/Input";
import DropSelect from "../../../component/dropdowns/DropSelect";
import { getFileType, isActive } from "../../../data/data";
import InputFile from "../../../component/inputs/FileInput";
import { postDocumentData } from "../../../API/postDocumentData";
import postData from "../../../API/postData";
import { useNavigate } from "react-router-dom";

const AddCurrency = () => {
  const [currencies, setCurrencies] = useState({});
  const [fileInput, setFileInput] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [shouldSubmit, setShouldSubmit] = useState(false);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setFileInput(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (name, value) => {
    setCurrencies((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (fileInput) {
      const formData = new FormData();
      formData.append("document", fileInput);
      const docType = getFileType(fileInput.name);
      formData.append("doc_type", docType);

      try {
        const docUpload = await postDocumentData(
          "auth/documents/upload/",
          formData
        );

        setCurrencies((prev) => ({
          ...prev,
          currency_sign: docUpload.data.id,
        }));
        setShouldSubmit(true);
      } catch (error) {
        console.log(error);
      }
    } else {
      alert("select document");
    }
  };
  const navigate = useNavigate();
  if (shouldSubmit) {
    postData("utility/admin/currency/", currencies)
      .then((res) => {
        console.log("add currency response", res);
        navigate("/utility/currency");
        setShouldSubmit(false);
      })
      .catch((err) => {
        console.log("error adding currency", err);
      });
  }

  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb
          directory1="Utility"
          directory2="currency"
          directory3="Add New"
          path="/utility/currency"
        />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search mb-3">
            <h5>Add Currency</h5>
          </div>
          <div>
            <form action="" onSubmit={handleSubmit}>
              <Input
                type="text"
                placeholder="Currency Name"
                onChange={(value) => handleInputChange("currency_name", value)}
              />
              <Input
                type="text"
                placeholder="Currency Rate"
                onChange={(value) => handleInputChange("currency_rate", value)}
              />
              <DropSelect
                label="Status"
                options={isActive}
                onChange={(value) => handleInputChange("is_active", value)}
              />
              <div>
                <div className="profile-img-wrapper">
                  {selectedImage ? (
                    <img
                      className="mb-2 img-fluid"
                      src={selectedImage || ""}
                      alt="currency"
                    />
                  ) : (
                    ""
                  )}
                </div>
                <InputFile id="currency-img" imageChange={handleImageChange} />
              </div>
              <div className="my-3">
                <button className="btn btn-dark w-100" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCurrency;
