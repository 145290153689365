import React, { useEffect, useState } from "react";
import Breadcrumb from "../../component/breadcrumb/Breadcrumb";
import { getData } from "../../API/getData";
import Table from "../../component/table/Table";
import { blogColumns } from "../../data/tableColumns";
import { useNavigate } from "react-router-dom";
import useDeleteModal from "../../utilities/DeleteModal";
import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit2 } from "react-icons/fi";
import AddNew from "../../component/buttons/AddNew";

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);

  const fetchBlogs = () => {
    getData("blog/admin/post/").then((res) => {
      if (res && res.length > 0) {
        setBlogs(res);
      }
    });
  };
  useEffect(() => {
    fetchBlogs();
  }, []);

  const navigate = useNavigate();
  const { showDeleteModal, DeleteModal } = useDeleteModal("blog/admin/post");

  const actionColumn = {
    title: "Actions",
    key: "actions",
    render: (data) => {
      return (
        <div className="d-flex align-items-center">
          <button
            className="primary-bg p-1 border-0 me-2"
            onClick={() => navigate(`/blogs/blog-list/edit-blog/${data.id}`)}
          >
            <FiEdit2 className="text-white" />
          </button>

          <button
            className="bg-danger p-1 border-0 ms-2"
            onClick={() => showDeleteModal(data, fetchBlogs)}
          >
            <AiOutlineDelete className="text-white" />
          </button>
        </div>
      );
    },
  };

  const finalColumn = [...blogColumns, actionColumn];

  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb directory1="Blogs" directory2="Blog List" />
        <AddNew path="/blogs/blog-list/add-blog" />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search mb-3">
            <h5>Blog List</h5>
          </div>
          <div className="table-area">
            <Table columns={finalColumn} dataSource={blogs} />
          </div>
        </div>
      </div>
      {/*  */}
      <DeleteModal />
    </div>
  );
};

export default Blogs;
