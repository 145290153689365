import React, { useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import Input from "../../../component/inputs/Input";
import DropSelect from "../../../component/dropdowns/DropSelect";
import { getFileType, isActive, pageTypeData } from "../../../data/data";
import InputFile from "../../../component/inputs/FileInput";
import { postDocumentData } from "../../../API/postDocumentData";
import { useNavigate } from "react-router-dom";
import postData from "../../../API/postData";

const AddPage = () => {
  const [page, setPage] = useState({});
  const [fileInput, setFileInput] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [fileInput2, setFileInput2] = useState();
  const [selectedImage2, setSelectedImage2] = useState(null);
  const [shouldSubmit, setShouldSubmit] = useState(false);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setFileInput(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleImageChange2 = (event) => {
    const file = event.target.files[0];
    setFileInput2(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage2(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (name, value) => {
    setPage((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (fileInput) {
      const formData = new FormData();
      formData.append("document", fileInput);
      const docType = getFileType(fileInput.name);
      formData.append("doc_type", docType);

      try {
        const docUpload = await postDocumentData(
          "auth/documents/upload/",
          formData
        );

        setPage((prev) => ({
          ...prev,
          thumbnail: docUpload.data.id,
        }));

        const formData2 = new FormData();
        formData2.append("document", fileInput2);
        const docType2 = getFileType(fileInput2.name);
        formData2.append("doc_type", docType2);

        try {
          const docUpload2 = await postDocumentData(
            "auth/documents/upload/",
            formData2
          );

          setPage((prev) => ({
            ...prev,
            attachment: docUpload2.data.id,
          }));
          setShouldSubmit(true);
        } catch (error) {
          console.log(error);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      alert("select document");
    }
  };

  const navigate = useNavigate();
  if (shouldSubmit) {
    postData("utility/admin/page/", page)
      .then((res) => {
        console.log("add page response", res);
        navigate("/utility/page");
        setShouldSubmit(false);
      })
      .catch((err) => {
        console.log("error adding page data", err);
      });
  }
  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb
          directory1="Utility"
          directory2="Page"
          directory3="Add Page"
          path="/utility/page"
        />
      </div>
      <div className="p-3">
        <div className="p-3 bg-white">
          <div className="title-and-search mb-3">
            <h5>Add Page</h5>
          </div>
          <div>
            <form action="" onSubmit={handleSubmit}>
              <Input
                type="text"
                placeholder="Title"
                onChange={(value) => handleInputChange("title", value)}
              />
              <Input
                type="text"
                placeholder="Description"
                onChange={(value) => handleInputChange("desc", value)}
              />
              <Input
                type="text"
                placeholder="Video URL"
                onChange={(value) => handleInputChange("video_url", value)}
              />
              <DropSelect
                label="Page Type"
                options={pageTypeData}
                onChange={(value) => handleInputChange("page_type", value)}
              />
              <DropSelect
                label="Status"
                options={isActive}
                onChange={(value) => handleInputChange("is-active", value)}
              />
              <div>
                <label htmlFor="" className="fw-bold mb-2">
                  Page Thumbnail
                </label>
                <div className="profile-img-wrapper">
                  {selectedImage ? (
                    <img
                      className="mb-2 img-fluid"
                      src={selectedImage || ""}
                      alt="page-thumb"
                    />
                  ) : (
                    ""
                  )}
                </div>
                <InputFile
                  id="page-thumbnail"
                  imageChange={handleImageChange}
                />
              </div>
              <div>
                <label htmlFor="" className="fw-bold mb-2">
                  Attachment
                </label>
                <div className="profile-img-wrapper">
                  {selectedImage ? (
                    <img
                      className="mb-2 img-fluid"
                      src={selectedImage2 || ""}
                      alt="page-attach"
                    />
                  ) : (
                    ""
                  )}
                </div>
                <InputFile
                  id="page-attachment"
                  imageChange={handleImageChange2}
                />
              </div>
              <button className="my-3 btn btn-dark w-100" type="submit">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPage;
