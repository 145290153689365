import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import AddNew from "../../../component/buttons/AddNew";
import { getData } from "../../../API/getData";
import Table from "../../../component/table/Table";
import { pageColumns } from "../../../data/tableColumns";
import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit2 } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import useDeleteModal from "../../../utilities/DeleteModal";

const Page = () => {
  const [pages, setPages] = useState([]);

  const fetchPages = () => {
    getData("utility/admin/page/")
      .then((res) => {
        setPages(res);
      })
      .catch((err) => {
        console.log("error getting pages", err);
      });
  };

  useEffect(() => {
    fetchPages();
  }, []);

  const navigate = useNavigate();
  const { showDeleteModal, DeleteModal } = useDeleteModal("utility/admin/page");

  const actionColumn = {
    title: "Actions",
    key: "actions",
    render: (data) => {
      return (
        <div className="d-flex align-items-center">
          <button
            className="primary-bg p-1 border-0 me-2"
            onClick={() => navigate(`/utility/page/edit-page/${data.id}`)}
          >
            <FiEdit2 className="text-white" />
          </button>

          <button
            className="bg-danger p-1 border-0 ms-2"
            onClick={() => showDeleteModal(data, fetchPages)}
          >
            <AiOutlineDelete className="text-white" />
          </button>
        </div>
      );
    },
  };

  const finalColumn = [...pageColumns, actionColumn];

  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb directory1="Utility" directory2="Page" />
        <AddNew path="/utility/page/add-page" />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search mb-3">
            <h5>Pages</h5>
          </div>
          <div className="table-area">
            <Table columns={finalColumn} dataSource={pages} />
          </div>
        </div>
      </div>
      {/*  */}
      <DeleteModal />
    </div>
  );
};

export default Page;
