import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import Input from "../../../component/inputs/Input";
import DropSelect from "../../../component/dropdowns/DropSelect";
import { couponType, discountType, isActive } from "../../../data/data";
import { GETData } from "../../../API/GETDataV2";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import putData from "../../../API/putData";

const EditCoupon = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [couponData, setCouponData] = useState({
    coupon_title: "",
    coupon_type: "",
    discount_type: "",
    start_date: "",
    expire_date: "",
    discount_amount: "",
    minimum_purchase: "",
    maximum_discount: "",
    max_usage: "",
    usage_count: "",
    is_active: "",
    customers: [],
  });

  const [userData, setUserData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    // Fetch coupon data
    GETData(`sales/admin/coupon/${id}/`)
      .then((res) => {
        setCouponData(res.data.data);
        setSelectedOptions(res.data.data.customers);
      })
      .catch((err) => {
        console.log("Error getting coupon", err);
      });

    // Fetch user data
    GETData("auth/user/")
      .then((res) => {
        setUserData(res.data.data);
      })
      .catch((err) => {
        console.log("Error getting user data", err);
      });
  }, [id]);

  const handleOptionSelect = (selectedOption) => {
    if (!selectedOptions.includes(selectedOption)) {
      setSelectedOptions([...selectedOptions, parseInt(selectedOption, 10)]);
    }
  };

  const handleRemoveOption = (removedOption) => {
    const updatedOptions = selectedOptions.filter(
      (option) => option !== removedOption
    );
    setSelectedOptions(updatedOptions);
  };

  const handleInputChange = (name, value) => {
    if (
      name === "coupon_type" ||
      name === "discount_type" ||
      name === "maximum_discount" ||
      name === "max_usage" ||
      name === "usage_count"
    ) {
      value = value ? parseInt(value, 10) : 0;
    }
    setCouponData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedCouponData = {
      ...couponData,
      customers: selectedOptions,
    };

    putData(`sales/admin/coupon/${id}/`, updatedCouponData)
      .then((res) => {
        console.log("Edit coupon response", res);
        navigate("/sales/coupon");
      })
      .catch((err) => {
        console.log("Error editing coupon", err);
      });
  };

  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb directory1="Sales" directory2="Coupon" />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search mb-3">
            <h5>Edit Coupon</h5>
          </div>
          <form onSubmit={handleSubmit}>
            <Input
              type="text"
              placeholder="Coupon title"
              value={couponData.coupon_title}
              onChange={(value) => handleInputChange("coupon_title", value)}
            />
            <DropSelect
              label="Coupon Type"
              options={couponType}
              value={couponData.coupon_type}
              onChange={(value) => handleInputChange("coupon_type", value)}
            />
            <DropSelect
              label="Discount type"
              options={discountType}
              value={couponData.discount_type}
              onChange={(value) => handleInputChange("discount_type", value)}
            />
            <Input
              type="date"
              placeholder="Start Date"
              value={couponData.start_date.split("T")[0]}
              onChange={(value) => handleInputChange("start_date", value)}
            />
            <Input
              type="date"
              placeholder="Expiry Date"
              value={couponData.expire_date.split("T")[0]}
              onChange={(value) => handleInputChange("expire_date", value)}
            />
            <Input
              type="text"
              placeholder="Discount Amount"
              value={couponData.discount_amount}
              onChange={(value) => handleInputChange("discount_amount", value)}
            />
            <Input
              type="text"
              placeholder="Minimum Purchase"
              value={couponData.minimum_purchase}
              onChange={(value) => handleInputChange("minimum_purchase", value)}
            />
            <Input
              type="text"
              placeholder="Maximum Discount"
              value={couponData.maximum_discount}
              onChange={(value) => handleInputChange("maximum_discount", value)}
            />
            <Input
              type="text"
              placeholder="Maximum Usage"
              value={couponData.max_usage}
              onChange={(value) => handleInputChange("max_usage", value)}
            />
            <Input
              type="text"
              placeholder="Usage Count"
              value={couponData.usage_count}
              onChange={(value) => handleInputChange("usage_count", value)}
            />
            <DropSelect
              label="Status"
              options={isActive}
              value={couponData.is_active}
              onChange={(value) => handleInputChange("is_active", value)}
            />
            <DropSelect
              label="Customers"
              options={[
                {
                  value: "",
                  title: "Select customer",
                  disabled: true,
                },
                ...userData.map((item) => ({
                  value: item.id,
                  title: item.user_name,
                })),
              ]}
              //   value={selectedOptions}
              onChange={handleOptionSelect}
            />
            <div className="mt-4 d-flex flex-wrap">
              {selectedOptions.map((selectedOption) => {
                const selectedUser = userData.find(
                  (user) => user.id === selectedOption
                );

                return (
                  <div
                    key={selectedOption}
                    className="fade-bg p-2 position-relative me-3"
                  >
                    <p className="mb-0">
                      {selectedUser ? selectedUser.user_name : "Unknown"}
                    </p>
                    <button
                      className="position-absolute bg-transparent border-0 req-doc-close"
                      onClick={() => handleRemoveOption(selectedOption)}
                    >
                      <IoMdCloseCircleOutline className="fs-5" />
                    </button>
                  </div>
                );
              })}
            </div>
            <button className="btn btn-dark w-100 my-3" type="submit">
              Update
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditCoupon;
