import React from "react";

const FormattedDate = ({ timestamp }) => {
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  const formattedDate = new Date(timestamp).toLocaleString("en-US", options);

  return <span>{formattedDate}</span>;
};

export default FormattedDate;
