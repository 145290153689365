import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import InputFile from "../../../component/inputs/FileInput";
import DropSelect from "../../../component/dropdowns/DropSelect";
import Input from "../../../component/inputs/Input";
import { getData } from "../../../API/getData";
import { useNavigate, useParams } from "react-router-dom";
import { GETData } from "../../../API/GETDataV2";
import { getFileType, isActive } from "../../../data/data";
import { postDocumentData } from "../../../API/postDocumentData";
import putData from "../../../API/putData";

const EditCategory = () => {
  const { id } = useParams();
  const [dataSet, setData] = useState({});

  const [categoryData, setCategoryData] = useState([]);
  const [fileInput, setFileInput] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [shouldSubmit, setShouldSubmit] = useState(false);

  const fetchCategory = () => {
    getData("inventory/admin/category/").then((response) => {
      setCategoryData(response);
    });
  };

  useEffect(() => {
    fetchCategory();

    GETData(`inventory/admin/category/${id}/`).then((res) => {
      setData(res.data.data);
      setSelectedImage(res.data.data.image_url);
    });
  }, [id]);

  const handleInputChange = (name, value) => {
    if (name === "parent" || name === "image") {
      value = value ? parseInt(value, 10) : 0;
    }
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setFileInput(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (fileInput) {
      const formData = new FormData();
      formData.append("document", fileInput);
      const docType = getFileType(fileInput.name);
      formData.append("doc_type", docType);

      try {
        const docUpload = await postDocumentData(
          "auth/documents/upload/",
          formData
        );
        setData((prev) => ({
          ...prev,
          image: docUpload.data.id,
        }));
        setShouldSubmit(true);
      } catch (error) {
        console.log(error);
      }
    } else {
      setShouldSubmit(true);
    }
  };
  const navigate = useNavigate();

  if (shouldSubmit) {
    putData(`inventory/admin/category/${id}/`, {
      name: dataSet.name,
      is_active: dataSet.is_active,
      parent: dataSet.parent,
      image: dataSet.image,
    }).then((res) => {
      console.log(res);
      navigate("/inventory/categories");
    });
  }
  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb
          directory1="Inventory"
          directory2="Category"
          directory3="Edit Category"
          path="/inventory/categories"
        />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search">
            <h5>Edit Category</h5>
          </div>
          <div>
            <form action="" onSubmit={handleSubmit}>
              <Input
                type="text"
                placeholder="Name"
                value={dataSet.name || ""}
                onChange={(value) => handleInputChange("name", value)}
              />
              <DropSelect
                label="Is Active"
                options={isActive}
                value={dataSet.is_active}
                onChange={(value) => handleInputChange("is_active", value)}
              />
              <DropSelect
                label="Parent Category"
                value={dataSet.parent || ""}
                options={[
                  {
                    value: "",
                    title: "Select a Parent Category",
                    disabled: true,
                  },
                  ...categoryData.map((item) => ({
                    value: item.id,
                    title: item.name,
                  })),
                ]}
                onChange={(value) => handleInputChange("parent", value)}
              />
              <div>
                <div className="profile-img-wrapper">
                  {selectedImage ? (
                    <img
                      className="mb-2 img-fluid"
                      src={selectedImage || ""}
                      alt="brand"
                    />
                  ) : (
                    ""
                  )}
                </div>
                <InputFile
                  id="category-image-edit"
                  imageChange={handleImageChange}
                />
              </div>
              <button className="btn btn-dark w-100 my-3" type="submit">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCategory;
