import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { PiSquaresFourFill } from "react-icons/pi";
import { BiSolidBellRing } from "react-icons/bi";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import Logo from "../../assets/Logo.png";
import { FaOpencart } from "react-icons/fa6";
import { FcStatistics, FcSupport } from "react-icons/fc";
import { LuPackageSearch } from "react-icons/lu";
import { BsTools } from "react-icons/bs";
import { GrBlog } from "react-icons/gr";
import { TbTruckDelivery } from "react-icons/tb";
import { FaRocketchat } from "react-icons/fa";

const Sidebar = ({ toggle, toggleSidebar }) => {
  const location = useLocation();

  const [menuItems] = useState([
    {
      id: 1,
      title: "Dashboard",
      icon: PiSquaresFourFill,
      path: "/",
      subMenus: [],
    },
    {
      id: 2,
      title: "Notifications",
      icon: BiSolidBellRing,
      subMenus: [
        {
          label: "Personal Notifications",
          path: "/notifications/personal-notifications",
        },
        { label: "System Alert", path: "/notifications/system-alert" },
      ],
    },
    {
      id: 3,
      title: "Inventory",
      icon: FaOpencart,
      subMenus: [
        { label: "Brands", path: "/inventory/brands" },
        { label: "Categories", path: "/inventory/categories" },
        { label: "Products", path: "/inventory/products" },
        { label: "Product Reviews", path: "/inventory/product-review" },
        { label: "Promotions", path: "/inventory/promotions" },
        { label: "Variant Group", path: "/inventory/variant-group" },
        { label: "Variant Option", path: "/inventory/variant-option" },
      ],
    },
    {
      id: 4,
      title: "Sales",
      icon: FcStatistics,
      subMenus: [
        { label: "Coupon", path: "/sales/coupon" },
        { label: "Orders", path: "/sales/orders" },
        { label: "Cancel Reasons", path: "/sales/cancel-reasons" },
      ],
    },
    {
      id: 5,
      title: "Delivery",
      icon: TbTruckDelivery,
      subMenus: [
        { label: "Delivery Charge", path: "/delivery/delivery-charge" },
        { label: "Rider", path: "/delivery/rider" },
      ],
    },
    {
      id: 6,
      title: "Support",
      icon: FcSupport,
      subMenus: [
        { label: "Ticket", path: "/support/tickets" },
        { label: "Reply", path: "/support/reply" },
      ],
    },
    {
      id: 7,
      title: "Packages",
      icon: LuPackageSearch,
      subMenus: [
        { label: "Package list", path: "/packages/package-list" },
        { label: "Subscriptions", path: "/packages/subscriptions" },
      ],
    },
    {
      id: 8,
      title: "Utility",
      icon: BsTools,
      subMenus: [
        { label: "Banner", path: "/utility/banner" },
        { label: "Currency", path: "/utility/currency" },
        { label: "Email Subscription", path: "/utility/email-subscription" },
        { label: "FAQ", path: "/utility/faq" },
        { label: "Global Settings", path: "/utility/global-setting" },
        { label: "Page", path: "/utility/page" },
      ],
    },
    {
      id: 9,
      title: "Blogs",
      icon: GrBlog,
      subMenus: [
        { label: "Blog List", path: "/blogs/blog-list" },
        { label: "Likes", path: "/blogs/likes" },
        { label: "Comments", path: "/blogs/comments" },
      ],
    },
    {
      id: 10,
      title: "Chats",
      icon: FaRocketchat,
      path: "/chats",
      subMenus: [],
    },
  ]);

  const [activeIndex, setActiveIndex] = useState(null);
  const [activeSubmenuIndex, setActiveSubmenuIndex] = useState(null);

  useEffect(() => {
    const activeMenuId = localStorage.getItem("activeMenuId");
    const activeSubmenuId = localStorage.getItem("activeSubmenuId");

    setActiveIndex(activeMenuId ? parseInt(activeMenuId) : 1);
    setActiveSubmenuIndex(activeSubmenuId ? parseInt(activeSubmenuId) : null);
  }, []);

  const toggleSubMenu = (id) => {
    setActiveIndex(activeIndex === id ? null : id);
    setActiveSubmenuIndex(null);

    localStorage.setItem("activeMenuId", activeIndex === id ? null : id);
    localStorage.removeItem("activeSubmenuId");
  };

  const toggleSubmenuItem = (submenuIndex) => {
    setActiveSubmenuIndex(
      activeSubmenuIndex === submenuIndex ? null : submenuIndex
    );

    localStorage.setItem(
      "activeSubmenuId",
      activeSubmenuIndex === submenuIndex ? null : submenuIndex
    );
  };

  const isSubmenuActive = (submenuPath) => {
    return location.pathname.startsWith(submenuPath);
  };

  return (
    <nav
      className={`sidebar border-end primary-bg ${
        toggle ? "sidebar-hide" : "sidebar-show"
      }`}
    >
      <div className="logo-wrapper p-2 border border-end-0 shadow-sm bg-white">
        <img className="" src={Logo} alt="Techtsy" />
      </div>
      <div className="menu-items-wrapper">
        <ul className="menu-ul">
          {menuItems.map((menuItem) => (
            <li key={menuItem.id}>
              <Link
                to={menuItem.path}
                className={`menu-item px-4 py-3 ${
                  activeIndex === menuItem.id ? "menu-active" : ""
                }`}
                onClick={() => {
                  toggleSubMenu(menuItem.id);
                  if (menuItem.subMenus.length === 0) {
                    toggleSidebar();
                  }
                }}
              >
                {React.createElement(menuItem.icon)}
                <div className="menu-title">{menuItem.title}</div>
                {menuItem.subMenus.length > 0 && (
                  <>
                    {activeIndex === menuItem.id ? (
                      <MdKeyboardArrowUp className="submenu-icon" />
                    ) : (
                      <MdKeyboardArrowDown className="submenu-icon" />
                    )}
                  </>
                )}
              </Link>
              {menuItem.subMenus.length > 0 && (
                <ul
                  className={`submenu-ul pb-3 ${
                    activeIndex === menuItem.id ? "visible" : ""
                  }`}
                >
                  {menuItem.subMenus.map((submenu, submenuIndex) => (
                    <li
                      className="submenu-li text-dark py-2"
                      key={submenuIndex}
                    >
                      <Link
                        to={`${submenu.path}`}
                        className={`submenu-item position-relative text-decoration-none ${
                          isSubmenuActive(submenu.path) ? "submenu-active" : ""
                        }`}
                        onClick={() => {
                          toggleSubmenuItem(submenuIndex);
                          toggleSidebar();
                        }}
                      >
                        {submenu.label}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
      <div className="copyright-sidebar">
        <p className="text-sm text-fade mb-0 text-center">
          Copyright &#169; {new Date().getFullYear()}
        </p>
        <p className="text-center text-fade mb-0 text-sm text-bold">
          Techtsy Shoppy
        </p>
      </div>
    </nav>
  );
};

export default Sidebar;
