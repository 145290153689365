import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import { useParams } from "react-router-dom";
import { GETData } from "../../../API/GETDataV2";
import FormattedDate from "../../../utilities/FormattedDate";
import { AiOutlineDelete } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";
import { orderStatusData } from "../../../data/data";
import DropSelect from "../../../component/dropdowns/DropSelect";
import postData from "../../../API/postData";

const OrderDetail = () => {
  const { id } = useParams();
  const [orderData, setOrderData] = useState({});
  const [userData, setUserData] = useState([]);
  const [rider, setRider] = useState();
  useEffect(() => {
    GETData("auth/user/").then((res) => {
      setUserData(res.data.data);
    });
  }, []);
  useEffect(() => {
    GETData(`sales/admin/order/${id}/`).then((res) => {
      setOrderData(res.data.data);
    });
  }, [id]);
  // console.log(orderData);

  // const orderStatus = orderStatusData.find(
  //   (status) => status.value === orderData.order_status
  // );
  const statusChange = (value) => {
    setOrderData((prev) => ({
      ...prev,
      order_status: value,
    }));
  };

  const handleOrderProcessing = () => {
    postData(`sales/admin/order/${orderData.id}/process/`, {
      order: orderData.id,
      note: 0,
      order_status: orderData.order_status,
    }).then((res) => {
      console.log("changed order status", res);
    });
    if (rider !== undefined) {
      postData(`sales/admin/order/${orderData.id}/assign_rider/`, {
        rider_id: rider,
      }).then((res) => {
        console.log("rider assignment response", res);
      });
    }
  };

  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb directory1="Orders" directory2="Order Detail" />
      </div>

      {orderData ? (
        <div className="row">
          <div className="col-lg-9">
            <div className="p-3 pe-0">
              <div className="bg-white p-3 mb-3 d-flex justify-content-between">
                <span className="fw-500">{orderData.invoice_no}</span>
                <span>
                  <span className="mx-2">
                    <FormattedDate timestamp={orderData.created_at} />
                  </span>
                  |
                  <span className="mx-2">
                    {orderData.order_items ? orderData.order_items.length : 0}{" "}
                    items
                  </span>
                  |<span className="mx-2">Total $ {orderData.total}</span>|
                  <span className="mx-2 p-1 fade-bg rounded-3">
                    {orderData.payment_method === 2 ? "unpaid" : "paid"}
                  </span>
                </span>
              </div>
              <div className="bg-white p-3 mb-3">
                <div className="title-and-search d-flex align-items-center justify-content-between mb-2 border-bottom">
                  <h5>Product List</h5>
                  <div className="order-detail-status">
                    <DropSelect
                      options={orderStatusData}
                      value={orderData.order_status}
                      onChange={statusChange}
                    />
                  </div>
                </div>
                <div>
                  {orderData.order_items
                    ? orderData.order_items.map((item, index) => (
                        <div
                          key={index}
                          className="row align-items-center mb-2 pb-2 border-bottom"
                        >
                          <div className="col-md-2">
                            <img
                              className="order-img"
                              src={item.image_url}
                              alt={item.product_name}
                            />
                          </div>
                          <p className="mb-0 col-md-3">{item.product_name}</p>
                          <p className="mb-0 col-md-2">
                            <span className="text-muted">Unit cost</span>{" "}
                            {item.price}
                          </p>
                          <p className="mb-0 col-md-2">
                            <span className="text-muted">Quantity</span>{" "}
                            {item.quantity}
                          </p>
                          <p className="mb-0 col-md-2">
                            <span className="text-muted">Total</span>{" "}
                            {item.subtotal}
                          </p>
                          <div className="col-md-1">
                            <AiOutlineDelete />
                          </div>
                        </div>
                      ))
                    : ""}
                  <div className="row border-bottom pb-2">
                    <div className="col-md-9"></div>
                    <div className="col-md-3">
                      <p className="mb-0">
                        <span className="text-muted">Subtotal </span>
                        {orderData.subtotal}
                      </p>
                    </div>
                  </div>
                  <div className="row py-2">
                    <div className="col-md-6 border-end">
                      <p>Note from customer</p>
                      <p>{orderData.customer_note}</p>
                    </div>
                    <div className="col-md-6">
                      <p>Delivery Status</p>
                      <div className="d-flex justify-content-between align-items-center order-detail-status">
                        <p className="rounded-3 fade-bg p-2">
                          {orderData.delivery_status || "Pending"}
                        </p>
                        <span>
                          {orderData.order_status === 1 ? (
                            <DropSelect
                              options={[
                                {
                                  value: "",
                                  title: "Select a rider",
                                  disabled: true,
                                },
                                ...userData
                                  .filter((item) => item.role === 3)
                                  .map((data) => ({
                                    value: data.id,
                                    title: data.user_name,
                                  })),
                              ]}
                              onChange={(value) => setRider(parseInt(value))}
                            />
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white p-3">
                <div className="row">
                  <div className="col-md-6 border-end">
                    <p className="fw-bold">Price Information</p>
                    <div className="d-flex justify-content-between">
                      <p>Subtotal</p>
                      <p>{orderData.subtotal}</p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p>Shipping fee</p>
                      <p>{orderData.shipping_charge}</p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p>Vat</p>
                      <p>{orderData.vat}</p>
                    </div>
                    <div className="d-flex justify-content-between pt-2 border-top">
                      <p>Total</p>
                      <p>{orderData.total}</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex justify-content-between">
                      <p className="fw-bold">Payment Information</p>
                      <button className="bg-transparent border-0 mb-3">
                        <FaEdit />
                      </button>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p>Payment Type</p>
                      <p>
                        {orderData.payment_method === 0
                          ? "Paypal"
                          : orderData.payment_method === 1
                          ? "Billplz"
                          : "Cash on delivery"}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p>Coupon</p>
                      <p>{orderData.coupon ? orderData.coupon : 0}</p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p>Reward Point</p>
                      <p>
                        {orderData.reward_point ? orderData.reward_point : 0}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p>Payment Status</p>
                      <p>
                        <span className="p-1 fade-bg rounded-3">
                          {orderData.payment_method === 2 ? "unpaid" : "paid"}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="my-3">
                <button
                  className="btn btn-dark"
                  onClick={handleOrderProcessing}
                >
                  Save Changes
                </button>
                <button className="btn btn-dark ms-3">Print</button>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="p-3 ps-0">
              <div className="bg-white p-3 mb-3">
                <p className="fw-bold">Customer Information</p>
                <p className="mb-1 fw-bold">{orderData.customer_name}</p>
                <p className="mb-1">{orderData.customer_mobile}</p>
                <p className="mb-1">{orderData.customer_email}</p>
              </div>
              <div className="bg-white p-3 mb-3">
                <p className="fw-bold">Shipping Address</p>
                {orderData.shipping_address_detail ? (
                  <div>
                    <span>
                      Flat: {orderData.shipping_address_detail.flat},{" "}
                    </span>
                    <span>
                      Road: {orderData.shipping_address_detail.road},{" "}
                    </span>
                    <span>
                      {orderData.shipping_address_detail.address} -{" "}
                      {orderData.shipping_address_detail.zip_code},{" "}
                      {orderData.shipping_address_detail.country_name}
                    </span>
                  </div>
                ) : (
                  <p className="mb-1">No address found</p>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default OrderDetail;
