import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoutes } from "../ProtectedRoutes";
import PackageList from "../../view/packages/package-list/PackageList";
import EditPackage from "../../view/packages/package-list/EditPackage";
import AddPackage from "../../view/packages/package-list/AddPackage";
import Subscription from "../../view/packages/subscription/Subscription";
import AddSubscription from "../../view/packages/subscription/AddSubscription";
import EditSubscription from "../../view/packages/subscription/EditSubscription";

const PackagesRoutes = () => {
  return (
    <Routes>
      <Route
        path="/package-list"
        element={<ProtectedRoutes Component={PackageList} />}
      />
      <Route
        path="/package-list/edit-package/:id"
        element={<ProtectedRoutes Component={EditPackage} />}
      />
      <Route
        path="/package-list/add-new"
        element={<ProtectedRoutes Component={AddPackage} />}
      />
      {/*  */}
      <Route
        path="/subscriptions"
        element={<ProtectedRoutes Component={Subscription} />}
      />
      <Route
        path="/subscriptions/add-new"
        element={<ProtectedRoutes Component={AddSubscription} />}
      />
      <Route
        path="/subscriptions/edit-subscription/:id"
        element={<ProtectedRoutes Component={EditSubscription} />}
      />
    </Routes>
  );
};

export default PackagesRoutes;
