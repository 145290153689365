import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import { getData } from "../../../API/getData";
import { useNavigate } from "react-router-dom";
import useDeleteModal from "../../../utilities/DeleteModal";
import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit2 } from "react-icons/fi";
import AddNew from "../../../component/buttons/AddNew";
import { currencyColumns } from "../../../data/tableColumns";
import Table from "../../../component/table/Table";

const Currency = () => {
  const [currencies, setCurrencies] = useState([]);
  const fetchCurrencies = () => {
    getData("utility/admin/currency/").then((res) => {
      setCurrencies(res);
    });
  };
  useEffect(() => {
    fetchCurrencies();
  }, []);

  const navigate = useNavigate();
  const { showDeleteModal, DeleteModal } = useDeleteModal(
    "utility/admin/currency"
  );

  const actionColumn = {
    title: "Actions",
    key: "actions",
    render: (data) => {
      return (
        <div className="d-flex align-items-center">
          <button
            className="primary-bg p-1 border-0 me-2"
            onClick={() =>
              navigate(`/utility/currency/edit-currency/${data.id}`)
            }
          >
            <FiEdit2 className="text-white" />
          </button>

          <button
            className="bg-danger p-1 border-0 ms-2"
            onClick={() => showDeleteModal(data, fetchCurrencies)}
          >
            <AiOutlineDelete className="text-white" />
          </button>
        </div>
      );
    },
  };

  const finalColumn = [...currencyColumns, actionColumn];
  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb directory1="Utility" directory2="Currency" />
        <AddNew path="/utility/currency/add-new" />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search mb-3">
            <h5>All Currencies</h5>
          </div>
          <div className="table-area">
            <Table columns={finalColumn} dataSource={currencies} />
          </div>
        </div>
      </div>
      {/* delete item */}
      <DeleteModal />
    </div>
  );
};

export default Currency;
