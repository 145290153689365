import React, { useEffect, useState } from "react";
import Breadcrumb from "../../component/breadcrumb/Breadcrumb";
import { getData } from "../../API/getData";
import Table from "../../component/table/Table";
import { ticketColumns } from "../../data/tableColumns";
import { useNavigate } from "react-router-dom";

const Ticket = () => {
  const [tickets, setTickets] = useState([]);

  const fetchTickets = () => {
    getData("support/admin/ticket/").then((res) => {
      setTickets(res);
    });
  };
  useEffect(() => {
    fetchTickets();
  }, []);

  const navigate = useNavigate();

  const actionColumn = {
    title: "Action",
    key: "action",
    render: (data) => {
      return (
        <button
          className="border-0 bg-warning text-dark fw-bold text-sm p-1 px-2 rounded-3"
          onClick={() => navigate(`/support/ticket-reply/${data.id}`)}
        >
          Reply
        </button>
      );
    },
  };
  const finalColumn = [...ticketColumns, actionColumn];
  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb directory1="Support" directory2="Tickets" />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search mb-3">
            <h5>All Tickets</h5>
          </div>
          <div className="table-area">
            <Table columns={finalColumn} dataSource={tickets} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ticket;
