import React, { useEffect, useState } from "react";
import {
  MdGroups,
  MdOutlineDirectionsBike,
  MdOutlineUnsubscribe,
  MdOutlinePendingActions,
  MdOutlineProductionQuantityLimits,
  MdOutlineCategory,
  MdCancel,
} from "react-icons/md";
import { GoPackage } from "react-icons/go";
import { FaBoxOpen } from "react-icons/fa";
import { GrCompliance } from "react-icons/gr";
import { GETData } from "../../../API/GETDataV2";
import DashboardCard from "./DashboardCard";

const DashCardSection = () => {
  const [reports, setReports] = useState([]);
  const fetchReports = () => {
    GETData("reports/admin/info").then((res) => {
      setReports(res.data.data);
    });
  };
  useEffect(() => {
    fetchReports();
  }, []);

  return (
    <div className="dashboard-card-section">
      <div className="row">
        <DashboardCard
          icon={MdGroups}
          title="Total Users"
          value={reports.total_user}
        />

        <DashboardCard
          icon={MdOutlineDirectionsBike}
          title="Total Riders"
          value={reports.total_rider}
        />

        <DashboardCard
          icon={MdOutlineUnsubscribe}
          title="Total Subscribers"
          value={reports.total_active_subscriber}
        />

        <DashboardCard
          icon={FaBoxOpen}
          title="Total Orders"
          value={reports.total_order}
        />
        <DashboardCard
          icon={MdOutlinePendingActions}
          title="Pending Orders"
          value={reports.total_pending_order}
        />
        <DashboardCard
          icon={MdCancel}
          title="Cancelled Orders"
          value={reports.total_cancelled_order}
        />
        <DashboardCard
          icon={GrCompliance}
          title="Completed Orders"
          value={reports.total_completed_order}
        />
        <DashboardCard
          icon={MdOutlineProductionQuantityLimits}
          title="Total Products"
          value={reports.total_products}
        />
        <DashboardCard
          icon={MdOutlineCategory}
          title="Total Categories"
          value={reports.total_category}
        />
        <DashboardCard
          icon={GoPackage}
          title="Total Packages"
          value={reports.total_package}
        />
      </div>
    </div>
  );
};

export default DashCardSection;
