import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import { getData } from "../../../API/getData";
import SwitchColumn from "../../../utilities/SwitchColumn";
import Table from "../../../component/table/Table";

const EmailSubscription = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const fetchSubscriptions = () => {
    getData("utility/admin/email-subscription/").then((res) => {
      setSubscriptions(res);
    });
  };
  useEffect(() => {
    fetchSubscriptions();
  }, []);

  const subscriptionColumn = [
    { title: "ID", key: "id" },
    { title: "Email", key: "email" },
    SwitchColumn({ apiEndpoint: "utility/admin/email-subscription" }),
  ];
  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb directory1="Email Subscription" />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search">
            <h5>Email Subscriptions</h5>
          </div>
          <div className="table-area">
            <Table columns={subscriptionColumn} dataSource={subscriptions} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailSubscription;
