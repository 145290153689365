import React, { useState } from "react";

const Switch = ({ initialValue, onSwitchChange }) => {
  const [isChecked, setIsChecked] = useState(initialValue);

  const switchChange = () => {
    const newCheckedState = !isChecked;
    setIsChecked(newCheckedState);
    onSwitchChange(newCheckedState); // Notify the parent component
  };

  return (
    <div className="switch-wrapper form-check form-switch">
      <input
        className="form-check-input"
        type="checkbox"
        onChange={switchChange}
        checked={isChecked}
      />
      <label className={`switch-label activa ${isChecked ? "" : "d-none"}`}>
        Active
      </label>
      <label className={`switch-label deactiva ${isChecked ? "d-none" : ""}`}>
        Inactive
      </label>
    </div>
  );
};

export default Switch;
