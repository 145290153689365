import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import { getData } from "../../../API/getData";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { promotionsColumn } from "../../../data/tableColumns";
import Table from "../../../component/table/Table";
import { GETData } from "../../../API/GETDataV2";
import Modal from "../../../component/modal/Modal";

const Promotion = () => {
  const [promotionData, setPromotionData] = useState([]);
  const fetchPromotionData = () => {
    getData("inventory/admin/promotion/")
      .then((promotions) => {
        setPromotionData(promotions);
      })
      .catch((err) => {
        console.log("error getting promotions", err);
      });
  };
  useEffect(() => {
    fetchPromotionData();
  }, []);

  const actionColumn = {
    title: "Actions",
    key: "actions",
    render: (data) => {
      return (
        <div className="d-flex align-items-center">
          <button
            className="bg-light border p-1 ms-2"
            onClick={() => popUpPromotions(data.id)}
          >
            <MdOutlineRemoveRedEye className="text-dark" />
          </button>
        </div>
      );
    },
  };
  const finalColumn = [...promotionsColumn, actionColumn];

  const [promotionModal, setPromotionModal] = useState(false);
  const [promotionInfo, setpromotionInfo] = useState({});
  const popUpPromotions = (id) => {
    GETData(`inventory/admin/promotion/${id}/`)
      .then((res) => {
        setpromotionInfo(res.data.data);
      })
      .then(() => {
        setPromotionModal(true);
      })
      .catch((err) => {
        console.log("error getting single promotion", err);
      });
  };
  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb directory1="Inventory" directory2="Promotions" />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search mb-3">
            <h5>Promotions</h5>
          </div>
          <div className="table-area">
            <Table columns={finalColumn} dataSource={promotionData} />
          </div>
        </div>
      </div>
      {/* promotions popup */}
      <div className="review-popup">
        <Modal
          title="Preview Detail"
          visible={promotionModal}
          onCancel={() => {
            setPromotionModal(false);
            setpromotionInfo({});
          }}
          onOk={() => {
            setPromotionModal(false);
            setpromotionInfo({});
          }}
          content={
            <div>
              <div className="row">
                <div className="col-6">
                  <p>Id</p>
                  <p>Product Code</p>
                  <p>Name</p>
                  <p>Price</p>
                  <p>Promotional Price</p>
                  <p>Promotion Start</p>
                  <p>Promotion End</p>
                  <p>Promotion Status</p>
                </div>
                <div className="col-6">
                  <p>{promotionInfo.id}</p>
                  <p>{promotionInfo.product_code}</p>
                  <p>{promotionInfo.name}</p>
                  <p>{promotionInfo.price}</p>
                  <p>{promotionInfo.promotional_price}</p>
                  <p>{promotionInfo.promotions_start_date}</p>
                  <p>{promotionInfo.promotions_expiry_date}</p>
                  <p>{promotionInfo.promotion_status}</p>
                </div>
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default Promotion;
