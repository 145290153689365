import React, { useState } from "react";
import AuthContainer from "../AuthContainer";
import logo from "../../../assets/Logo.png";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../../../API/AxiosInstance";

const AccountVerify = ({ phoneNumber }) => {
  const [otp, setOtp] = useState(new Array(6).fill(""));

  const handleChange = (element, index, e) => {
    if (e.key === "Backspace") {
      setOtp([...otp.map((d, idx) => (idx === index ? "" : d))]);

      if (index > 0) {
        element.previousSibling.focus();
      }
    } else if (!isNaN(e.key)) {
      setOtp([...otp.map((d, idx) => (idx === index ? e.key : d))]);

      if (element.nextSibling) {
        element.nextSibling.focus();
      }
    }
  };
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axiosInstance.post("auth/account/verify/", {
        mobile: phoneNumber,
        code: otp.join(""),
      });
      console.log(response);
      navigate("/login");
    } catch (error) {
      setErrorMsg(
        error.response?.data?.errors?.mobile?.[0] ||
          error.response?.data?.errors?.code?.[0] ||
          error.response?.data?.message
      );
    } finally {
      setLoading(false);
    }
  };
  return (
    <AuthContainer
      titleLG="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Autem
                ex perferendis"
      titleSM="Lorem ipsum dolor sit amet consectetur adipisicing elit. Est,
                possimus?"
    >
      <div className="p-4">
        <div className="w-100 d-flex justify-content-center">
          <img className="img-fluid mt-3" src={logo} alt="techtsy" />
        </div>
        <h4 className="my-4">Account Verification</h4>
        <p className="my-4 px-1 py-2 fade-bg text-center">
          A 6 digit OTP code has been sent to {phoneNumber}
        </p>
        {loading ? <p className="text-center my-2">Loading</p> : ""}
        {errorMsg ? (
          <p className="fade-bg px-1 py-2 text-center">{errorMsg}</p>
        ) : (
          ""
        )}
        <form onSubmit={handleSubmit}>
          <div className="d-flex align-items-center justify-content-between mb-3">
            {otp.map((data, index) => {
              return (
                <input
                  key={index}
                  type="text"
                  className="otp-input text-center form-control"
                  maxLength="1"
                  value={data}
                  readOnly
                  onKeyDown={(e) => handleChange(e.target, index, e)}
                  onFocus={(e) => e.target.select()}
                />
              );
            })}
          </div>
          <div className="my-4">
            <button type="submit" className="btn btn-dark w-100">
              Submit
            </button>
          </div>
        </form>
        <div className="d-flex align-items-center justify-content-center">
          <Link>Resend Code</Link>
        </div>
      </div>
    </AuthContainer>
  );
};

export default AccountVerify;
