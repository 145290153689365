import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import AddNew from "../../../component/buttons/AddNew";
import { getData } from "../../../API/getData";
import Table from "../../../component/table/Table";
import { cancelReasonColumns } from "../../../data/tableColumns";
import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit2 } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import useDeleteModal from "../../../utilities/DeleteModal";

const Reason = () => {
  const [reasons, setReasons] = useState([]);

  const fetchReasons = () => {
    getData("sales/admin/reason/")
      .then((res) => {
        setReasons(res);
      })
      .catch((err) => {
        console.log("error getting reasons", err);
      });
  };

  useEffect(() => {
    fetchReasons();
  }, []);

  const navigate = useNavigate();
  const { showDeleteModal, DeleteModal } = useDeleteModal("sales/admin/reason");

  const actionColumn = {
    title: "Actions",
    key: "actions",
    render: (data) => {
      return (
        <div className="d-flex align-items-center">
          <button
            className="primary-bg p-1 border-0 me-2"
            onClick={() =>
              navigate(`/sales/cancel-reasons/edit-reason/${data.id}`)
            }
          >
            <FiEdit2 className="text-white" />
          </button>

          <button
            className="bg-danger p-1 border-0 ms-2"
            onClick={() => showDeleteModal(data, fetchReasons)}
          >
            <AiOutlineDelete className="text-white" />
          </button>
        </div>
      );
    },
  };

  const finalColumn = [...cancelReasonColumns, actionColumn];
  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb directory1="Sales" directory2="Cancel Reasons" />
        <AddNew path="/sales/cancel-reasons/add-reason" />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search mb-3">
            <h5>All Reasons</h5>
          </div>
          <div className="table-area">
            <Table columns={finalColumn} dataSource={reasons} />
          </div>
        </div>
      </div>
      {/* delete item */}
      <DeleteModal />
    </div>
  );
};

export default Reason;
