import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import Input from "../../../component/inputs/Input";
import DropSelect from "../../../component/dropdowns/DropSelect";
import { getFileType, isActive } from "../../../data/data";
import InputFile from "../../../component/inputs/FileInput";
import { postDocumentData } from "../../../API/postDocumentData";
import { useNavigate, useParams } from "react-router-dom";
import { GETData } from "../../../API/GETDataV2";
import putData from "../../../API/putData";

const EditCurrency = () => {
  const { id } = useParams();
  const [currencies, setCurrencies] = useState({});
  const [fileInput, setFileInput] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [shouldSubmit, setShouldSubmit] = useState(false);

  useEffect(() => {
    GETData(`utility/admin/currency/${id}/`).then((res) => {
      setCurrencies(res.data.data);
      setSelectedImage(res.data.data.currency_sign_url);
    });
  }, [id]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setFileInput(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (name, value) => {
    setCurrencies((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (fileInput) {
      const formData = new FormData();
      formData.append("document", fileInput);
      const docType = getFileType(fileInput.name);
      formData.append("doc_type", docType);

      try {
        const docUpload = await postDocumentData(
          "auth/documents/upload/",
          formData
        );

        setCurrencies((prev) => ({
          ...prev,
          currency_sign: docUpload.data.id,
        }));
        setShouldSubmit(true);
      } catch (error) {
        console.log(error);
      }
    } else {
      setShouldSubmit(true);
    }
  };
  const navigate = useNavigate();
  if (shouldSubmit) {
    putData(`utility/admin/currency/${id}/`, {
      currency_name: currencies.currency_name,
      currency_rate: currencies.currency_rate,
      is_active: currencies.is_active,
      currency_sign: currencies.currency_sign,
    })
      .then((res) => {
        console.log("edit currency response", res);
        navigate("/utility/currency");
        setShouldSubmit(false);
      })
      .catch((err) => {
        console.log("error adding currency", err);
      });
  }

  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb
          directory1="Utility"
          directory2="currency"
          directory3="Edit Currency"
          path="/utility/currency"
        />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search mb-3">
            <h5>Edit Currency</h5>
          </div>
          <div>
            <form action="" onSubmit={handleSubmit}>
              <Input
                type="text"
                placeholder="Currency Name"
                value={currencies.currency_name || ""}
                onChange={(value) => handleInputChange("currency_name", value)}
              />
              <Input
                type="text"
                placeholder="Currency Rate"
                value={currencies.currency_rate || ""}
                onChange={(value) => handleInputChange("currency_rate", value)}
              />
              <DropSelect
                label="Status"
                options={isActive}
                value={currencies.is_active}
                onChange={(value) => handleInputChange("is_active", value)}
              />
              <div>
                <div className="profile-img-wrapper">
                  {selectedImage ? (
                    <img
                      className="mb-2 img-fluid"
                      src={selectedImage || ""}
                      alt="currency"
                    />
                  ) : (
                    ""
                  )}
                </div>
                <InputFile id="currency-img" imageChange={handleImageChange} />
              </div>
              <div className="my-3">
                <button className="btn btn-dark w-100" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCurrency;
