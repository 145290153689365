import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import Table from "../../../component/table/Table";
import { subscriptionColumns } from "../../../data/tableColumns";
import { getData } from "../../../API/getData";
import { useNavigate } from "react-router-dom";
import useDeleteModal from "../../../utilities/DeleteModal";
import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit2 } from "react-icons/fi";
import AddNew from "../../../component/buttons/AddNew";

const Subscription = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const fetchSubscriptions = () => {
    getData("package/admin/subscription/").then((res) => {
      setSubscriptions(res);
    });
  };
  useEffect(() => {
    fetchSubscriptions();
  }, []);

  const navigate = useNavigate();
  const { showDeleteModal, DeleteModal } = useDeleteModal(
    "package/admin/subscription"
  );

  const actionColumn = {
    title: "Actions",
    key: "actions",
    render: (data) => {
      return (
        <div className="d-flex align-items-center">
          <button
            className="primary-bg p-1 border-0 me-2"
            onClick={() =>
              navigate(`/packages/subscriptions/edit-subscription/${data.id}`)
            }
          >
            <FiEdit2 className="text-white" />
          </button>

          <button
            className="bg-danger p-1 border-0 ms-2"
            onClick={() => showDeleteModal(data, fetchSubscriptions)}
          >
            <AiOutlineDelete className="text-white" />
          </button>
        </div>
      );
    },
  };
  console.log(subscriptions);
  const finalColumn = [...subscriptionColumns, actionColumn];
  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb directory1="Packages" directory2="Subscription" />
        <AddNew path="/packages/subscriptions/add-new" />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search mb-3">
            <h5>All Subscriptions</h5>
          </div>
          <div className="table-area">
            <Table columns={finalColumn} dataSource={subscriptions} />
          </div>
        </div>
      </div>
      {/* delete item */}
      <DeleteModal />
    </div>
  );
};

export default Subscription;
