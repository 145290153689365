import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import Input from "../../../component/inputs/Input";
import InputFile from "../../../component/inputs/FileInput";
import DropSelect from "../../../component/dropdowns/DropSelect";
import { getFileType, isActive, isFeatured } from "../../../data/data";
import postData from "../../../API/postData";
import { useNavigate } from "react-router-dom";
import { postDocumentData } from "../../../API/postDocumentData";

const AddBrand = () => {
  const [dataSet, setData] = useState({});
  const [fileInput, setFileInput] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [shouldSubmit, setShouldSubmit] = useState(false);
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setFileInput(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleInputChange = (name, value) => {
    if (name === "logo" || name === "position") {
      value = value ? parseInt(value, 10) : 0;
    }
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (fileInput) {
      const formData = new FormData();
      formData.append("document", fileInput);
      const docType = getFileType(fileInput.name);
      formData.append("doc_type", docType);

      try {
        const docUpload = await postDocumentData(
          "auth/documents/upload/",
          formData
        );
        setData((prev) => ({
          ...prev,
          logo: docUpload.data.id,
        }));
        setShouldSubmit(true);
      } catch (error) {
        console.log(error);
      }
    } else {
      setShouldSubmit(true);
    }
  };
  const navigate = useNavigate();

  useEffect(() => {
    if (shouldSubmit) {
      postData("inventory/admin/brand/", dataSet).then((response) => {
        console.log(response);
        setShouldSubmit(false);
        navigate("/inventory/brands");
      });
    }
  });
  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb
          directory1="Inventory"
          directory2="Brands"
          directory3="Add Brand"
          path="/inventory/brands"
        />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search mb-3">
            <h5>All Brands</h5>
          </div>
          <form onSubmit={handleSubmit}>
            <Input
              type="text"
              placeholder="Brand Name"
              onChange={(value) => handleInputChange("name", value)}
            />
            <Input
              type="text"
              placeholder="Position"
              onChange={(value) => handleInputChange("position", value)}
            />
            <DropSelect
              label="Is Featured"
              options={isFeatured}
              onChange={(value) => handleInputChange("is_featured", value)}
            />
            <DropSelect
              label="Is Active"
              options={isActive}
              onChange={(value) => handleInputChange("is_active", value)}
            />
            <div>
              <div className="profile-img-wrapper">
                {selectedImage ? (
                  <img
                    className="mb-2 img-fluid"
                    src={selectedImage || ""}
                    alt="brand"
                  />
                ) : (
                  ""
                )}
              </div>
              <InputFile id="brand-logo" imageChange={handleImageChange} />
            </div>
            <div>
              <button type="submit" className="btn btn-dark w-100">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddBrand;
