import React, { useEffect, useState } from "react";
import Breadcrumb from "../../component/breadcrumb/Breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { GETData } from "../../API/GETDataV2";
import { getFileType } from "../../data/data";
import { postDocumentData } from "../../API/postDocumentData";
import putData from "../../API/putData";
import { IoMdCloseCircleOutline } from "react-icons/io";
import Textarea from "../../component/inputs/Textarea";
import InputFile from "../../component/inputs/FileInput";

const EditReply = () => {
  const { id } = useParams();
  const [reply, setReply] = useState({});
  const [selectedImages, setSelectedImages] = useState([]);
  const [shouldSubmit, setShouldSubmit] = useState(false);

  useEffect(() => {
    GETData(`support/admin/reply/${id}/`).then((res) => {
      setReply(res.data.data);
      if (res.data.data.attachment_url) {
        setSelectedImages(
          res.data.data.attachment_url.map((item) => ({
            id: item.id,
            url: item.document_url,
          }))
        );
      }
    });
  }, [id]);

  const handleProductImages = (event) => {
    const files = event.target.files;
    setSelectedImages([...selectedImages, ...Array.from(files)]);
  };

  const handleProductImageRemove = (index) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedImages) {
      try {
        const productImagesId = [];
        for (const image of selectedImages) {
          const formDATA = new FormData();
          if (image.name) {
            // Check if image name is defined
            formDATA.append("document", image);
            const DOCtype = getFileType(image.name);
            formDATA.append("doc_type", DOCtype);
            console.log("uploading product images:", image.name);
            const productImagesUpload = await postDocumentData(
              "auth/documents/upload/",
              formDATA
            );

            const imagesID = productImagesUpload.data.id;
            console.log("uploaded images id:", imagesID);
            productImagesId.push(imagesID);
          }
        }
        if (productImagesId.length > 0) {
          setReply((prev) => ({
            ...prev,
            attachment: productImagesId,
          }));
        }
        setShouldSubmit(true);
      } catch (error) {
        console.log("error uploading images", error);
      }
    } else {
      setShouldSubmit(true);
    }
  };
  const navigate = useNavigate();

  if (shouldSubmit) {
    putData(`support/admin/reply/${id}/`, {
      note: reply.note,
      ticket: parseInt(reply.ticket, 10),
      attachment: reply.attachment,
    }).then((res) => {
      console.log("reply edit response", res);
      setShouldSubmit(false);
      navigate("/support/reply");
    });
  }

  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb
          directory1="Support"
          directory2="Reply"
          directory3="Edit Ticket"
          path="/support/reply"
        />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search mb-3">
            <h5>Edit Reply</h5>
          </div>
          <div>
            <form action="" onSubmit={handleSubmit}>
              <Textarea
                placeholder="Note"
                value={reply.note || ""}
                onChange={(value) =>
                  setReply((prev) => ({
                    ...prev,
                    note: value,
                  }))
                }
              />
              <div className="d-flex flex-wrap">
                <div className="me-3 add-images-input">
                  <InputFile
                    id="ticket-attachment"
                    imageChange={handleProductImages}
                    multiple={true}
                  />
                </div>
                <div className="d-flex">
                  {selectedImages.map((image, index) => (
                    <div className="add-images-wrapper" key={index}>
                      <img
                        className="img-fluid"
                        src={image.url}
                        alt={`ticket-${index}`}
                      />
                      <button
                        className="border-0 bg-transparent multi-img-close-btn"
                        onClick={() => handleProductImageRemove(index)}
                      >
                        <IoMdCloseCircleOutline className="fs-4 text-secondary" />
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              <div className="my-3">
                <button className="btn btn-dark w-100" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditReply;
