import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import { GETData } from "../../../API/GETDataV2";
import Table from "../../../component/table/Table";
import { globalSettingColumns } from "../../../data/tableColumns";
import { Link } from "react-router-dom";

const GlobalSetting = () => {
  const [globalSetting, setGlobalSetting] = useState({});
  useEffect(() => {
    GETData("utility/admin/global-settings/").then((res) => {
      setGlobalSetting(res.data.data);
    });
  }, []);

  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb directory1="Utility" directory2="Global Setting" />
        <Link
          to="/utility/global-setting/edit-global-setting"
          className="btn btn-dark"
        >
          Edit / Add Data
        </Link>
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search mb-3">
            <h5>Global Setting</h5>
          </div>
          <div className="table-area">
            <Table
              columns={globalSettingColumns}
              dataSource={[globalSetting]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalSetting;
