import React, { useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import { useNavigate } from "react-router-dom";
import postData from "../../../API/postData";
import Input from "../../../component/inputs/Input";
import DropSelect from "../../../component/dropdowns/DropSelect";
import { isActive } from "../../../data/data";

const AddDeliveryCharge = () => {
  const [deliveryCharge, setDeliveryCharge] = useState({});

  const handleInputChange = (name, value) => {
    if (name === "km_distance") {
      value = value ? parseInt(value, 10) : 0;
    }
    setDeliveryCharge((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    postData("delivery/admin/delivery_charge/", deliveryCharge)
      .then((res) => {
        console.log("add delivery charge response", res);
        navigate("/delivery/delivery-charge");
      })
      .catch((err) => {
        console.log("error posting delivery charge data", err);
      });
  };

  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb
          directory1="Delivery"
          directory2="Delivery Charge"
          directory3="Add Delivery Charge"
          path="/delivery/delivery-charge"
        />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search mb-3">
            <h5>Add Delivery Charge</h5>
          </div>
          <div>
            <form action="" onSubmit={handleSubmit}>
              <Input
                type="text"
                placeholder="Distance (KM)"
                onChange={(value) => handleInputChange("km_distance", value)}
                required={true}
              />
              <Input
                type="text"
                placeholder="Amount"
                onChange={(value) => handleInputChange("amount", value)}
                required={true}
              />
              <DropSelect
                label="Status"
                options={isActive}
                onChange={(value) => handleInputChange("is_active", value)}
              />
              <button className="my-3 btn btn-dark w-100" type="submit">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDeliveryCharge;
