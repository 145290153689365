import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import AddNew from "../../../component/buttons/AddNew";
import { getData } from "../../../API/getData";
import useDeleteModal from "../../../utilities/DeleteModal";
import { useNavigate } from "react-router-dom";
import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit2 } from "react-icons/fi";
import { variantOptionsColumn } from "../../../data/tableColumns";
import Table from "../../../component/table/Table";

const VariantOption = () => {
  const [variantOptions, setVariantOptions] = useState([]);
  const fetchvariantOptions = () => {
    getData("inventory/admin/variant-option/")
      .then((data) => {
        setVariantOptions(data);
      })
      .catch((err) => {
        console.log("error getting variant options data", err);
      });
  };
  useEffect(() => {
    fetchvariantOptions();
  }, []);

  const { showDeleteModal, DeleteModal } = useDeleteModal(
    "inventory/admin/variant-option"
  );
  const navigate = useNavigate();

  const actionColumn = {
    title: "Actions",
    key: "actions",
    render: (data) => {
      return (
        <div className="d-flex align-items-center">
          <button
            className="primary-bg p-1 border-0 me-2"
            onClick={() =>
              navigate(`/inventory/variant-option/edit-option/${data.id}`)
            }
          >
            <FiEdit2 className="text-white" />
          </button>

          <button
            className="bg-danger p-1 border-0 ms-2"
            onClick={() => showDeleteModal(data, fetchvariantOptions)}
          >
            <AiOutlineDelete className="text-white" />
          </button>
        </div>
      );
    },
  };
  const finalColumn = [...variantOptionsColumn, actionColumn];
  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb directory1="Inventory" directory2="Variant Option" />
        <AddNew path="/inventory/variant-option/add-option" />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search mb-3">
            <h5>All Variant Options</h5>
          </div>
          <div className="table-area">
            <Table columns={finalColumn} dataSource={variantOptions} />
          </div>
        </div>
      </div>
      {/* delete item */}
      <DeleteModal />
    </div>
  );
};

export default VariantOption;
