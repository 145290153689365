import React, { useCallback, useEffect, useState } from "react";
import Breadcrumb from "../../component/breadcrumb/Breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { GETData } from "../../API/GETDataV2";
import Input from "../../component/inputs/Input";
import DropSelect from "../../component/dropdowns/DropSelect";
import Textarea from "../../component/inputs/Textarea";
import { isRead, notificationTypeChoice } from "../../data/data";
import putData from "../../API/putData";

const EditNotification = () => {
  const { id } = useParams();
  const [editItem, setEditItem] = useState({});
  const [userData, setUserData] = useState([]);

  const fetchUserData = () => {
    GETData("auth/user/").then((res) => {
      setUserData(res.data.data);
    });
  };

  const fetchData = useCallback(() => {
    GETData(`notification/admin/personal-notification/${id}/`).then((res) => {
      setEditItem(res.data.data);
    });
  }, [id]);

  const [formattedDate, setFormattedDate] = useState("");
  const [formattedTime, setFormattedTime] = useState("");

  useEffect(() => {
    fetchData();
    fetchUserData();
    const dateObject = new Date(editItem.timestamp);
    // Format date
    const year = dateObject.getFullYear();
    const month = `${dateObject.getMonth() + 1}`.padStart(2, "0");
    const day = `${dateObject.getDate()}`.padStart(2, "0");
    setFormattedDate(`${year}-${month}-${day}`);

    // Format time
    const hours = `${dateObject.getHours()}`.padStart(2, "0");
    const minutes = `${dateObject.getMinutes()}`.padStart(2, "0");
    setFormattedTime(`${hours}:${minutes}`);
  }, [editItem.timestamp, fetchData]);

  const handleInputChange = (name, value) => {
    if (name === "user" || name === "notification_type") {
      value = value ? parseInt(value, 10) : 0;
    }
    setEditItem((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    putData(`notification/admin/personal-notification/${id}/`, editItem)
      .then((res) => {
        console.log("edit notification response", res);
        navigate("/notifications/personal-notifications");
      })
      .catch((err) => {
        console.log("error editing notification", err);
      });
  };

  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb
          directory1="Notifications"
          directory2="Edit Notification"
          path="/notifications"
        />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search mb-3">
            <h5>Edit Notification</h5>
          </div>
          <form action="" onSubmit={handleSubmit}>
            <Input
              type="text"
              placeholder="Title"
              required={true}
              value={editItem.title || ""}
              onChange={(value) => handleInputChange("title", value)}
            />
            <Textarea
              placeholder="Message"
              value={editItem.message || ""}
              onChange={(value) => handleInputChange("message", value)}
            />
            <Input
              type="date"
              placeholder="Date"
              required={true}
              value={formattedDate}
            />
            <Input
              type="time"
              placeholder="Time"
              required={true}
              value={formattedTime}
            />
            <DropSelect
              label="Notification Type"
              options={notificationTypeChoice}
              value={editItem.notification_type}
              onChange={(value) =>
                handleInputChange("notification_type", value)
              }
            />
            <DropSelect
              label="Is Read ?"
              options={isRead}
              value={editItem.is_read}
              onChange={(value) => handleInputChange("is_read", value)}
            />

            <DropSelect
              label="User"
              options={[
                {
                  value: "",
                  title: "Select an User",
                  disabled: true,
                },
                ...userData.map((item) => ({
                  value: item.id,
                  title: item.user_name,
                })),
              ]}
              value={editItem.user}
              onChange={(value) => handleInputChange("user", value)}
            />
            <button className="btn btn-dark w-100" type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditNotification;

// function formatTimestamp(timestamp) {
//   const dateObject = new Date(timestamp);

//   const formattedDate = dateObject.toLocaleDateString("en-US", {
//     year: "numeric",
//     month: "2-digit",
//     day: "2-digit",
//   });

//   const formattedTime = dateObject.toLocaleTimeString("en-US", {
//     hour: "2-digit",
//     minute: "2-digit",
//     hour12: true,
//   });

//   return { formattedDate, formattedTime };
// }
