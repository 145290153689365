import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { GETData } from "../../../API/GETDataV2";
import putData from "../../../API/putData";
import Input from "../../../component/inputs/Input";
import DropSelect from "../../../component/dropdowns/DropSelect";
import { isActive, membershipAndPackageType } from "../../../data/data";

const EditPackage = () => {
  const { id } = useParams();
  const [packages, setPackage] = useState({});

  useEffect(() => {
    GETData(`package/admin/package/${id}/`).then((res) => {
      setPackage(res.data.data);
    });
  }, [id]);

  const handleInputChange = (name, value) => {
    if (name === "package_type" || name === "duration") {
      value = value ? parseInt(value, 10) : 0;
    }
    setPackage((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    putData(`package/admin/package/${id}/`, {
      package_name: packages.package_name,
      price: packages.price,
      package_type: packages.package_type,
      duration: packages.duration,
      details: packages.details,
      is_active: packages.is_active,
    }).then((res) => {
      console.log("edit package response", res);
      navigate("/packages/package-list");
    });
  };
  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb
          directory1="Packages"
          directory2="Package List"
          directory3="edit-package"
          path="/packages/package-list"
        />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search mb-3">
            <h5>Edit Package</h5>
          </div>
          <div>
            <form action="" onSubmit={handleSubmit}>
              <Input
                type="text"
                placeholder="Package Name"
                required={true}
                value={packages.package_name || ""}
                onChange={(value) => handleInputChange("package_name", value)}
              />
              <Input
                type="text"
                placeholder="Price"
                required={true}
                value={packages.price || ""}
                onChange={(value) => handleInputChange("price", value)}
              />
              <DropSelect
                label="Package Type"
                options={membershipAndPackageType}
                value={packages.package_type}
                onChange={(value) => handleInputChange("package_type", value)}
              />
              <Input
                type="text"
                placeholder="Duration (in days)"
                required={true}
                value={packages.duration || ""}
                onChange={(value) => handleInputChange("duration", value)}
              />
              <Input
                type="text"
                placeholder="Details"
                required={true}
                value={packages.details || ""}
                onChange={(value) => handleInputChange("details", value)}
              />
              <DropSelect
                label="Status"
                options={isActive}
                value={packages.is_active}
                onChange={(value) => handleInputChange("is_active", value)}
              />
              <button className="my-3 btn btn-dark w-100" type="submit">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPackage;
