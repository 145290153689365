import React, { useEffect, useState } from "react";
import Breadcrumb from "../../component/breadcrumb/Breadcrumb";
import { getData } from "../../API/getData";
import Table from "../../component/table/Table";
import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit2 } from "react-icons/fi";
import useDeleteModal from "../../utilities/DeleteModal";
import { useNavigate } from "react-router-dom";

const Replies = () => {
  const [replies, setReplies] = useState([]);
  const [tickets, setTickets] = useState([]);
  const fetchRelpies = () => {
    getData("support/admin/reply/").then((res) => {
      setReplies(res);
    });
  };
  useEffect(() => {
    fetchRelpies();
    getData("support/admin/ticket/").then((res) => {
      setTickets(res);
    });
  }, []);

  const { showDeleteModal, DeleteModal } = useDeleteModal(
    "support/admin/reply"
  );

  const navigate = useNavigate();

  const replyColumns = [
    { title: "ID", key: "id" },
    { title: "Note", key: "note" },
    {
      title: "Ticket",
      key: "ticket",
      render: (data) => {
        const ticket = tickets.find((item) => item.id === data.ticket);
        return ticket ? ticket.subject : "Unknown";
      },
    },
    {
      title: "Action",
      key: "action",
      render: (data) => {
        return (
          <div className="d-flex align-items-center">
            <button
              className="primary-bg p-1 border-0 me-2"
              onClick={() => navigate(`/support/reply/edit-reply/${data.id}`)}
            >
              <FiEdit2 className="text-white" />
            </button>

            <button
              className="bg-danger p-1 border-0 ms-2"
              onClick={() => showDeleteModal(data, fetchRelpies)}
            >
              <AiOutlineDelete className="text-white" />
            </button>
          </div>
        );
      },
    },
  ];
  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb directory1="Support" directory2="Reply" />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search mb-3">
            <h5>All Replies</h5>
          </div>
          <div className="table-area">
            <Table columns={replyColumns} dataSource={replies} />
          </div>
        </div>
      </div>
      {/* delete item */}
      <DeleteModal />
    </div>
  );
};

export default Replies;
