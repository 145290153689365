import React, { useEffect, useState } from "react";
import Breadcrumb from "../../component/breadcrumb/Breadcrumb";
import AddNew from "../../component/buttons/AddNew";
import Table from "../../component/table/Table";
import { getData } from "../../API/getData";
import { notificationColumns } from "../../data/tableColumns";
import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit2 } from "react-icons/fi";
import useDeleteModal from "../../utilities/DeleteModal";
import { useNavigate } from "react-router-dom";

const Notification = () => {
  const [tableData, setTableData] = useState([]);

  const fetchData = () => {
    getData("notification/admin/personal-notification/").then((res) => {
      setTableData(res);
    });
  };
  useEffect(() => {
    fetchData();
  }, []);

  const navigate = useNavigate();
  const { showDeleteModal, DeleteModal } = useDeleteModal(
    "notification/admin/personal-notification"
  );

  const actionColumn = {
    title: "Actions",
    key: "actions",
    render: (data) => {
      return (
        <div className="d-flex align-items-center">
          <button
            className="primary-bg p-1 border-0 me-2"
            onClick={() =>
              navigate(`/notifications/edit-notification/${data.id}`)
            }
          >
            <FiEdit2 className="text-white" />
          </button>

          <button
            className="bg-danger p-1 border-0 ms-2"
            onClick={() => showDeleteModal(data, fetchData)}
          >
            <AiOutlineDelete className="text-white" />
          </button>
        </div>
      );
    },
  };
  const finalColumn = [...notificationColumns, actionColumn];

  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb
          directory1="Notifications"
          directory2="Personal Notifications"
        />
        <AddNew path="/notifications/add-notification" />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search mb-3">
            <h5>All Notifications</h5>
          </div>
          <div className="table-area">
            <Table columns={finalColumn} dataSource={tableData} />
          </div>
        </div>
      </div>
      {/* delete modal */}
      <DeleteModal />
    </div>
  );
};

export default Notification;
