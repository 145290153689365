import React, { useCallback, useEffect, useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import Input from "../../../component/inputs/Input";
import DropSelect from "../../../component/dropdowns/DropSelect";
import { isActive } from "../../../data/data";
import { getData } from "../../../API/getData";
import { useNavigate, useParams } from "react-router-dom";
import { GETData } from "../../../API/GETDataV2";
import putData from "../../../API/putData";

const EditVariantOption = () => {
  const { id } = useParams();

  const [variantOptions, setVariantOptions] = useState({});
  const [variantGroups, setVariantGroups] = useState([]);
  const fetchVariantGroups = () => {
    getData("inventory/admin/variant-group/")
      .then((res) => {
        setVariantGroups(res);
      })
      .catch((err) => {
        console.log("error getting variant groups", err);
      });
  };

  const fetchVariantOptions = useCallback(() => {
    GETData(`inventory/admin/variant-option/${id}/`)
      .then((res) => {
        setVariantOptions(res.data.data);
      })
      .catch((err) => {
        console.log("error getting variant options", err);
      });
  }, [id]);

  const handleInputChange = (name, value) => {
    if (name === "group") {
      value = parseInt(value, 10);
    }
    setVariantOptions((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    fetchVariantOptions();
    fetchVariantGroups();
  }, [fetchVariantOptions]);

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    putData(`inventory/admin/variant-option/${id}/`, variantOptions)
      .then((res) => {
        console.log("variant options edit response", res);
        navigate("/inventory/variant-option");
      })
      .catch((err) => {
        console.log("error editing variant options", err);
      });
  };

  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb
          directory1="Inventory"
          directory2="Variant Option"
          directory3="Add Variant Option"
          path="/inventory/variant-option"
        />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search mb-3">
            <h5>Add Variant Option</h5>
          </div>
          <form onSubmit={handleSubmit}>
            <Input
              type="text"
              placeholder="Name"
              value={variantOptions.name || ""}
              onChange={(value) => handleInputChange("name", value)}
              required={true}
            />
            <DropSelect
              label="Status"
              options={isActive}
              onChange={(value) => handleInputChange("is_active", value)}
              value={variantOptions.is_active}
            />
            <DropSelect
              label="Group"
              options={[
                {
                  value: "",
                  title: "Select a group",
                  disabled: true,
                },
                ...variantGroups.map((item) => ({
                  value: item.id,
                  title: item.group_name,
                })),
              ]}
              value={variantOptions.group}
              onChange={(value) => handleInputChange("group", value)}
            />

            <div className="mt-4">
              <button type="submit" className="btn btn-dark w-100">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditVariantOption;
