import React, { useCallback, useEffect, useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import Input from "../../../component/inputs/Input";
import DropSelect from "../../../component/dropdowns/DropSelect";
import { isActive } from "../../../data/data";
import { useNavigate, useParams } from "react-router-dom";
import { GETData } from "../../../API/GETDataV2";
import putData from "../../../API/putData";

const EditVariantGroup = () => {
  const { id } = useParams();
  const [variantData, setVariantData] = useState({});

  const handleInputChange = (name, value) => {
    setVariantData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const fetchVariantData = useCallback(() => {
    GETData(`inventory/admin/variant-group/${id}/`)
      .then((res) => {
        setVariantData(res.data.data);
      })
      .catch((err) => {
        console.log("error getting variant data", err);
      });
  }, [id]);

  useEffect(() => {
    fetchVariantData();
  }, [fetchVariantData]);

  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    putData(`inventory/admin/variant-group/${id}/`, variantData)
      .then((res) => {
        console.log("variant group edit response", res);
        navigate("/inventory/variant-group");
      })
      .catch((err) => {
        console.log("error editing variant group", err);
      });
  };
  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb
          directory1="Inventory"
          directory2="Variant Group"
          directory3="Edit Variant group"
          path="/inventory/variant-group"
        />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search mb-3">
            <h5>Add Variant Group</h5>
          </div>

          <form onSubmit={handleSubmit}>
            <Input
              type="text"
              placeholder="Group Name"
              value={variantData.group_name || ""}
              onChange={(value) => handleInputChange("group_name", value)}
              required={true}
            />
            <DropSelect
              label="Status"
              value={variantData.is_active}
              options={isActive}
              onChange={(value) => handleInputChange("is_active", value)}
            />
            <div className="mt-3">
              <button type="submit" className="btn btn-dark w-100">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditVariantGroup;
