import React, { useState, useEffect } from "react";
import Breadcrumb from "../../component/breadcrumb/Breadcrumb";
import Input from "../../component/inputs/Input";
import DropSelect from "../../component/dropdowns/DropSelect";
import { isRead, notificationTypeChoice } from "../../data/data";
import { GETData } from "../../API/GETDataV2";
import Textarea from "../../component/inputs/Textarea";
import postData from "../../API/postData";
import { useNavigate } from "react-router-dom";

const AddNotification = () => {
  const [userData, setUserData] = useState([]);
  const [timeStamp, setTimeStamp] = useState({});
  const fetchData = () => {
    GETData("auth/user/").then((res) => {
      setUserData(res.data.data);
    });
  };
  useEffect(() => {
    fetchData();
    let timeDate = `${timeStamp.date}T${timeStamp.time}`;
    setNotificationData((prev) => ({
      ...prev,
      timestamp: timeDate,
    }));
  }, [timeStamp]);

  const [notificationData, setNotificationData] = useState({});

  const handleInputChange = (name, value) => {
    if (name === "user" || name === "notification_type") {
      value = value ? parseInt(value, 10) : 0;
    }
    setNotificationData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const postNotification = await postData(
        "notification/admin/personal-notification/",
        notificationData
      );
      console.log(postNotification);
      navigate("/notifications/personal-notifications");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb
          directory1="Notifications"
          directory2="Personal Notification"
          directory3="Add New Notification"
          path="/notifications/personal-notifications"
        />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search mb-3">
            <h5>Add Notification</h5>
          </div>
          <form className="mb-3" onSubmit={handleSubmit}>
            <Input
              type="text"
              placeholder="Title"
              required={true}
              onChange={(value) => handleInputChange("title", value)}
            />
            <Textarea
              placeholder="Message"
              onChange={(value) => handleInputChange("message", value)}
            />
            <Input
              type="date"
              placeholder="Date"
              required={true}
              onChange={(value) =>
                setTimeStamp((prev) => ({
                  ...prev,
                  date: value,
                }))
              }
            />
            <Input
              type="time"
              placeholder="Time"
              required={true}
              onChange={(value) =>
                setTimeStamp((prev) => ({
                  ...prev,
                  time: value,
                }))
              }
            />
            <DropSelect
              label="Notification Type"
              options={notificationTypeChoice}
              onChange={(value) =>
                handleInputChange("notification_type", value)
              }
            />
            <DropSelect
              label="Is Read ?"
              options={isRead}
              onChange={(value) => handleInputChange("is_read", value)}
            />

            <DropSelect
              label="User"
              options={[
                {
                  value: "",
                  title: "Select an User",
                  disabled: true,
                },
                ...userData.map((item) => ({
                  value: item.id,
                  title: item.user_name,
                })),
              ]}
              onChange={(value) => handleInputChange("user", value)}
            />
            <button className="btn btn-dark w-100" type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddNotification;
