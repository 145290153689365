import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoutes } from "../ProtectedRoutes";
import Ticket from "../../view/support/Ticket";
import SendReply from "../../view/support/SendReply";
import Replies from "../../view/support/Replies";
import EditReply from "../../view/support/EditReply";

const SupportRoutes = () => {
  return (
    <Routes>
      <Route path="/tickets" element={<ProtectedRoutes Component={Ticket} />} />
      <Route
        path="/ticket-reply/:id"
        element={<ProtectedRoutes Component={SendReply} />}
      />
      <Route path="/reply" element={<ProtectedRoutes Component={Replies} />} />
      <Route
        path="/reply/edit-reply/:id"
        element={<ProtectedRoutes Component={EditReply} />}
      />
    </Routes>
  );
};

export default SupportRoutes;
