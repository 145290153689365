import React, { useEffect, useState } from "react";
import Breadcrumb from "../../component/breadcrumb/Breadcrumb";
import { getData } from "../../API/getData";
import Table from "../../component/table/Table";
import { likeColumns } from "../../data/tableColumns";
import useDeleteModal from "../../utilities/DeleteModal";
import { AiOutlineDelete } from "react-icons/ai";

const Likes = () => {
  const [likes, setLikes] = useState([]);
  const fetchLikes = () => {
    getData("blog/admin/like/").then((res) => {
      setLikes(res);
    });
  };
  useEffect(() => {
    fetchLikes();
  }, []);

  const { showDeleteModal, DeleteModal } = useDeleteModal("blog/admin/like");

  const actionColumn = {
    title: "Actions",
    key: "actions",
    render: (data) => {
      return (
        <div className="d-flex align-items-center">
          <button
            className="bg-danger p-1 border-0 ms-2"
            onClick={() => showDeleteModal(data, fetchLikes)}
          >
            <AiOutlineDelete className="text-white" />
          </button>
        </div>
      );
    },
  };

  const finalColumn = [...likeColumns, actionColumn];
  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb directory1="Blogs" directory2="Likes" />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search mb-3">
            <h5>All Likes</h5>
          </div>
          <div className="table-area">
            <Table columns={finalColumn} dataSource={likes} />
          </div>
        </div>
      </div>
      {/*  */}
      <DeleteModal />
    </div>
  );
};

export default Likes;
