import React from "react";
import { Link } from "react-router-dom";
import { BiAddToQueue } from "react-icons/bi";

const AddNew = ({ path }) => {
  return (
    <Link to={path} className="btn btn-dark px-4">
      <BiAddToQueue className="me-2 mb-1" />
      Add New
    </Link>
  );
};

export default AddNew;
