import React, { useEffect, useState } from "react";
import { CiCirclePlus } from "react-icons/ci";
import { IoMdCloseCircleOutline } from "react-icons/io";
import Input from "../component/inputs/Input";
import DropSelect from "../component/dropdowns/DropSelect";
import { getData } from "../API/getData";

const Productvariant = ({
  productVariants: initialProductVariants,
  onVariantDataChange,
}) => {
  const [productVariants, setProductVariants] = useState(
    initialProductVariants
  );
  const [variantOptions, setVariantOptions] = useState([]);

  //   fetching variant options data
  const fetchData = () => {
    getData("inventory/admin/variant-option/").then((res) => {
      setVariantOptions(res);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleVariantsData = (index, name, value) => {
    const updatedVariants = [...productVariants];
    updatedVariants[index] = {
      ...updatedVariants[index],
      [name]: value,
    };
    setProductVariants(updatedVariants);
    if (onVariantDataChange) {
      onVariantDataChange(updatedVariants);
    }
  };

  const handleAddVariant = () => {
    setProductVariants([...productVariants, {}]);
  };

  const handleRemoveVariant = (index) => {
    const updatedVariants = productVariants.filter((_, i) => i !== index);
    setProductVariants(updatedVariants);
    if (onVariantDataChange) {
      onVariantDataChange(updatedVariants);
    }
  };
  return (
    <>
      <div className="d-flex justify-content-center">
        <button
          type="button"
          className="bg-transparent border-0 mb-3"
          onClick={handleAddVariant}
        >
          <CiCirclePlus className="fs-1" />
        </button>
      </div>
      {productVariants.map((variant, index) => (
        <div className="row has-variants" key={index}>
          <div className="col-md-2">
            <Input
              type="text"
              placeholder="Code"
              value={variant.code || ""}
              onChange={(value) => handleVariantsData(index, "code", value)}
            />
          </div>
          <div className="col-md-3">
            <Input
              type="text"
              placeholder="Quantity"
              value={variant.quantity || ""}
              onChange={(value) => handleVariantsData(index, "quantity", value)}
            />
          </div>
          <div className="col-md-3">
            <Input
              type="text"
              placeholder="Additional Price"
              value={variant.additional_price || ""}
              onChange={(value) =>
                handleVariantsData(index, "additional_price", value)
              }
            />
          </div>
          <div className="col-md-3">
            <DropSelect
              label="Variant Option"
              options={[
                {
                  value: "",
                  title: "Select variant option",
                  disabled: true,
                },
                ...variantOptions.map((item) => ({
                  value: item.id,
                  title: item.name,
                })),
              ]}
              value={variant.variant_option}
              onChange={(value) =>
                handleVariantsData(index, "variant_option", value)
              }
            />
          </div>
          <div className="col-md-1">
            <button
              type="button"
              className="border-0 bg-transparent text-danger"
              onClick={() => handleRemoveVariant(index)}
            >
              <IoMdCloseCircleOutline className="fs-2 mt-2" />
            </button>
          </div>
        </div>
      ))}
    </>
  );
};

export default Productvariant;
