import React, { useCallback, useEffect, useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import InputFile from "../../../component/inputs/FileInput";
import DropSelect from "../../../component/dropdowns/DropSelect";
import Input from "../../../component/inputs/Input";
import { useNavigate, useParams } from "react-router-dom";
import { getFileType, isActive, isFeatured } from "../../../data/data";
import { GETData } from "../../../API/GETDataV2";
import { postDocumentData } from "../../../API/postDocumentData";
import putData from "../../../API/putData";

const EditBrand = () => {
  const { id } = useParams();
  const [itemData, setItemData] = useState({});
  const [fileInput, setFileInput] = useState();

  const [shouldSubmit, setShouldSubmit] = useState(false);

  const [selectedImage, setSelectedImage] = useState();
  const fetchItemData = useCallback(async () => {
    try {
      const response = await GETData(`inventory/admin/brand/${id}/`);
      setItemData(response.data.data);
      setSelectedImage(response.data.data.logo_url);
    } catch (error) {
      console.log("error fetching edit brand data item: ", error);
    }
  }, [id]);

  useEffect(() => {
    fetchItemData();
  }, [fetchItemData]);

  const handleInputChange = (name, value) => {
    if (name === "logo" || name === "position") {
      value = value ? parseInt(value, 10) : 0;
    }
    setItemData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setFileInput(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (fileInput) {
      const formData = new FormData();
      formData.append("document", fileInput);
      const docType = getFileType(fileInput.name);
      formData.append("doc_type", docType);

      try {
        const docUpload = await postDocumentData(
          "auth/documents/upload/",
          formData
        );
        console.log(docUpload);
        setItemData((prev) => ({
          ...prev,
          logo: docUpload.data.id,
        }));
        setShouldSubmit(true);
      } catch (error) {
        console.log(error);
      }
    } else {
      setShouldSubmit(true);
    }
  };
  const navigate = useNavigate();
  const putEditData = useCallback(() => {
    putData(`inventory/admin/brand/${id}/`, itemData).then((res) => {
      console.log(res);
    });
    setShouldSubmit(false);

    navigate("/inventory/brands");
  }, [itemData, id, navigate]);

  useEffect(() => {
    if (shouldSubmit) {
      putEditData();
    }
  }, [shouldSubmit, putEditData]);

  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb
          directory1="Inventory"
          directory2="Brands"
          directory3="Edit Brand"
          path="/inventory/brands"
        />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search mb-3">
            <h5>Edit Brand</h5>
          </div>

          <form onSubmit={handleSubmit}>
            <Input
              type="text"
              placeholder="Brand Name"
              value={itemData.name || ""}
              onChange={(value) => handleInputChange("name", value)}
            />
            <Input
              type="text"
              placeholder="Position"
              value={itemData.position || ""}
              onChange={(value) => handleInputChange("position", value)}
            />
            <DropSelect
              label="Is Featured"
              options={isFeatured}
              value={itemData.is_featured}
              onChange={(value) => handleInputChange("is_featured", value)}
            />
            <DropSelect
              label="Is Active"
              options={isActive}
              value={itemData.is_active}
              onChange={(value) => handleInputChange("is_active", value)}
            />
            <div>
              <div className="profile-img-wrapper">
                {selectedImage ? (
                  <img
                    className="mb-2 img-fluid"
                    src={selectedImage || ""}
                    alt="brand"
                  />
                ) : (
                  ""
                )}
              </div>
              <InputFile id="brand-logo-2" imageChange={handleImageChange} />
            </div>
            <div>
              <button type="submit" className="btn btn-dark w-100">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditBrand;
