import React from "react";

const DashboardCard = ({ icon, title, value }) => {
  return (
    <div className="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-2 mb-2">
      <div className="p-4 secondary-bg rounded-12 text-white d-flex flex-column align-items-center justify-content-center">
        <div className="fs-2 mb-1">{React.createElement(icon)}</div>
        <h5 className="mb-1">{value}</h5>
        <p className="mb-0 text-muted">{title}</p>
      </div>
    </div>
  );
};

export default DashboardCard;
