import React, { useEffect, useState } from "react";
import Input from "../component/inputs/Input";

const AdditionalProp = ({
  additionProps: initialAdditionProps,
  onAdditionPropsChange,
}) => {
  const [additionProps, setAdditionProps] = useState(initialAdditionProps);

  useEffect(() => {
    setAdditionProps(initialAdditionProps);
  }, [initialAdditionProps]);

  const handleAdditionPropInput = (name, value) => {
    const newProps = {
      ...additionProps,
      [name]: value,
    };
    setAdditionProps(newProps);
    if (onAdditionPropsChange) {
      onAdditionPropsChange(newProps);
    }
  };
  return (
    <div className="row mt-4 py-4 border-top border-2 border-secondary">
      <div className="col-md-4">
        <Input
          type="text"
          placeholder="Additional Prop 1"
          value={additionProps.additionalProp1 || ""}
          onChange={(value) =>
            handleAdditionPropInput("additionalProp1", value)
          }
        />
      </div>
      <div className="col-md-4">
        <Input
          type="text"
          placeholder="Additional Prop 2"
          value={additionProps.additionalProp2 || ""}
          onChange={(value) =>
            handleAdditionPropInput("additionalProp2", value)
          }
        />
      </div>
      <div className="col-md-4">
        <Input
          type="text"
          placeholder="Additional Prop 3"
          value={additionProps.additionalProp3 || ""}
          onChange={(value) =>
            handleAdditionPropInput("additionalProp3", value)
          }
        />
      </div>
    </div>
  );
};

export default AdditionalProp;
