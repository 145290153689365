import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoutes } from "../ProtectedRoutes";
import Banner from "../../view/utility/banner/Banner";
import AddBanner from "../../view/utility/banner/AddBanner";
import EditBanner from "../../view/utility/banner/EditBanner";
import Currency from "../../view/utility/currency/Currency";
import AddCurrency from "../../view/utility/currency/AddCurrency";
import EditCurrency from "../../view/utility/currency/EditCurrency";
import EmailSubscription from "../../view/utility/email-subscription/EmailSubscription";
import Faq from "../../view/utility/faq/Faq";
import AddFaq from "../../view/utility/faq/AddFaq";
import EditFaq from "../../view/utility/faq/EditFaq";
import GlobalSetting from "../../view/utility/global-setting/GlobalSetting";
import EditGlobalSetting from "../../view/utility/global-setting/EditGlobalSetting";
import Page from "../../view/utility/page/Page";
import AddPage from "../../view/utility/page/AddPage";
import EditPage from "../../view/utility/page/EditPage";

const UtilityRoot = () => {
  return (
    <Routes>
      <Route path="/banner" element={<ProtectedRoutes Component={Banner} />} />
      <Route
        path="/banner/add-new"
        element={<ProtectedRoutes Component={AddBanner} />}
      />
      <Route
        path="/banner/edit-banner/:id"
        element={<ProtectedRoutes Component={EditBanner} />}
      />
      <Route
        path="/currency"
        element={<ProtectedRoutes Component={Currency} />}
      />
      <Route
        path="/currency/add-new"
        element={<ProtectedRoutes Component={AddCurrency} />}
      />
      <Route
        path="/currency/edit-currency/:id"
        element={<ProtectedRoutes Component={EditCurrency} />}
      />
      <Route
        path="/email-subscription"
        element={<ProtectedRoutes Component={EmailSubscription} />}
      />
      <Route path="/faq" element={<ProtectedRoutes Component={Faq} />} />
      <Route
        path="/faq/add-faq"
        element={<ProtectedRoutes Component={AddFaq} />}
      />
      <Route
        path="/faq/edit-faq/:id"
        element={<ProtectedRoutes Component={EditFaq} />}
      />
      <Route
        path="/global-setting"
        element={<ProtectedRoutes Component={GlobalSetting} />}
      />
      <Route
        path="/global-setting/edit-global-setting"
        element={<ProtectedRoutes Component={EditGlobalSetting} />}
      />
      <Route path="/page" element={<ProtectedRoutes Component={Page} />} />
      <Route
        path="/page/add-page"
        element={<ProtectedRoutes Component={AddPage} />}
      />
      <Route
        path="/page/edit-page/:id"
        element={<ProtectedRoutes Component={EditPage} />}
      />
    </Routes>
  );
};

export default UtilityRoot;
