import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import Input from "../../../component/inputs/Input";
import DropSelect from "../../../component/dropdowns/DropSelect";
import { getData } from "../../../API/getData";
import {
  getFileType,
  isActive,
  isFeatured,
  stockStatusData,
} from "../../../data/data";
import InputFile from "../../../component/inputs/FileInput";
import ProductVariants from "./ProductVariants";
import AdditionalProp from "./AdditionalProp";
import { postDocumentData } from "../../../API/postDocumentData";
import { IoMdCloseCircleOutline } from "react-icons/io";
import postData from "../../../API/postData";
import { useNavigate } from "react-router-dom";

const AddProduct = () => {
  const [categoryData, setCategoryData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [dataSet, setData] = useState({});

  const [fileInput, setFileInput] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [shouldSubmit, setShouldSubmit] = useState(false);

  const [selectedImages, setSelectedImages] = useState([]);

  const navigate = useNavigate();

  const fetchData = () => {
    getData("inventory/admin/category/").then((res) => {
      setCategoryData(res);
    });
    getData("inventory/admin/brand/").then((res) => {
      setBrandData(res);
    });
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleInputChange = (name, value) => {
    if (
      name === "category" ||
      name === "brand" ||
      name === "quantity" ||
      name === "reward_points" ||
      name === "stock_status" ||
      name === "brand"
    ) {
      value = value ? parseInt(value, 10) : 0;
    }
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // thumbnail image
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setFileInput(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // variant options
  const handleVariantDataChange = (newVariantData) => {
    // console.log("Updated Variant Data:", newVariantData);
    setData((prev) => ({
      ...prev,
      product_variants: newVariantData,
    }));
  };

  const handleVariantOption = (value) => {
    setData((prev) => ({
      ...prev,
      has_variant: value,
    }));
  };

  // additional prop

  const handleAdditionPropsChange = (newAdditionProp) => {
    setData((prev) => ({
      ...prev,
      product_specification: newAdditionProp,
    }));
  };

  // upload images
  const handleProductImages = (event) => {
    const files = event.target.files;
    setSelectedImages([...selectedImages, ...Array.from(files)]);
  };

  const handleProductImageRemove = (index) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (fileInput) {
      const formData = new FormData();
      formData.append("document", fileInput);
      const docType = getFileType(fileInput.name);
      formData.append("doc_type", docType);

      try {
        const docUpload = await postDocumentData(
          "auth/documents/upload/",
          formData
        );
        setData((prev) => ({
          ...prev,
          thumbnail: docUpload.data.id,
        }));

        if (selectedImages) {
          try {
            const productImagesId = [];
            for (const image of selectedImages) {
              const formDATA = new FormData();
              formDATA.append("document", image);
              const DOCtype = getFileType(image.name);
              formDATA.append("doc_type", DOCtype);
              console.log("uploading product images:", image.name);
              const productImagesUpload = await postDocumentData(
                "auth/documents/upload/",
                formDATA
              );

              const imagesID = productImagesUpload.data.id;
              console.log("uploaded images id:", imagesID);
              productImagesId.push(imagesID);
            }
            setData((prev) => ({
              ...prev,
              images: productImagesId,
            }));
            setShouldSubmit(true);
          } catch (error) {
            console.log("error uploading images", error);
          }
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setShouldSubmit(true);
    }
  };

  if (shouldSubmit) {
    postData("inventory/admin/product/", dataSet).then((res) => {
      console.log(res);
      navigate("/inventory/products");
      setShouldSubmit(false);
    });
  }

  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb
          directory1="Inventory"
          directory2="Products"
          directory3="Add Product"
          path="/inventory/products"
        />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search">
            <h5>Add product</h5>
          </div>

          <form action="" onSubmit={handleFormSubmit}>
            <div className="row">
              <div className="col-md-6">
                <Input
                  type="text"
                  placeholder="Name"
                  onChange={(value) => handleInputChange("name", value)}
                />
                <DropSelect
                  label="Category"
                  options={[
                    {
                      value: "",
                      title: "Select Category",
                      disabled: true,
                    },
                    ...categoryData.map((item) => ({
                      value: item.id,
                      title: item.name,
                    })),
                  ]}
                  onChange={(value) => handleInputChange("category", value)}
                />
                <DropSelect
                  label="Brand"
                  options={[
                    {
                      value: "",
                      title: "Select Brand",
                      disabled: true,
                    },
                    ...brandData.map((item) => ({
                      value: item.id,
                      title: item.name,
                    })),
                  ]}
                  onChange={(value) => handleInputChange("brand", value)}
                />
                <Input
                  type="text"
                  placeholder="SKU"
                  onChange={(value) => handleInputChange("sku", value)}
                />
                <Input
                  type="text"
                  placeholder="Description"
                  onChange={(value) => handleInputChange("description", value)}
                />
                <Input
                  type="text"
                  placeholder="Cost"
                  onChange={(value) => handleInputChange("cost", value)}
                />
                <Input
                  type="text"
                  placeholder="Price"
                  onChange={(value) => handleInputChange("price", value)}
                />
                <Input
                  type="text"
                  placeholder="Quantity"
                  onChange={(value) => handleInputChange("quantity", value)}
                />

                <Input
                  type="text"
                  placeholder="Vat"
                  onChange={(value) => handleInputChange("vat", value)}
                />
                <div>
                  <label className="form-label fw-bold">Thumbnail Image</label>
                  <div className="profile-img-wrapper">
                    {selectedImage ? (
                      <img
                        className="mb-2 img-fluid"
                        src={selectedImage || ""}
                        alt="brand"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <InputFile
                    id="product-image"
                    imageChange={handleImageChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <DropSelect
                  label="Stock Status"
                  options={stockStatusData}
                  onChange={(value) => handleInputChange("stock_status", value)}
                />
                <DropSelect
                  label="Is Active"
                  options={isActive}
                  onChange={(value) => handleInputChange("is_active", value)}
                />
                <DropSelect
                  label="Is Featured"
                  options={isFeatured}
                  onChange={(value) => handleInputChange("is_featured", value)}
                />
                <Input
                  type="text"
                  placeholder="Reward Points"
                  onChange={(value) =>
                    handleInputChange("reward_points", value)
                  }
                />
                <DropSelect
                  label="Has Promotion"
                  options={[
                    { title: "Select an option", value: "", disabled: true },
                    { title: "true", value: true },
                    { title: "False", value: false },
                  ]}
                  onChange={(value) =>
                    handleInputChange("has_promotion", value)
                  }
                />
                <Input
                  type="text"
                  placeholder="Promotional Price"
                  onChange={(value) =>
                    handleInputChange("promotional_price", value)
                  }
                />
                <Input
                  type="date"
                  placeholder="Promotion Start Date"
                  onChange={(value) =>
                    handleInputChange("promotions_start_date", value)
                  }
                />
                <Input
                  type="date"
                  placeholder="Promotion End Date"
                  onChange={(value) =>
                    handleInputChange("promotions_expiry_date", value)
                  }
                />

                <Input
                  type="text"
                  placeholder="Unit Name"
                  onChange={(value) => handleInputChange("unit_name", value)}
                />
                <Input
                  type="text"
                  placeholder="Unit Value"
                  onChange={(value) => handleInputChange("unit_value", value)}
                />
              </div>
            </div>
            <div className="row mt-4 py-4 border-top border-2 border-secondary">
              <DropSelect
                label="Has Variant ?"
                options={[
                  { title: "Select an option", value: "", disabled: true },
                  { title: "Yes", value: true },
                  { title: "No", value: false },
                ]}
                onChange={handleVariantOption}
              />
              {dataSet.has_variant === "true" ? (
                <ProductVariants
                  onVariantDataChange={handleVariantDataChange}
                />
              ) : (
                ""
              )}
            </div>
            <AdditionalProp onAdditionPropsChange={handleAdditionPropsChange} />
            <div className="row mt-4 pb-3 pt-5 border-top border-2 border-secondary">
              <div className="d-flex flex-wrap">
                <div className="me-3 add-images-input">
                  <InputFile
                    id="add-producr-images"
                    imageChange={handleProductImages}
                    multiple={true}
                  />
                </div>
                <div className="d-flex">
                  {selectedImages.map((image, index) => (
                    <div className="add-images-wrapper" key={index}>
                      <img
                        className="img-fluid"
                        src={URL.createObjectURL(image)}
                        alt={`product-${index}`}
                      />
                      <button
                        className="border-0 bg-transparent multi-img-close-btn"
                        onClick={handleProductImageRemove}
                      >
                        <IoMdCloseCircleOutline className="fs-4 text-secondary" />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <button className="btn btn-dark my-3 py-2 w-100" type="submit">
              {shouldSubmit ? "Adding Product..." : "Add Product"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddProduct;
