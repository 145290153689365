import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoutes } from "../ProtectedRoutes";
import Blogs from "../../view/blogs/Blogs";
import AddBlog from "../../view/blogs/AddBlog";
import EditBlog from "../../view/blogs/EditBlog";
import Likes from "../../view/blogs/Likes";
import Comments from "../../view/blogs/Comments";

const BlogRoutes = () => {
  return (
    <Routes>
      <Route
        path="/blog-list"
        element={<ProtectedRoutes Component={Blogs} />}
      />
      <Route
        path="/blog-list/add-blog"
        element={<ProtectedRoutes Component={AddBlog} />}
      />
      <Route
        path="/blog-list/edit-blog/:id"
        element={<ProtectedRoutes Component={EditBlog} />}
      />
      <Route path="/Likes" element={<ProtectedRoutes Component={Likes} />} />
      <Route
        path="/Comments"
        element={<ProtectedRoutes Component={Comments} />}
      />
    </Routes>
  );
};

export default BlogRoutes;
