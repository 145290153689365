import React, { useEffect, useState } from "react";
import Breadcrumb from "../../component/breadcrumb/Breadcrumb";
import { getData } from "../../API/getData";
import Table from "../../component/table/Table";
import { commentColumns } from "../../data/tableColumns";
import useDeleteModal from "../../utilities/DeleteModal";
import { AiOutlineDelete } from "react-icons/ai";

const Comments = () => {
  const [comments, setComments] = useState([]);
  const fetchComments = () => {
    getData("blog/admin/comment/").then((res) => {
      setComments(res);
    });
  };
  useEffect(() => {
    fetchComments();
  }, []);

  const { showDeleteModal, DeleteModal } = useDeleteModal("blog/admin/comment");

  const actionColumn = {
    title: "Actions",
    key: "actions",
    render: (data) => {
      return (
        <div className="d-flex align-items-center">
          <button
            className="bg-danger p-1 border-0 ms-2"
            onClick={() => showDeleteModal(data, fetchComments)}
          >
            <AiOutlineDelete className="text-white" />
          </button>
        </div>
      );
    },
  };

  const finalColumn = [...commentColumns, actionColumn];
  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb directory1="Blogs" directory2="Comments" />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search mb-3">
            <h5>All Comments</h5>
          </div>
          <div className="table-area">
            <Table columns={finalColumn} dataSource={comments} />
          </div>
        </div>
      </div>
      {/*  */}
      <DeleteModal />
    </div>
  );
};

export default Comments;
