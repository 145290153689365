// useDeleteModal.js
import { useState } from "react";
import deleteData from "../API/deleteData";
import Modal from "../component/modal/Modal";

const useDeleteModal = (deleteEndpoint) => {
  const [selectedDeleteItem, setSelectedDeleteItem] = useState(null);
  const [deleteDataModal, setDeleteDataModal] = useState(false);
  const [successCallback, setSuccessCallback] = useState(null);

  const showDeleteModal = (item, onSuccess) => {
    setSelectedDeleteItem(item);
    setSuccessCallback(() => onSuccess);
    setDeleteDataModal(true);
  };

  const cancelDelete = () => {
    setSelectedDeleteItem(null);
    setSuccessCallback(null);
    setDeleteDataModal(false);
  };

  const deleteDataFunc = async () => {
    if (!selectedDeleteItem) {
      return;
    }

    const itemID = selectedDeleteItem.id;

    try {
      const response = await deleteData(`${deleteEndpoint}/${itemID}/`);
      console.log(response);

      if (response.status === "success" && successCallback) {
        successCallback(); // Call the success callback
      }

      setDeleteDataModal(false);
      setSelectedDeleteItem(null);
      setSuccessCallback(null);
    } catch (error) {
      console.log(error);
    }
  };

  const DeleteModal = () => (
    <Modal
      title="Delete Modal"
      content={
        <div>
          <p>
            Are you sure to delete the item -
            {selectedDeleteItem ? (
              <span className="fs-5 fw-bold">
                {selectedDeleteItem.name ||
                  selectedDeleteItem.title ||
                  selectedDeleteItem.product_name ||
                  selectedDeleteItem.group_name ||
                  selectedDeleteItem.coupon_title ||
                  selectedDeleteItem.reason_name ||
                  selectedDeleteItem.package_name ||
                  selectedDeleteItem.currency_name ||
                  selectedDeleteItem.id}
                ?
              </span>
            ) : (
              ""
            )}
          </p>
        </div>
      }
      visible={deleteDataModal}
      onCancel={cancelDelete}
      onOk={deleteDataFunc}
    />
  );

  return { showDeleteModal, DeleteModal };
};

export default useDeleteModal;
