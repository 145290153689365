import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import Input from "../../../component/inputs/Input";
import DropSelect from "../../../component/dropdowns/DropSelect";
import { isActive, reasonTypeData } from "../../../data/data";
import { useNavigate, useParams } from "react-router-dom";
import { GETData } from "../../../API/GETDataV2";
import putData from "../../../API/putData";

const EditReason = () => {
  const { id } = useParams();
  const [reason, setReason] = useState({
    reason_name: "",
    reason_type: "",
    is_active: "",
  });

  useEffect(() => {
    GETData(`sales/admin/reason/${id}/`).then((res) => {
      setReason(res.data.data);
    });
  }, [id]);

  const handleInputChange = (name, value) => {
    if (name === "reason_type") {
      value = value ? parseInt(value, 10) : 0;
    }
    setReason((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    putData(`sales/admin/reason/${id}/`, {
      reason_type: reason.reason_type,
      reason_name: reason.reason_name,
      is_active: reason.is_active,
    })
      .then((res) => {
        console.log("edit reason response", res);
        navigate("/sales/cancel-reasons");
      })
      .catch((err) => {
        console.log("error editing reason", err);
      });
  };
  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb
          directory1="Sales"
          directory2="Cancel Reasons"
          directory3="Edit Reason"
          path="/sales/cancel-reasons"
        />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search mb-3">
            <h5>Edit Reason</h5>
          </div>
          <div>
            <form onSubmit={handleSubmit}>
              <Input
                type="text"
                placeholder="Reason Name"
                value={reason.reason_name || ""}
                onChange={(value) => handleInputChange("reason_name", value)}
              />
              <DropSelect
                label="Reason Type"
                options={reasonTypeData}
                value={reason.reason_type}
                onChange={(value) => handleInputChange("reason_type", value)}
              />
              <DropSelect
                label="Is Active"
                options={isActive}
                value={reason.is_active}
                onChange={(value) => handleInputChange("is_active", value)}
              />

              <button className="btn btn-dark w-100 my-3" type="submit">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditReason;
