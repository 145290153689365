import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import MainLayout from "../view/main-layout/MainLayout";
import Dashboard from "../view/dashboard/Dashboard";
import Login from "../view/auth/login/Login";
import ForgotPass from "../view/auth/forgot-pass/ForgotPass";
import ForgotPassVerify from "../view/auth/forgot-pass-verification/ForgotPassVerify";
import Signup from "../view/auth/signup/Signup";
import AccountVerify from "../view/auth/account-verification/AccountVerify";
import NotFound from "../view/NotFound";
import { ProtectedRoutes } from "./ProtectedRoutes";
import Profile from "../view/profile/Profile";
import InventoryRoutes from "./inventory/Inventory";
import NotificationRoutes from "./notification/NotificationRoutes";
import SalesRoutes from "./sales/SalesRoutes";
import SupportRoutes from "./support/SupportRoutes";
import PackagesRoutes from "./packages/PackagesRoutes";
import UtilityRoot from "./utility/UtilityRoot";
import BlogRoutes from "./blogs/BlogRoutes";
import DeliveryRoots from "./delivery/DeliveryRoots";
import Chat from "../view/chat/Chat";
import Test from "../data/Test";

const AppRoutes = () => {
  const [registeredPhone, setRegisteredPhone] = useState();
  const getRegisteredPhone = (data) => {
    setRegisteredPhone(data);
  };
  const [forgotPhone, setForgotPhone] = useState();
  const getForgotPhone = (data) => {
    setForgotPhone(data);
  };
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path="/login" element={<Login />} />
      <Route
        path="/sign-up"
        element={<Signup setPhoneNumber={getRegisteredPhone} />}
      />
      <Route
        path="/account-verification"
        element={<AccountVerify phoneNumber={registeredPhone} />}
      />
      <Route
        path="/forgot-password"
        element={<ForgotPass setForgotPhone={getForgotPhone} />}
      />
      <Route
        path="/forgot-password-verification"
        element={<ForgotPassVerify mobileNumber={forgotPhone} />}
      />
      <Route path="/" element={<ProtectedRoutes Component={MainLayout} />}>
        <Route index element={<ProtectedRoutes Component={Dashboard} />} />
        <Route
          path="/profile"
          element={<ProtectedRoutes Component={Profile} />}
        />
        <Route
          path="/test/:id"
          element={<ProtectedRoutes Component={Test} />}
        />

        {/* -------------------INVENTORY SECTION------------------------- */}
        <Route path="/inventory/*" element={<InventoryRoutes />} />
        {/* -------------------NOTIFICATION SECTION------------------------- */}
        <Route path="/notifications/*" element={<NotificationRoutes />} />
        {/* -------------------SALES SECTION------------------------- */}
        <Route path="/sales/*" element={<SalesRoutes />} />
        {/* -------------------SUPPORT SECTION------------------------- */}
        <Route path="/support/*" element={<SupportRoutes />} />
        {/* -------------------PACKAGE SECITON-------------------------- */}
        <Route path="/packages/*" element={<PackagesRoutes />} />
        {/* -------------------UTILITY SECITON-------------------------- */}
        <Route path="/utility/*" element={<UtilityRoot />} />
        {/* -------------------BLOG SECITON-------------------------- */}
        <Route path="/blogs/*" element={<BlogRoutes />} />
        {/* -------------------DELIVERY SECITON-------------------------- */}
        <Route path="/delivery/*" element={<DeliveryRoots />} />
        {/* -------------------CHAT SECITON-------------------------- */}
        <Route path="/chats" element={<ProtectedRoutes Component={Chat} />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
