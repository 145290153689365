import React, { useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import Input from "../../../component/inputs/Input";
import DropSelect from "../../../component/dropdowns/DropSelect";
import { getFileType, isActive } from "../../../data/data";
import InputFile from "../../../component/inputs/FileInput";
import { postDocumentData } from "../../../API/postDocumentData";
import postData from "../../../API/postData";
import { useNavigate } from "react-router-dom";

const AddBanner = () => {
  const [banners, setBanners] = useState({});
  const [fileInput, setFileInput] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [shouldSubmit, setShouldSubmit] = useState(false);
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setFileInput(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (name, value) => {
    if (name === "image" || name === "position") {
      value = value ? parseInt(value, 10) : 0;
    }
    setBanners((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (fileInput) {
      const formData = new FormData();
      formData.append("document", fileInput);
      const docType = getFileType(fileInput.name);
      formData.append("doc_type", docType);

      try {
        const docUpload = await postDocumentData(
          "auth/documents/upload/",
          formData
        );
        setBanners((prev) => ({
          ...prev,
          image: docUpload.data.id,
        }));
        setShouldSubmit(true);
      } catch (error) {
        console.log(error);
      }
    } else {
      alert("select document");
    }
  };

  const navigate = useNavigate();

  if (shouldSubmit) {
    postData("utility/admin/banner/", banners)
      .then((res) => {
        console.log("banner add response", res);
        navigate("/utility/banner");
        setShouldSubmit(false);
      })
      .catch((err) => {
        console.log("error adding banner", err);
      });
  }
  return (
    <div>
      <div className="bread-crumb-wrapper">
        <Breadcrumb
          directory1="Utility"
          directory2="Banner"
          directory3="Add New"
          path="/utility/banner"
        />
      </div>
      <div className="p-3">
        <div className="bg-white p-3">
          <div className="title-and-search mb-3">
            <h5>Add New</h5>
          </div>
          <div>
            <form action="" onSubmit={handleSubmit}>
              <Input
                type="text"
                placeholder="Name"
                onChange={(value) => handleInputChange("name", value)}
              />
              <Input
                type="date"
                placeholder="Expiry Date"
                onChange={(value) => handleInputChange("expiry_date", value)}
              />
              <Input
                type="text"
                placeholder="Position"
                onChange={(value) => handleInputChange("position", value)}
              />
              <DropSelect
                label="Is Active"
                options={isActive}
                onChange={(value) => handleInputChange("is_active", value)}
              />
              <div>
                <div className="profile-img-wrapper">
                  {selectedImage ? (
                    <img
                      className="mb-2 img-fluid"
                      src={selectedImage || ""}
                      alt="brand"
                    />
                  ) : (
                    ""
                  )}
                </div>
                <InputFile id="banner-img" imageChange={handleImageChange} />
              </div>
              <button className="btn btn-dark w-100 my-3" type="submit">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBanner;
