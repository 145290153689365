import React from "react";
import illustration from "../../assets/image/login-ill.svg";

const AuthContainer = ({ children, titleLG, titleSM }) => {
  return (
    <div>
      <div className="row g-0">
        <div className=" d-none d-md-block d-lg-block d-xl-block col-md-6 tertiary-bg">
          <div className="vh-100 d-flex flex-column align-items-center justify-content-center">
            <img className="img-fluid p-2" src={illustration} alt="techtsy" />
            <div className="text-white w-75 mt-5 ">
              <h3 className="mb-4 fw-700">{titleLG}</h3>
              <h6>{titleSM}</h6>
            </div>
          </div>
        </div>
        <div className="col-md-6 p-3 p-md-0 p-lg-0 p-xl-0">
          <div className="vh-100 d-flex align-items-center justify-content-center">
            <div className="col-xxl-5 col-xl-6 col-lg-8 col-md-10 col-sm-12 col-12">
              <div className="shadow rounded-12">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthContainer;
